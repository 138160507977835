import {
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import OrderDetailsReservations from '../components/order-details/order-details-reservations';
import OrderProductFields from '../components/order-details/order-product-fields';
import { useAddOrderState } from '../context/add-order-context';
import { useOrderFormDispatch, useOrderFormState } from '../context/order-form-context';
import { CampaignInterface, ProductInterface } from '../interfaces';
import { OrderBatch, parseOrderBatch } from '../types/order-batch';

const useStyles = makeStyles(() => ({
  inputContainer: {
    justifyContent: 'space-between',
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
}));

interface OrderDetailsProps {
  campaignData?: CampaignInterface;
  product: ProductInterface;
  orderBatch: OrderBatch;
  setErrors: Function;
  batchIndex: number;
  clearErrors: Function;
  floatingOrder?: {
    floating: boolean;
    quantity: number;
    variations: string[];
  };
}

export interface BatchState extends OrderBatch {
  radioValue: string;
}

const OrderBatchContainer: React.FC<OrderDetailsProps> = ({
  campaignData,
  product,
  orderBatch,
  setErrors,
  clearErrors,
  floatingOrder,
  batchIndex,
}: OrderDetailsProps) => {
  const addOrderState = useAddOrderState();
  const orderFormState = useOrderFormState();
  const orderFormDispatch = useOrderFormDispatch();
  const classes = useStyles();
  const [batchName, setBatchName] = useState<string>(orderBatch.name || '');
  const [batchNameAuto, setBatchNameAuto] = useState<boolean>(!orderBatch.customName);

  const resolveRadioValue = (): string => {
    if (product?.limitType === 'week') {
      return orderBatch.quantity === 20 || orderBatch.quantity === 10
        ? `${orderBatch.quantity}`
        : orderBatch.quantity
        ? 'other'
        : '';
    }
    return orderBatch.quantity === 5 || orderBatch.quantity === 10
      ? `${orderBatch.quantity}`
      : orderBatch.quantity
      ? 'other'
      : '';
  };

  const [batch, setBatch] = useState<BatchState>({
    ...orderBatch,
    radioValue: resolveRadioValue(),
  });

  const validateQuantity = (): string => {
    if (floatingOrder && floatingOrder.floating) {
      if (product.limitType === 'month') {
        if (floatingOrder.quantity > 0) return '';
        return 'Virhe';
      }
      if (floatingOrder.quantity >= 5 && floatingOrder.quantity % 5 === 0) return '';
      return 'Arvon tulee olla jaollinen viidellä';
    }
    if (product.limitType === 'month') {
      if (batch.quantity > 0) return '';
      return 'Virhe';
    }
    if (batch.quantity >= 5 && batch.quantity % 5 === 0) return '';
    return 'Arvon tulee olla jaollinen viidellä';
  };

  const validatePeriod = (): string => {
    if (floatingOrder && floatingOrder.floating) return '';
    if (product.limitType === 'month') {
      if (batch.unitAmount < product.minOrderQuantity)
        return `Valitse vähintään ${product.minOrderQuantity} kuukautta`;
      return '';
    }
    if (product.limitType === 'week') {
      if (batch.unitAmount < product.minOrderQuantity)
        return `Valitse vähintään ${product.minOrderQuantity} viikkoa`;
      return '';
    } else {
      if (batch.unitAmount < product.minOrderQuantity)
        return `Valitse vähintään ${product.minOrderQuantity} päivää`;
      return '';
    }
  };

  const validateBatchName = (): string => {
    return batchName.length > 0 ? '' : 'Pakollinen kenttä';
  };

  const validateVariations = (): string => {
    if (floatingOrder && floatingOrder.floating) {
      if (floatingOrder.variations.length > 0) return '';
      return 'Valitse vähintään yksi';
    }
    if (batch.variations.length > 0) return '';
    return 'Valitse vähintään yksi';
  };

  useEffect(() => {
    //pitaisi varmaan yksiloda mika batsi kyseessa
    const errors: string[] = [];
    if (validateQuantity()) errors.push(validateQuantity());
    if (validatePeriod()) errors.push(validatePeriod());
    if (validateVariations()) errors.push(validateVariations());
    if (validateBatchName()) errors.push(validateBatchName());
    if (errors.length > 0) {
      setErrors(batch.id);
    } else {
      //pass
      clearErrors(batch.id);
    }

    const newBatches = [...orderFormState.batches.filter((b) => b.id !== batch.id), batch];

    const batches =
      floatingOrder && floatingOrder.floating
        ? newBatches.map((batch) => {
            batch.variations = floatingOrder.variations;
            batch.quantity = addOrderState.floatingOrder?.quantity || 0;
            return batch;
          })
        : newBatches;

    orderFormDispatch({
      type: 'updateBatches',
      payload: batches.map((batch) => parseOrderBatch(batch)),
    });
  }, [batch, floatingOrder]);

  useEffect(() => {
    if (product.variations && product.variations.length === 1) {
      const variations = product.variations.map((v) => v.id);
      setBatch((prevBatch) => {
        const result = { ...prevBatch, variations };
        return result;
      });
    }
  }, [product]);

  useEffect(() => {
    if (batchNameAuto) {
      const sorted = batch.dates ? batch.dates.sort((a, b) => a.getTime() - b.getTime()) : [];
      const names = batch.variations
        .map((variation) => {
          if (product && product.variations) {
            const variationProd = product.variations.find((prodVar) => {
              return prodVar.id === variation;
            });
            if (variationProd) return variationProd.name;
          }
          return variation;
        })
        .sort((a, b) => {
          if (a > b) return 1;
          if (a < b) return -1;
          return 0;
        });
      //tassa paivitetaan tuota nimea jos on auto
      const name =
        `${product.name} ${names.join(', ')}`.charAt(0).toUpperCase() +
        `${product.name} ${names.join(', ')}, ${
          product.limitType !== 'month' ? batch.quantity + ' sek, ' : ''
        }${
          product.limitType !== 'month'
            ? product.limitType !== 'week'
              ? batch.unitAmount + ' ' + 'päivää'
              : batch.unitAmount + ' ' + 'viikkoa'
            : batch.unitAmount + ' ' + 'kuukautta'
        }${
          product.limitType === 'second'
            ? ', ' +
              moment(sorted[0]).format('DD.MM.') +
              '-' +
              moment(sorted[sorted.length - 1]).format('DD.MM.YYYY')
            : product.limitType === 'week' && batch.weeks && batch.weeks.length > 0
            ? ', ' + batch.weeks.map((week) => `${week.week}/${week.year}`).join(', ')
            : batch.months && batch.months.length > 0
            ? ', ' +
              batch.months[0].month +
              '/' +
              batch.months[0].year +
              '-' +
              batch.months[batch.months.length - 1].month +
              '/' +
              batch.months[batch.months.length - 1].year
            : ''
        }`.slice(1);
      setBatchName(name);
      setBatch((prevBatch) => ({ ...prevBatch, name, customName: false }));
    }
  }, [
    product,
    batch.unitAmount,
    batch.variations,
    batch.quantity,
    batch.dates,
    batch.months,
    batchNameAuto,
  ]);

  useEffect(() => {
    setBatch((prevBatch) => ({ ...prevBatch, unitAmount: orderBatch.unitAmount }));
  }, [orderBatch.unitAmount]);

  return (
    <>
      <Grid
        style={{
          fontSize: '22px',
          fontWeight: 700,
          marginTop: '20px',
        }}
      >
        Jakso {batchIndex + 1}
      </Grid>
      <Grid container style={{ width: '900px', marginLeft: '10px' }}>
        <OrderProductFields batch={batch} setBatch={setBatch} floatingOrder={floatingOrder} />
        <OrderDetailsReservations
          batch={batch}
          setBatch={setBatch}
          periodError={validatePeriod()}
          floating={floatingOrder?.floating ? floatingOrder : undefined}
          product={product}
          campaingId={campaignData && campaignData.id ? campaignData.id : ''}
          orderTemplate={campaignData ? true : false}
        />
        {!floatingOrder ||
          (!floatingOrder.floating && (
            <Grid
              container
              className={classes.inputContainer}
              alignItems="flex-end"
              item
              style={{ marginBottom: '30px' }}
            >
              <Grid item>
                <Typography className={classes.title}>Tilausrivi</Typography>
              </Grid>
              <TextField
                style={{ width: '80%' }}
                name="batch-name"
                disabled={batchNameAuto}
                onChange={(e): void => {
                  setBatchName(e.target.value);
                }}
                onBlur={(): void => {
                  setBatch({ ...batch, name: batchName, customName: true });
                }}
                placeholder=""
                value={batchName}
              ></TextField>
              <Typography className={classes.error}>{validateBatchName()}</Typography>
              <FormControl>
                <FormControlLabel
                  labelPlacement="end"
                  style={{ width: '80%', marginTop: '20px' }}
                  label="Anna tilausrivi käsin"
                  control={
                    <Switch
                      checked={!batchNameAuto}
                      onChange={(): void => {
                        setBatchNameAuto(!batchNameAuto);
                      }}
                      color="primary"
                    ></Switch>
                  }
                ></FormControlLabel>
              </FormControl>
            </Grid>
          ))}
      </Grid>
    </>
  );
};

export default OrderBatchContainer;
