import gql from 'graphql-tag';
import Client from './apollo-client';
class MaterialClient {
  private client: Client;
  constructor() {
    // eslint-disable-next-line no-undef
    const url = process.env.REACT_APP_MATERIAL_SERVICE_URL || '';
    this.client = new Client(url);
  }

  addFile = async (file: File): Promise<any> => {
    try {
      const ADD_FILE = gql`
        mutation addMaterial($file: Upload!) {
          addMaterial(material: { file: $file }) {
            id
            uniqueName
            originalName
            fileFormat
          }
        }
      `;
      const result = await this.client.mutate(ADD_FILE, {
        file,
      });
      return result.data.addMaterial;
    } catch (error) {
      throw new Error(error);
    }
  };
}
export default new MaterialClient();
