import React from 'react';
import { CheckboxGroup } from '../components';
import { ProductVariationInterface } from '../interfaces';

interface VariationChooserProps {
  variations?: ProductVariationInterface[];
  setFieldValue: Function;
  touched: boolean;
  maxVariations?: number;
  selectedVariations: string[];
  floating?: boolean;
}
const VariationChooser: React.FC<VariationChooserProps> = ({
  variations,
  setFieldValue,
  touched,
  maxVariations,
  selectedVariations,
  floating = false,
}: VariationChooserProps) => {
  const options = variations
    ? variations
        .sort((v1, v2) => {
          if (!v1.order) v1.order = 0;
          if (!v2.order) v2.order = 0;
          return v1.order > v2.order ? 1 : -1;
        })
        .map((variation) => {
          return { label: variation.name, value: variation.id };
        })
    : [];

  const variationsLength = (): number => {
    if (selectedVariations) {
      return selectedVariations.length;
    }
    return 0;
  };

  const handleValueChange = (inputName: any, value: any): void => {
    const name = inputName.split('.')[1];
    setFieldValue(name, value);
  };

  const getValue = (inputName: string): { name: string; value: boolean } => {
    const name = inputName.split('.')[1];
    return { name: inputName, value: selectedVariations?.includes(name) };
  };

  return (
    <CheckboxGroup
      options={options}
      getFieldProps={getValue}
      title={'Esitysaika'}
      name={'variations'}
      disabled={floating}
      setFieldValue={handleValueChange}
      maxVariations={maxVariations}
      variationsLength={variationsLength()}
      fieldError={
        touched && selectedVariations.length === 0
          ? 'Valitse vähintään yksi.'
          : ''
      }
    ></CheckboxGroup>
  );
};
export default VariationChooser;
