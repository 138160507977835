interface TemplateProps {
  week?: boolean;
  currentUser: {
    phone?: string;
    name?: string;
    email: string;
    organization: {
      name: string;
    };
  };
}
export const template = (props: TemplateProps): string => {
  const currentUser = props.currentUser;

  return `
  <h3>Yhteistyöterveisin</h3>
  ${currentUser?.organization?.name ? `<p>${currentUser?.organization?.name}</p>` : ''}
  ${currentUser?.name ? `<p>${currentUser?.name}</p>` : ''}
  ${currentUser?.phone ? `<p>${currentUser?.phone}</p>` : ''}
  ${
    currentUser?.email
      ? ` <p><a href="mailto:${currentUser?.email}">${currentUser?.email}</a></p>`
      : ''
  }
 
`;
};
