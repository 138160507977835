import { Grid, makeStyles } from '@material-ui/core';
import { redirectTo, RouteComponentProps } from '@reach/router';
import React, { useEffect } from 'react';
import Image from '../assets/login-background.png';
import { Layout, SetPasswordComponent } from '../components';
import { getToken } from '../lib/storage-util';

const useStyles = makeStyles(() => ({
  resetContainer: {
    width: '100%',
    minHeight: '100%',
    backgroundImage: `url(${Image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}));

interface MatchParams {
  resetToken: string;
}
type Props = RouteComponentProps<MatchParams>;

const SetPassword: React.FC<RouteComponentProps> = ({ ...props }: Props) => {
  const classes = useStyles();
  useEffect(() => {
    document.title = 'Sales Mediamyynti - Reset password';
    const token = getToken();
    if (token) {
      redirectTo('/logout');
    }
  }, []);
  return (
    <Layout>
      <Grid wrap="nowrap" className={classes.resetContainer} container>
        <SetPasswordComponent resetToken={props.resetToken} />
      </Grid>
    </Layout>
  );
};

export default SetPassword;
