import { Grid } from '@material-ui/core';
import { FormikErrors, FormikTouched } from 'formik';
import React, { ReactNode, useEffect } from 'react';
import { CheckboxGroup, RadioButtonGroup } from '../components';
import { Month, ProductSettingInterface } from '../interfaces';

interface Errors {
  [key: string]:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | FormikErrors<Date>[]
    | Month[]
    | { [key: string]: boolean }
    | undefined;
}

interface Touched {
  [key: string]: any;
}

interface SettingChoosersProps {
  productSettings?: ProductSettingInterface[];
  getFieldProps: Function;
  setFieldValue: Function;
  fieldErrors: FormikErrors<Errors>;
  touched: FormikTouched<Touched>;
}

const SettingChoosers: React.FC<SettingChoosersProps> = ({
  productSettings,
  getFieldProps,
  setFieldValue,
  fieldErrors,
  touched,
}: SettingChoosersProps) => {
  useEffect(() => {
    if (productSettings) {
      productSettings.map((setting) => {
        const field = getFieldProps(setting.id);
        if (!field.value && setting.type === 'checkbox') {
          setFieldValue(setting.id, {});
        }
      });
    }
  }, [productSettings]);

  const mapSettings = (setting: ProductSettingInterface): ReactNode => {
    if (setting.otherOption) {
      setting.otherOption.name = setting.id + '-other';
    }

    switch (setting.type) {
      case 'radio':
        return (
          <RadioButtonGroup
            name={setting.id}
            options={setting.options}
            title={setting.label}
            getFieldProps={getFieldProps}
            otherInput={setting.otherOption ? setting.otherOption : undefined}
            fieldError={
              Object.keys(touched).length === 0
                ? ''
                : fieldErrors[setting.id + '-other']
                ? fieldErrors[setting.id + '-other']
                : fieldErrors[setting.id]
            }
          ></RadioButtonGroup>
        );
      case 'checkbox':
        return (
          <CheckboxGroup
            setFieldValue={setFieldValue}
            getFieldProps={getFieldProps}
            name={setting.id}
            title={setting.label}
            options={setting.options}
            fieldError={
              Object.keys(touched).length === 0 ? '' : fieldErrors[setting.id]
            }
          ></CheckboxGroup>
        );

      default:
        return;
    }
  };

  return (
    <>
      {productSettings &&
        productSettings.map((setting) => {
          return (
            <Grid item key={setting.id}>
              {mapSettings(setting)}
            </Grid>
          );
        })}
    </>
  );
};

export default SettingChoosers;
