import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useOrderFormDispatch, useOrderFormState } from '../../context/order-form-context';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '800px',
    marginTop: '60px',
    marginBottom: '60px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  radio: {
    '&$checked': {
      color: '#5E8EFC',
    },
  },
  checked: {},
  formGroup: {
    width: '400px',
  },
  title: {
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '19px',
    fontWeight: 'bold',
    color: '#000000',
  },
  text: {
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '16px',
    fontWeight: 400,
    color: '#000000DE',
  },
}));

const OrderBillingInfo: React.FC = () => {
  const classes = useStyles();
  const orderFormState = useOrderFormState();
  const orderFormDispatch = useOrderFormDispatch();

  const updateValue = (newValue: boolean): void => {
    if (!newValue && orderFormState.partialPayment)
      alert('Huom! Kyseessä ennakkolasku, huomioi konsernin ohjeistus ennakkolaskutuksista.');
    orderFormDispatch({
      type: 'updatePartialPayment',
      payload: newValue,
    });
  };

  return (
    <>
      {orderFormState.isMultiMonth && (
        <FormControl className={classes.formControl}>
          <div>
            <Typography className={classes.title}>Laskutustapa</Typography>
          </div>
          <FormGroup className={classes.formGroup}>
            <FormControlLabel
              className={classes.text}
              name="all"
              control={
                <Checkbox
                  classes={{
                    root: classes.radio,
                    checked: classes.checked,
                  }}
                  onChange={(_, checked: boolean): void => updateValue(checked)}
                />
              }
              checked={!!orderFormState.partialPayment}
              value={!!orderFormState.partialPayment}
              label="Osalaskutus"
            ></FormControlLabel>
          </FormGroup>
        </FormControl>
      )}
    </>
  );
};

export default OrderBillingInfo;
