import { CampaignPrice } from '../../interfaces';
import { CampaignPriceInput } from '../../types/campaign-price-input';
import campaignClient from '../campaign-client';

const addCampaignPrice = async (
  price: CampaignPriceInput
): Promise<CampaignPrice> => {
  return campaignClient.addCampaignPrice(price);
};

export default addCampaignPrice;
