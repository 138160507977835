import { redirectTo, RouteComponentProps } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { Layout } from '../components';
import OrderListContainer from '../containers/campaign-list-container';
import { getToken, getUserId } from '../lib/storage-util';
import userClient from '../lib/user-client';

const OrderList: React.FC<RouteComponentProps> = () => {
  const [restrictedProducts, setRestrictedProducts] = useState<string[]>();
  const [admin, setAdmin] = useState(false);
  useEffect(() => {
    document.title = 'Sales Mediamyynti - Orders';
    const token = getToken();
    if (!token) {
      redirectTo('/');
    }
  }, []);
  useEffect(() => {
    const getUser = async (): Promise<void> => {
      try {
        const userId = getUserId();
        if (userId) {
          const user = await userClient.getUser(userId);
          setAdmin(!!user.admin);
          if (
            user.organization.restrictedProducts &&
            user.organization.restrictedProducts.length > 0
          )
            setRestrictedProducts(user.organization.restrictedProducts);
        }
      } catch {
        //pass
      }
    };
    getUser();
  }, []);
  return (
    <Layout tab="/orders">
      <OrderListContainer
        admin={admin}
        restrictedProducts={restrictedProducts}
        type="order"
      ></OrderListContainer>
    </Layout>
  );
};
export default OrderList;
