import Views from './views';

const routes = [
  {
    component: Views.Login,
    path: '/',
  },
  {
    component: Views.Order,
    path: '/order',
  },
  {
    component: Views.OrderList,
    path: '/orders',
  },
  {
    component: Views.UserCreate,
    path: '/newuser',
  },
  {
    component: Views.Logout,
    path: '/logout',
  },
  {
    component: Views.ResetPassword,
    path: '/resetpassword',
  },
  {
    component: Views.SetPassword,
    path: '/resetpassword/:resetToken',
  },
  {
    component: Views.Playlist,
    path: '/playlist',
  },
  {
    component: Views.FileUpload,
    path: '/file-upload',
  },
  {
    component: Views.OfferList,
    path: '/offers',
  },
  {
    component: Views.OrderTemplate,
    path: '/ordertemplate',
  },
  {
    component: Views.ApproveOffer,
    path: '/approveoffer',
  },
  {
    component: Views.Reservations,
    path: '/reservations',
  },
  {
    component: Views.OrderUpdate,
    path: '/orderUpdate',
  },
  {
    component: Views.FloatingOrders,
    path: '/floatingOrders',
  },
  {
    component: Views.Report,
    path: '/report',
  },
];

export default routes;
