import { PlaylistItem } from '../interfaces';

interface MappedItems {
  changed: PlaylistItem[];
  array: PlaylistItem[];
}

const mapItemIds = (items: PlaylistItem[]): MappedItems => {
  const changed: PlaylistItem[] = [];

  const array = items.map((item, i) => {
    const tempItem = { ...item };
    if (i === 0) {
      tempItem.nextItemId = items[i + 1] ? items[i + 1].id : '';
      tempItem.previousItemId = null;
    } else if (i === items.length - 1) {
      tempItem.previousItemId = items[i - 1] ? items[i - 1].id : '';
      tempItem.nextItemId = null;
    } else {
      tempItem.nextItemId = items[i + 1].id;
      tempItem.previousItemId = items[i - 1].id;
    }
    if (JSON.stringify(tempItem) !== JSON.stringify(item)) {
      changed.push(tempItem);
    }
    return tempItem;
  });

  const arrays = { changed, array };

  return arrays;
};

const reorder = (
  list: PlaylistItem[],
  startIndex: number,
  endIndex: number
): PlaylistItem[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  console.log(list);

  return result;
};

const rearrange = (items: PlaylistItem[]): PlaylistItem[] => {
  let array: PlaylistItem[] = [];

  const first = items.find((item) => !item.previousItemId && item.nextItemId);

  if (first) {
    array.push(first);
    items.forEach((j, i) => {
      if (array[i]) {
        const test = items.find((item) => item.id === array[i].nextItemId);
        if (test) array.push(test);
      }
    });
  }

  if (array.length !== items.length) {
    const notIncluded = items.filter((f) => !array.includes(f));
    array = array.concat(notIncluded);
  }

  return array;
};

const mapItemLength = (items: number[]): number => {
  const reducer = (accumulator: number, currentValue: number): number =>
    accumulator + currentValue;

  return items.reduce(reducer);
};

export { rearrange, reorder, mapItemIds, mapItemLength };
