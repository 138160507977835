import { Button, makeStyles } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import React from 'react';

const useStyles = makeStyles(() => ({
  left: {
    borderBottomLeftRadius: '25px',
    borderTopLeftRadius: '25px',
    borderTopRightRadius: '0px',
    borderBottomRightRadius: '0px',
    maxWidth: '48px',
    color: '#5E8EFC',
  },
  right: {
    borderTopRightRadius: '25px',
    borderBottomRightRadius: '25px',
    borderBottomLeftRadius: '0px',
    borderTopLeftRadius: '0px',
    width: '48px',
    color: '#5E8EFC',
  },
  root: {
    minWidth: '48px',
    minHeight: '48px',
    backgroundColor: '#EFF1F2',

    '&:hover': {
      backgroundColor: '#EFF1F2',
      boxShadow: (props: any) => props.boxShadow,
    },
  },
}));

interface ArrowButtonProps {
  disableButton?: boolean;
  direction: string;
  handleClick: () => void;
}

const ArrowButton: React.FC<ArrowButtonProps> = ({
  disableButton = false,
  handleClick,
  direction,
}: ArrowButtonProps) => {
  const styles = {
    boxShadow:
      direction === 'left'
        ? ' -2px 4px 10px  #00000033'
        : ' 2px 4px 10px #00000033',
  };
  const classes = useStyles(styles);

  const handleButtonClick = (): void => {
    handleClick();
  };

  return (
    <Button
      disabled={disableButton}
      variant="contained"
      color="primary"
      onClick={handleButtonClick}
      disableRipple
      className={direction === 'left' ? classes.left : classes.right}
      classes={{ root: classes.root }}
    >
      {direction === 'left' ? (
        <KeyboardArrowLeftIcon></KeyboardArrowLeftIcon>
      ) : (
        <KeyboardArrowRightIcon></KeyboardArrowRightIcon>
      )}
    </Button>
  );
};

export default ArrowButton;
