import MomentUtils from '@date-io/moment';
import { createTheme, CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, sans-serif',
  },
  palette: {
    text: {
      primary: '#000000',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          minHeight: '100%',
          height: '100%',
          margin: 0,
          padding: 0,
          scrollBehavior: 'smooth',
          overflow: 'auto',
        },
        html: {
          minHeight: '100%',
          height: '100%',
          margin: 0,
          padding: 0,
          scrollBehavior: 'smooth',
        },
        '#root': {
          minHeight: '100%',
          height: '100%',
          margin: 0,
          padding: 0,
          scrollBehavior: 'smooth',
        },
      },
    },
  },
});

ReactDOM.render(
  <React.Fragment>
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <CssBaseline>
          <App />
        </CssBaseline>
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  </React.Fragment>,
  document.getElementById('root')
);
