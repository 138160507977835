import React from 'react';
import OrderDetailsForm from '../components/order-details-form';
import { CampaignInterface, OrderDetailsFormValues } from '../interfaces';

interface OrderDetailsContainerProps {
  submitting: boolean;
  setSubmitting: Function;
  handleSubmit: Function;
  setDetailErrors: Function;
  setSummaryTypeFormat: Function;
  campaignData?: CampaignInterface;
}

const OrderDetailsContainer: React.FC<OrderDetailsContainerProps> = ({
  setSubmitting,
  submitting,
  handleSubmit,
  setDetailErrors,
  setSummaryTypeFormat,
  campaignData,
}: OrderDetailsContainerProps) => {
  const detailsSubmit = (values: OrderDetailsFormValues): void => {
    const campaignDetails = values.campaignDetails.map((detail) => {
      if (typeof detail.value === 'object') {
        let values = '';
        Object.entries(detail.value).forEach(([key, item], i) => {
          if (item) {
            values =
              values +
              key +
              (i === Object.entries(detail.value).length - 1 ? '' : ',');
          }
        });
        detail.value = values;
      }
      return detail;
    });

    handleSubmit({
      campaignDetails,
      product: values.product,
    });
  };

  return (
    <OrderDetailsForm
      submitting={submitting}
      setSubmitting={setSubmitting}
      handleSubmit={detailsSubmit}
      setDetailErrors={setDetailErrors}
      setSummaryTypeFormat={setSummaryTypeFormat}
      campaignData={campaignData}
    ></OrderDetailsForm>
  );
};
export default OrderDetailsContainer;
