import {
  CardMedia,
  Grid,
  makeStyles,
  TableCell,
  TableSortLabel,
  Typography,
} from '@material-ui/core';
import React from 'react';
import Up from '../../assets/sort-amount-up-solid.svg';
import { Order, OrderBy } from '../../containers/campaign-list-container';

const useStyles = makeStyles(() => ({
  headerCell: {},
  headerLabel: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    letterSpacing: '0.15px',
    fontSize: '16px',
    '&:hover': {
      color: '#FFFFFF',
      opacity: 1,
    },
    '&:focus': {
      color: '#FFFFFF',
      opacity: 1,
    },
  },
  labelContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sortLabel: {
    width: '100%',
    minWidth: '170px',
  },
}));

interface Props {
  order?: Order;
  orderBy?: OrderBy;
  label: string;
  sortImage?: string;
  sortHandler?: any;
}

const CampaignHeaderColumn: React.FC<Props> = ({
  label,
  sortImage,
  order,
  orderBy,
  sortHandler,
}: Props) => {
  const classes = useStyles();

  const SortImage = ({ name }: { name: string }): any => {
    return (
      <CardMedia
        style={{
          width: '20px',
          height: '17.5px',
          opacity: orderBy === name ? 1 : '0.7',
          display: orderBy ? 'block' : 'none',
          transform: `scaleY(${
            orderBy === name ? (order === 'asc' ? '1' : '-1') : '1'
          })`,
        }}
        component="svg"
        image={Up}
      ></CardMedia>
    );
  };

  return (
    <TableCell align="left" className={classes.headerCell}>
      <TableSortLabel
        active={orderBy === sortImage}
        direction={orderBy === sortImage ? order : undefined}
        onClick={sortHandler}
        classes={{
          root: classes.sortLabel,
        }}
      >
        <Grid container direction="row" className={classes.labelContainer}>
          <Typography className={classes.headerLabel}>{label}</Typography>
          {sortImage && <SortImage name={sortImage} />}
        </Grid>
      </TableSortLabel>
    </TableCell>
  );
};

export default CampaignHeaderColumn;
