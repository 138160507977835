import {
  ButtonBase,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { Link } from '@reach/router';
import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

const useStyles = makeStyles(() => ({
  inputs: {
    width: '328px',
    height: '80px',
    marginBottom: '40px',
  },
  input: {
    color: '#5E8EFC',
    fontSize: '16px',
    letterSpacing: '0.15px',
  },
  error: {
    color: 'red',
    marginBottom: '5px',
  },
  infomsg: {
    color: '#5E8EFC',
    marginBottom: '15px',
  },

  buttonBase: {
    height: '48px',
    width: '184px',
    backgroundColor: '#5E8EFC',

    borderRadius: '4px',
  },
  submitText: {
    color: 'white',
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '1.25px',
  },
}));

type LoginProps = {
  handleLogin: ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => Promise<void>;
  loginError: boolean;
};

const LoginForm: React.FC<LoginProps> = ({
  handleLogin,
  loginError,
}: LoginProps) => {
  const classes = useStyles();

  const validationSchema = Yup.object({
    username: Yup.string().required('Pakollinen kenttä'),
    password: Yup.string().required('Pakollinen kenttä'),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema,
    onSubmit: handleLogin,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container direction="column">
        <Grid item container direction="column">
          <Grid className={classes.error}>
            {formik.errors.username ? formik.errors.username : null}
          </Grid>

          <TextField
            helperText="Käyttäjätunnus tai sähköposti"
            variant="outlined"
            type="text"
            id="username"
            name="username"
            onChange={formik.handleChange}
            value={formik.values.username}
            className={classes.inputs}
            InputProps={{
              className: classes.input,
            }}
          ></TextField>
        </Grid>

        <Grid item container direction="column">
          <Grid className={classes.error}>
            {formik.errors.password ? formik.errors.password : null}
          </Grid>

          <TextField
            helperText="Salasana"
            variant="outlined"
            type="password"
            id="password"
            name="password"
            autoComplete="on"
            onChange={formik.handleChange}
            value={formik.values.password}
            className={classes.inputs}
            InputProps={{
              className: classes.input,
            }}
          ></TextField>
        </Grid>
        <Grid item direction="column" container alignItems="center">
          {loginError ? (
            <Grid item container direction="column" alignItems="center">
              <Typography className={classes.error}>
                Wrong username or password.
              </Typography>
            </Grid>
          ) : (
            <></>
          )}
          <Grid>
            <Typography className={classes.infomsg}>
              Palauta unohtunut salasana{' '}
              <Link to="/resetpassword" className={classes.infomsg}>
                täällä
              </Link>
              .
            </Typography>
          </Grid>
          <ButtonBase className={classes.buttonBase} type="submit">
            <Typography className={classes.submitText}>
              kirjaudu sisään
            </Typography>
          </ButtonBase>
        </Grid>
      </Grid>
    </form>
  );
};
export default LoginForm;
