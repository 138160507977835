import { CampaignPriceRow } from '../interfaces';

export type CampaignPriceInput = {
  campaign: string;
  totalPrice: number;
  vat: number;
  mediaDiscount: number;
  discount: number;
  listPrice: number;
  priceRows: CampaignPriceRow[];
  discountAmount?: number;
  discountPercent?: number;
  customName?: boolean;
  solid?: boolean;
};

const parseCampaignPriceInput = ({
  campaign,
  totalPrice,
  vat,
  mediaDiscount,
  discount,
  listPrice,
  priceRows,
  solid = false,
  discountAmount,
  discountPercent,
  ...rest
}: Partial<CampaignPriceInput>): CampaignPriceInput => {
  if (
    typeof campaign === 'string' &&
    typeof mediaDiscount === 'number' &&
    typeof totalPrice === 'number' &&
    typeof vat === 'number' &&
    typeof discount === 'number' &&
    typeof listPrice === 'number' &&
    priceRows
  ) {
    return {
      campaign,
      mediaDiscount,
      totalPrice,
      vat,
      discount:
        discount && discountPercent
          ? Math.round((discount + Number.EPSILON) * 100) / 100
          : discountAmount
          ? discountAmount
          : 0,
      listPrice,
      priceRows,
      solid,
      discountPercent: discountPercent
        ? Math.round((discountPercent + Number.EPSILON) * 100) / 100
        : 0,
      discountAmount: discountAmount
        ? Math.round((discountAmount + Number.EPSILON) * 100) / 100
        : 0,
      ...rest,
    };
  }
  throw new Error('Missing fields for campaign price');
};

export { parseCampaignPriceInput };
