import * as yup from 'yup';
import { CampaignPriceRow } from '../interfaces';

export type EmailContext = {
  reference: string;
  orderNumber?: number;
  orderTime?: string;
  ordererName?: string;
  ordererBusinessID?: string;
  ordererBillingAddress?: string;
  ordererContactName?: string;
  ordererContactEmail?: string;
  productName?: string;
  productVAT?: string;
  productTotal?: string;
  discount?: string;
  discountPercent?: string;
  offerText?: string;
  priceRows?: (CampaignPriceRow & { rowPrice: string })[];
  materialGuide?: string;
  freeText?: string;
  costText?: string;
  tapeText?: string;
  productPriceWithoutTaxes?: string;
  mediaDiscount?: string;
  showMoreInstructions?: boolean;
  quantityArray?: number[];
  isTape?: boolean;
  campaignName: string;
};

const parseEmailContext = ({ reference, ...rest }: Partial<EmailContext>): EmailContext => {
  if (yup.string().isValidSync(reference)) {
    let {
      mediaDiscount,
      productPriceWithoutTaxes,
      tapeText,
      costText,
      freeText,
      materialGuide,
      orderNumber,
      productVAT,
      productTotal,
      discount,
      discountPercent,
      offerText,
      orderTime,
      ordererName,
      ordererBusinessID,
      ordererBillingAddress,
      ordererContactName,
      ordererContactEmail,
      productName,
      showMoreInstructions,
      isTape,
      quantityArray,
      campaignName,
    } = rest;

    if (!yup.string().nullable().isValidSync(mediaDiscount)) mediaDiscount = '';
    if (!yup.string().nullable().isValidSync(productPriceWithoutTaxes))
      productPriceWithoutTaxes = '';
    if (!yup.string().nullable().isValidSync(tapeText)) tapeText = '';
    if (!yup.string().nullable().isValidSync(costText)) costText = '';
    if (!yup.string().nullable().isValidSync(freeText)) freeText = '';
    if (!yup.string().nullable().isValidSync(materialGuide)) materialGuide = '';
    if (!yup.string().nullable().isValidSync(discountPercent)) discountPercent = '';
    if (!yup.string().nullable().isValidSync(offerText)) offerText = '';
    if (!yup.string().nullable().isValidSync(productTotal)) productTotal = '';
    if (!yup.string().nullable().isValidSync(discount)) discount = '';
    if (!yup.number().nullable().isValidSync(orderNumber)) orderNumber = 0;
    if (!yup.string().nullable().isValidSync(productVAT)) productVAT = '';
    if (!yup.string().nullable().isValidSync(orderTime)) orderTime = '';
    if (!yup.string().nullable().isValidSync(ordererName)) ordererName = '';
    if (!yup.string().nullable().isValidSync(ordererBusinessID)) ordererBusinessID = '';
    if (!yup.string().nullable().isValidSync(ordererBillingAddress)) ordererBillingAddress = '';
    if (!yup.string().nullable().isValidSync(ordererContactName)) ordererContactName = '';
    if (!yup.string().email().nullable().isValidSync(ordererContactEmail)) ordererContactEmail = '';
    if (!yup.string().nullable().isValidSync(productName)) productName = '';
    if (!yup.boolean().nullable().isValidSync(showMoreInstructions)) showMoreInstructions = false;
    if (!yup.boolean().nullable().isValidSync(isTape)) isTape = false;
    if (!yup.array().of(yup.number()).nullable().isValidSync(quantityArray)) quantityArray = [];
    if (!yup.string().nullable().isValidSync(campaignName)) campaignName = '';
    return {
      reference,
      mediaDiscount,
      productPriceWithoutTaxes,
      tapeText,
      costText,
      freeText,
      materialGuide,
      orderNumber,
      productVAT,
      productTotal,
      discount,
      discountPercent,
      offerText,
      orderTime,
      ordererName,
      ordererBusinessID,
      ordererBillingAddress,
      ordererContactName,
      ordererContactEmail,
      productName,
      priceRows: rest.priceRows,
      showMoreInstructions,
      quantityArray,
      isTape,
      campaignName,
    };
  }
  throw new Error('Missing fields for email context');
};

export { parseEmailContext };
