import {
  ReservationWeekInput,
  ReservationWeekInterface,
  Week,
} from '../../interfaces';
import { OrderBatch } from '../../types/order-batch';
import reservationClient from '../reservation-client';

interface ReservationsRequest {
  product: string;
  campaign: string;
  rough: boolean;
  batches: OrderBatch[];
}

//TODO paivita
const handleBatch = async (
  batch: OrderBatch,
  campaign: string,
  product: string,
  rough: boolean
): Promise<ReservationWeekInterface[]> => {
  if (!batch.weeks) return [];
  const reservationData: ReservationWeekInput[][] = batch.weeks.map(
    (week: Week): ReservationWeekInput[] => {
      const base = {
        year: week.year,
        week: week.week,
        campaign,
        product,
        quantity: batch.quantity,
        rough,
        batch: batch.id,
      };
      const reservation = batch.variations.map(
        (variation: string): ReservationWeekInput => {
          return { ...base, variation };
        }
      );
      return reservation;
    }
  );
  const reservations: ReservationWeekInput[] = reservationData.flat();
  const creations = reservations.map((reservation) =>
    reservationClient.addReservationWeek({ ...reservation })
  );
  const result = await Promise.all(creations);
  return result;
};

const addWeekReservations = async ({
  product,
  campaign,
  rough,
  batches,
}: ReservationsRequest): Promise<ReservationWeekInterface[]> => {
  const promises = batches.map(async (batch) =>
    handleBatch(batch, campaign, product, rough)
  );
  const reservations = await Promise.all(promises);
  return reservations.flat();
};

export default addWeekReservations;
