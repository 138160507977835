import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import SchemaFields from '../add-customer-schema-fields';

interface FormCompanyInfoProps {
  schema: any;
  handleChange: any;
  values: any;
  formikErrors: any;
  formik: any;
  touched: any;
  updating: boolean;
}

const FormCompanyInfo: React.FC<FormCompanyInfoProps> = ({
  schema,
  handleChange,
  values,
  formikErrors,
  formik,
  touched,
  updating,
}: FormCompanyInfoProps) => {
  return (
    <Grid container>
      <Grid item>
        <Typography>{schema.label}</Typography>
      </Grid>
      <Grid item container spacing={2}>
        {schema.fields.map((field: any, i: number) => {
          return (
            <Grid
              key={field.name + i}
              item
              style={{ width: field.fullWidth ? '100%' : '50%' }}
            >
              <SchemaFields
                formikErrors={formikErrors}
                key={field.name + i}
                input={field}
                handleChange={handleChange}
                values={values}
                formik={formik}
                touched={touched}
                updating={updating}
              ></SchemaFields>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default FormCompanyInfo;
