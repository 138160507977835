import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '390px',
    height: '250px',
    backgroundColor: '#B1B8C00D',
    border: '1px solid #B1B8C026',
    padding: '20px',
    margin: '12.5px',
  },
  radio: {
    '&$checked': {
      color: '#5E8EFC',
    },
  },
  checked: {},
  formGroup: {
    padding: '14px',
  },
  title: {
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '19px',
    fontWeight: 'bold',
    color: '#000000',
  },
  text: {
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '16px',
    fontWeight: 400,
    color: '#000000DE',
  },
  errorText: {
    fontSize: '12px',
    letterSpacing: '0.25px',
    lineHeight: '16px',
    fontWeight: 400,
    color: '#E30312',
  },
}));

interface CheckboxProps {
  getFieldProps: Function;
  title: string;
  options: { label: string; value: string }[];
  otherName?: string;
  name: string;
  setFieldValue: Function;
  fieldError?: string;
  maxVariations?: number;
  variationsLength?: number;
  disabled?: boolean;
}

const CheckboxGroup: React.FC<CheckboxProps> = ({
  getFieldProps,
  title,
  options,
  name,
  setFieldValue,
  fieldError,
  maxVariations,
  variationsLength,
  disabled = false,
}: CheckboxProps) => {
  const [allSelected, setAllSelected] = useState(false);
  const classes = useStyles();
  const handleChangeAll = (): void => {
    setAllSelected(!allSelected);

    options.map(async (item, i) => {
      await setFieldValue(`${name}.${item.value}`, !allSelected);
    });
  };

  const handleChange = (field: any): void => {
    if (allSelected) {
      if (field.value) {
        setAllSelected(!allSelected);
      }
    }

    if (maxVariations) {
      if ((variationsLength ? variationsLength : 0) < maxVariations) {
        setFieldValue(field.name, !field.value);
      }
      if (variationsLength === maxVariations) {
        if (field.value) setFieldValue(field.name, !field.value);
      }
    } else {
      setFieldValue(field.name, !field.value);
    }
  };

  const showSelectAll = (): boolean => {
    if (maxVariations) {
      return maxVariations < options.length ? false : true;
    }
    return true;
  };

  return (
    <FormControl className={classes.formControl}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.errorText}>
        {fieldError ? fieldError : null}
      </Typography>
      <FormGroup className={classes.formGroup}>
        {showSelectAll() && (
          <FormControlLabel
            className={classes.text}
            name="all"
            control={
              <Checkbox
                disabled={disabled}
                classes={{
                  root: classes.radio,
                  checked: classes.checked,
                }}
                onChange={handleChangeAll}
              />
            }
            checked={allSelected}
            value={allSelected}
            label="Valitse kaikki"
          ></FormControlLabel>
        )}
        {options.map((item) => {
          const field = getFieldProps(`${name}.${item.value}`);

          return (
            <FormControlLabel
              key={item.value}
              disabled={disabled}
              className={classes.text}
              checked={field.value ? field.value : false}
              control={
                <Checkbox
                  classes={{
                    root: classes.radio,
                    checked: classes.checked,
                  }}
                  onChange={(): void => handleChange(field)}
                  name={`${name}.${item.value}`}
                />
              }
              label={item.label}
            ></FormControlLabel>
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

export default CheckboxGroup;
