import { Button, Grid, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { CustomerInterface, NewCustomerInterface } from '../../interfaces';
import CustomerClient from '../../lib/customer-client';
import AddCustomerModal from '../add-customer/add-customer-modal';

const useStyles = makeStyles({
  button: {
    backgroundColor: '#5E8EFC',
    fontWeight: 'bold',
  },
});

interface Props {
  customer?: CustomerInterface;
  getCustomersVat: any;
  title: string;
  editMode?: boolean;
}

const OrderCustomerModal: React.FC<Props> = ({
  getCustomersVat,
  title,
  customer,
  editMode = false,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const classes = useStyles();

  const onClickModal = (): void => {
    setModalOpen(!modalOpen);
  };

  const addCustomer = async (customer: NewCustomerInterface): Promise<void> => {
    await CustomerClient.newCustomer(customer);
  };

  const updateCustomer = async (
    newCustomer: NewCustomerInterface
  ): Promise<void> => {
    await CustomerClient.updateCustomer(newCustomer);
    if (customer) getCustomersVat(customer.businessid);
  };

  return (
    <Grid item>
      <AddCustomerModal
        modalOpen={modalOpen}
        onClickModal={onClickModal}
        addCustomer={editMode ? updateCustomer : addCustomer}
        customer={customer}
      ></AddCustomerModal>
      <Button
        className={classes.button}
        onClick={onClickModal}
        variant="contained"
        color="primary"
      >
        {title}
      </Button>
    </Grid>
  );
};

export default OrderCustomerModal;
