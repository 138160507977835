import React, { createContext, ReactNode, useReducer } from 'react';
import { ReservationInterface } from '../interfaces';
import { CampaignPriceInput } from '../types/campaign-price-input';
import { OrderBatch } from '../types/order-batch';

type State = {
  productId?: string;
  batches: OrderBatch[];
  price?: CampaignPriceInput;
  partialPayment?: boolean;
  isMultiMonth: boolean;
};

type Action =
  | { type: 'updateCampaignPrice'; payload: CampaignPriceInput }
  | { type: 'updateBatches'; payload: OrderBatch[] }
  | { type: 'createBatchesFromReservations'; payload: ReservationInterface[] }
  | { type: 'updatePartialPayment'; payload: boolean }
  | { type: 'updateProductId'; payload: string };
type Dispatch = (action: Action) => void;

const OrderFormContext = createContext<State | undefined>(undefined);
const OrderFormDispatchContext = createContext<Dispatch | undefined>(undefined);

const checkIsMultiMonth = (state: State): boolean => {
  return state.batches.some((batch) => {
    const { weeks, months, dates } = batch;
    if (weeks && weeks.length) {
      return false;
    }
    if (dates && dates.length) {
      const months = [...new Set(dates.map((date) => `${date.getMonth()}${date.getFullYear()}`))];
      return months.length >= 2;
    }
    if (months && months.length) {
      if (months.length >= 2) return true;
    }
    return false;
  });
};

const initializeBillingInfo = (state: State): boolean | undefined => {
  if (checkIsMultiMonth(state) && state.partialPayment === undefined) {
    return true;
  }

  return state.partialPayment;
};

const OrderFormReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'updateProductId':
      return { ...state, productId: action.payload };
    case 'updateBatches':
      return {
        ...state,
        batches: action.payload.map((batch) => batch),
        isMultiMonth: checkIsMultiMonth({
          ...state,
          batches: action.payload.map((batch) => batch),
        }),
        partialPayment: initializeBillingInfo({
          ...state,
          batches: action.payload.map((batch) => batch),
        }),
      };
    case 'updateCampaignPrice':
      return {
        ...state,
        price: action.payload,
      };
    case 'updatePartialPayment':
      return {
        ...state,
        partialPayment: action.payload,
      };
    default: {
      throw new Error('OrderFormContext error');
    }
  }
};

interface Props {
  children: ReactNode;
  initialValues?: State;
}

const OrderFormProvider: React.FC<Props> = ({ children, initialValues }: Props) => {
  const initialState = initialValues || {
    batches: [],
    isMultiMonth: false,
    partialPayment: undefined,
  };

  const [state, dispatch] = useReducer(OrderFormReducer, {
    ...initialState,
  });

  return (
    <OrderFormContext.Provider value={state}>
      <OrderFormDispatchContext.Provider value={dispatch}>
        {children}
      </OrderFormDispatchContext.Provider>
    </OrderFormContext.Provider>
  );
};

const useOrderFormState = (): State => {
  const context = React.useContext(OrderFormContext);
  if (context === undefined) throw new Error('Use OrderFormProvider');
  return context;
};

const useOrderFormDispatch = (): Dispatch => {
  const context = React.useContext(OrderFormDispatchContext);
  if (context === undefined) throw new Error('Use OrderFormProvider');
  return context;
};

export { OrderFormProvider, useOrderFormState, useOrderFormDispatch };
