/* eslint-disable @typescript-eslint/camelcase */
import {
  Button,
  Grid,
  makeStyles,
  Step,
  StepLabel,
  Stepper
} from '@material-ui/core';
import { useFormik } from 'formik';
import React, { ReactNode, useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  CustomerInterface,
  CustomerPRH,
  NewCustomerInterface,
  OVTOperator
} from '../../interfaces';
import CustomerClient from '../../lib/customer-client';
import {
  billingInfo,
  companyAddressInfo,
  contactPerson,
  customerInfo
} from './add-customer-schema';
import BillingInfo from './form/form-billing-info';
import CompanyInfo from './form/form-company-info';
import ContactPersons from './form/form-contact-person';
import CustomerInfo from './form/form-customer-info';

const useStyles = makeStyles(() => ({
  stepperStyle: {
    width: '100%',
  },
  buttonContainer: {
    width: '100%',
    marginTop: '30px',
    justifyContent: 'space-between',
  },
}));

interface ContactPersonProps {
  [key: string]: string;
}

interface StepperProps {
  onclickModal: Function;
  submit: (data: NewCustomerInterface) => Promise<void>;
  customer?: CustomerInterface;
}

const AddCustomerStepper: React.FC<StepperProps> = ({
  onclickModal,
  submit,
  customer,
}: StepperProps) => {
  const [activeStep, setActiveStep] = useState(0);
  const [initialValues, setInitialValues] = useState<{
    [key: string]: string | any;
  }>();
  const [formikInitialValues, setFormikInitial] = useState<any>({});
  const [validate, setValidate] = useState<any>({});
  const [ovtOperators, setOvtOperators] = useState<
    { label: string; value: string }[]
  >([{ label: 'operaattori', value: 'operaattori' }]);
  const [countries, setCountries] = useState<
    { label: string; value: string }[]
  >([{ label: 'maat', value: 'maat' }]);
  const [freechoice4, setFreechoice4] = useState<
    { label: string; value: string }[]
  >([{ label: 'operaattori', value: 'operaattori' }]);
  const [salesmen, setSalesmen] = useState<{ label: string; value: string }[]>(
    []
  );

  const onSubmit = async (data: any): Promise<void> => {
    const {
      invoiceAddress: address,
      invoiceCity,
      invoiceZipcode,
      invoiceCountry,
      invoiceId,
    } = data;

    const invoiceAddress = {
      invoiceAddress: address,
      invoiceCity: invoiceCity,
      invoiceZipcode: invoiceZipcode,
      invoiceCountry: invoiceCountry,
      id: invoiceId ? invoiceId : '',
    };
    if (customer) {
      data.id = customer.id;
      data.companyId = customer.companyId;
    }
    await submit({ ...data, invoiceAddress });

    onclickModal();
  };

  const initial: { [key: string]: string | any } = {};

  const validationSchema: {
    [key: string]:
      | Yup.StringSchema
      | Yup.NumberSchema
      | Yup.ArraySchema<ContactPersonProps>
      | Yup.ObjectSchema;
  } = {};

  const schemaBuilder = (field: any) => {
    const max = field.maxLength || 150;
    switch (field.type) {
      case 'string': {
        validationSchema[field.name] = field.required
          ? Yup.string()
              .required('Pakollinen kenttä')
              .max(max, `Pituus saa olla enintään ${max} merkkä!`)
          : Yup.string();
        break;
      }
      case 'stringOutlined':
        validationSchema[field.name] = field.required
          ? Yup.string().required('Pakollinen kenttä')
          : Yup.string();
        break;
      case 'number':
        validationSchema[field.name] = field.required
          ? Yup.number().required('Pakollinen kenttä')
          : Yup.number();
        break;
      case 'email':
        validationSchema[field.name] = field.required
          ? Yup.string()
              .email('Virheellinen sähköpostiosoite')
              .required('Pakollinen kenttä')
          : Yup.string().email();
        break;
      case 'select':
        validationSchema[field.name] = field.required
          ? Yup.string().required('Pakollinen kenttä')
          : Yup.string();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    billingInfo.fields.forEach((field) => {
      initial[field.name] = '';

      schemaBuilder(field);
    });

    companyAddressInfo.fields.forEach((field) => {
      initial[field.name] = '';

      schemaBuilder(field);
    });

    customerInfo.fields.forEach((field) => {
      initial[field.name] = '';

      schemaBuilder(field);
    });

    const array: any = [];
    const fields: any = {};
    const tempValid: any = {};

    contactPerson.fields.forEach((field) => {
      fields[field.name] = '';
      tempValid[field.name] = field.required;
    });

    array.push(fields);

    validationSchema[contactPerson.name] = Yup.array()
      .of(
        Yup.object().shape({
          firstname: tempValid['firstname']
            ? Yup.string().required('Pakollinen kenttä')
            : Yup.string(),
          lastname: tempValid['lastname']
            ? Yup.string().required('Pakollinen kenttä')
            : Yup.string(),
          email: tempValid['email']
            ? Yup.string()
                .email('Virheellinen sähköpostiosoite')
                .required('Pakollinen kenttä')
            : Yup.string().email('Virheellinen sähköpostiosoite'),
          phone: tempValid['phone']
            ? Yup.string().required('Pakollinen kenttä')
            : Yup.string(),
          department: tempValid['department']
            ? Yup.string().required('Pakollinen kenttä')
            : Yup.string(),
        })
      )
      .min(1);
    initial[contactPerson.name] = array;
    setValidate(validationSchema);
    setFormikInitial({ ...initial });
  }, []);

  useEffect(() => {
    const array: any = [];
    const fields: any = {};
    contactPerson.fields.forEach((field) => {
      fields[field.name] = '';
    });
    array.push(fields);
    companyAddressInfo.fields.forEach((field) => {
      initial[field.name] = initialValues?.[field.name] ?? '';
    });
    customerInfo.fields.forEach((field) => {
      initial[field.name] = initialValues?.[field.name] ?? '';
    });

    if (initialValues?.contactPerson)
      initial[`contactPerson`] =
        initialValues.contactPerson.length > 0
          ? [...initialValues?.contactPerson]
          : array;

    billingInfo.fields.forEach((field) => {
      initial[field.name] = initialValues?.[field.name] ?? '';
    });

    setFormikInitial({
      ...formikInitialValues,
      invoiceId: initialValues?.invoiceId,
      ...initial,
    });
  }, [initialValues]);

  const formik = useFormik({
    initialValues: formikInitialValues,
    validationSchema: Yup.object().shape(validate),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => onSubmit(values),
    enableReinitialize: true,
  });

  useEffect(() => {
    if (customer) {
      formik.setFieldValue('businessRegistration', customer.businessid);
    }
  }, [customer]);

  const getCustomerPRH = async (businessid: string): Promise<void> => {
    try {
      if (customer) {
        setInitialValues({
          businessRegistration: customer?.businessid,
          company: customer?.customerName,
          vatNumber: `FI${customer?.businessid.replace('-', '')}`,
          visitAddress: customer.visit_address,
          visitZipcode: customer.visit_zipcode,
          visitCity: customer.visit_city,
          visitCountry: customer.visit_country,
          salesman: customer.salesman,
          freeChoice4: customer.free_choice4,
          isdn1: customer.isdn_1,
          freeChoice6: customer.free_choice6,
          freeChoice5: customer.free_choice5,
          invoiceAddress: customer.invoiceAddresses[0]
            ? customer.invoiceAddresses[0].invoice_address
            : '',
          invoiceZipcode: customer.invoiceAddresses[0]
            ? customer.invoiceAddresses[0].invoice_zipcode
            : '',
          invoiceCity: customer.invoiceAddresses[0]
            ? customer.invoiceAddresses[0].invoice_city
            : '',
          invoiceCountry: customer.invoiceAddresses[0]
            ? customer.invoiceAddresses[0].invoice_country
            : '',
          invoiceId: customer.invoiceAddresses[0]
            ? customer.invoiceAddresses[0].id
            : '',
          contactPerson: customer.contacts,
          fax: customer.fax,
        });
      }
      if (businessid.length > 8 && !customer) {
        const result: Partial<CustomerPRH> | null = await CustomerClient.findCustomerFromPRH(
          businessid
        );

        if (result !== null) {
          const newInitialValues = {
            businessRegistration: result.businessRegistration,
            company: result.company,
            vatNumber: result.vatNumber,
            address: result.printAddress,
            printZipcode: result.printZipcode,
            printCity: result.printCity,
            printCountry: result.printCountry,
            visitAddress: result.visitAddress,
            visitCity: result.visitCity,
            visitZipcode: result.visitZipcode,
            visitCountry: result.visitCountry,
          };

          setInitialValues(newInitialValues);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getSalesmen = async (): Promise<void> => {
    try {
      const result: [OVTOperator] = await CustomerClient.getSalesmen();
      const salesmen = result.map((operator: OVTOperator) => {
        return { label: operator.value, value: operator.value };
      });
      setSalesmen(salesmen);
    } catch (error) {
      console.log(error);
    }
  };

  const getOVTOperators = async (): Promise<void> => {
    try {
      const result: [OVTOperator] = await CustomerClient.getOVTOperators();
      const newOVTOperators = result.map((operator: OVTOperator) => {
        return { label: operator.value, value: operator.value };
      });
      setOvtOperators(newOVTOperators);
    } catch (error) {
      console.log(error);
    }
  };

  const getCountries = async (): Promise<void> => {
    try {
      const result: [OVTOperator] = await CustomerClient.getCountries();

      const newOVTOperators = result.map((operator: OVTOperator) => {
        return { label: operator.key, value: operator.value };
      });
      setCountries(newOVTOperators);
    } catch (error) {
      console.log(error);
    }
  };

  const getFreechoice4 = async (): Promise<void> => {
    try {
      const result: [OVTOperator] = await CustomerClient.getFreechoice4();
      const newOVTOperators = result.map((operator: OVTOperator) => {
        return { label: operator.value, value: operator.value };
      });
      setFreechoice4(newOVTOperators);
    } catch (error) {
      console.log(error);
    }
  };

  const validateHelper = (schema: {
    name: string;
    type: string;
    fields: { name: string }[];
  }): void => {
    const values: any = {};
    let errors = false;

    schema.fields.forEach((field: { name: string }) => {
      values[field.name] = formik.values[field.name];
    });
    formik.validateForm().then((err) => {
      if (schema.type === 'inputArray') {
        if (err[schema.name]) {
          errors = true;
        }
      }
      if (schema.type === 'fields') {
        Object.keys(values).forEach((key) => {
          if (err[key]) {
            errors = true;
          }
        });
      }

      if (!errors) {
        formik.setErrors({});
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    });
  };

  const handleNext = (): void => {
    switch (activeStep) {
      case 0:
        validateHelper(customerInfo);
        break;
      case 1:
        validateHelper(companyAddressInfo);
        break;
      case 2:
        validateHelper(contactPerson);
        break;
      case 3:
        validateHelper(billingInfo);
        break;
      default:
        break;
    }
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (formik.values.businessRegistration) {
      getCustomerPRH(formik.values.businessRegistration);
    }
  }, [formik.values.businessRegistration]);

  useEffect(() => {
    getOVTOperators();
    getFreechoice4();
    getSalesmen();
    getCountries();
  }, []);

  const classes = useStyles();

  const getStepContent = (step: number): ReactNode => {
    switch (step) {
      case 0:
        return (
          <CustomerInfo
            formik={formik}
            handleChange={formik.handleChange}
            values={formik.values}
            schema={customerInfo}
            formikErrors={formik.errors}
            touched={formik.touched}
            updating={customer ? true : false}
          ></CustomerInfo>
        );
      case 1:
        companyAddressInfo.fields = companyAddressInfo.fields.map((field) => {
          if (field.name === 'visitCountry') {
            field.options = countries;
          }
          return field;
        });

        return (
          <CompanyInfo
            handleChange={formik.handleChange}
            values={formik.values}
            schema={companyAddressInfo}
            formikErrors={formik.errors}
            formik={formik}
            touched={formik.touched}
            updating={customer ? true : false}
          ></CompanyInfo>
        );
      case 2:
        return (
          <ContactPersons
            handleChange={formik.handleChange}
            values={formik.values}
            schema={contactPerson}
            setFieldValue={formik.setFieldValue}
            formikErrors={formik.errors}
            formik={formik}
            touched={formik.touched}
            updating={customer ? true : false}
          ></ContactPersons>
        );
      case 3:
        billingInfo.fields = billingInfo.fields.map((field) => {
          if (field.name === 'freeChoice6') {
            field.options = ovtOperators;
          }
          if (field.name === 'freeChoice4') {
            field.options = freechoice4;
          }
          if (field.name === 'salesman') {
            field.options = salesmen;
          }
          if (field.name === 'invoiceCountry') {
            field.options = countries;
          }

          return field;
        });
        return (
          <BillingInfo
            handleChange={formik.handleChange}
            values={formik.values}
            schema={billingInfo}
            formikErrors={formik.errors}
            formik={formik}
            touched={formik.touched}
            updating={customer ? true : false}
          ></BillingInfo>
        );

      default:
        return;
    }
  };

  const steps = [1, 2, 3, 4];
  return (
    <Grid container>
      <Stepper
        alternativeLabel
        orientation="horizontal"
        activeStep={activeStep}
        className={classes.stepperStyle}
      >
        {steps.map((a) => {
          return (
            <Step key={a}>
              <StepLabel></StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <form onSubmit={formik.handleSubmit}>
        <Grid container item>
          <Grid container item>
            {getStepContent(activeStep)}
          </Grid>
          <Grid
            container
            item
            justify="space-between"
            style={{ width: '100%', marginTop: '30px' }}
          >
            <Grid item>
              {activeStep > 0 && (
                <Button variant="contained" onClick={handleBack}>
                  takaisin
                </Button>
              )}
            </Grid>
            <Grid item>
              {activeStep < 3 ? (
                <Button
                  color="primary"
                  variant="contained"
                  type="button"
                  onClick={handleNext}
                >
                  seuraava
                </Button>
              ) : (
                <Button
                  key="submit-last-button-4"
                  color="primary"
                  variant="contained"
                  type="submit"
                >
                  lähetä
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
};

export default AddCustomerStepper;
