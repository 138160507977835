import { Button, Card, Grid, Link, makeStyles } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { Playlist, PlaylistItem } from '../interfaces';
import campaignClient from '../lib/campaign-client';
import playlistClient from '../lib/playlist-client';
import {
  mapItemIds,
  mapItemLength,
  rearrange,
  reorder,
} from '../utils/playlist-utils';
import DraggableContent from './draggable-content';
const useStyles = makeStyles(() => ({
  item: {
    width: '173px',
    height: '233px',
  },
  card: { margin: '10px' },
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridGap: '1px',
  },
}));
interface DragAndDropProps {
  selectedDate: Date;
  variation: string;
}
const DragAndDrop: React.FC<DragAndDropProps> = ({
  selectedDate,
  variation,
}: DragAndDropProps) => {
  const classes = useStyles();
  const [playlist, setPlaylist] = useState<Playlist>();
  const [playlistItems, setItems] = useState<PlaylistItem[]>();
  const [changedItems, setChanged] = useState<PlaylistItem[]>();

  useEffect(() => {
    const update = async (): Promise<void> => {
      changedItems?.map(async (item) => {
        if (item) {
          const tmp = {
            previousItemId: item.previousItemId,
            nextItemId: item.nextItemId,
            materialId: item.materialId,
          };
          return await playlistClient.updatePLaylistItem(item.id, tmp);
        }
      });
    };
    update();
  }, [changedItems]);

  useEffect(() => {
    const getPlaylist = async (): Promise<void> => {
      const foundPlaylist = await playlistClient.findPlaylistWithDateVariation(
        selectedDate,
        variation
      );

      setPlaylist(foundPlaylist);
      if (foundPlaylist) {
        if (foundPlaylist.playlistItems) {
          const itemArray = foundPlaylist.playlistItems.map(
            async (item: PlaylistItem) => {
              const campaign = await campaignClient.getCampaign(
                item.campaignId
              );

              if (campaign) {
                return { ...item, campaign };
              }
              return item;
            }
          );

          const rearranged = rearrange(await Promise.all(itemArray));

          setItems(rearranged);
        }
      }
    };

    if (selectedDate && variation) {
      getPlaylist();
    }
  }, [variation, selectedDate]);

  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }

    if (playlistItems) {
      const newArray = reorder(
        playlistItems,
        result.source.index,
        result.destination.index
      );

      const mappedItems = mapItemIds(newArray);

      setItems(mappedItems.array);
      setChanged(mappedItems.changed);
    }
  };
  const generateVideo = async (): Promise<void> => {
    if (playlist) {
      if (playlistItems) {
        try {
          const result = await playlistClient.generateVideo(playlist.id);
          setPlaylist(result);
        } catch (error) {
          throw new Error('Merging video failed');
        }
      }
    }
  };

  const mapItems = (): JSX.Element | JSX.Element[] => {
    const array: number[] = [];

    return playlistItems ? (
      playlistItems.map((item, i) => {
        array.push(item.quantity);
        const placement = mapItemLength(array);

        return (
          <Draggable
            key={'draggable-' + item.id}
            draggableId={item.id ? item.id : ''}
            index={i}
          >
            {(provided): ReactElement => (
              <Card
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={classes.card}
              >
                <DraggableContent
                  placement={placement}
                  item={item}
                ></DraggableContent>
              </Card>
            )}
          </Draggable>
        );
      })
    ) : (
      <></>
    );
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {playlist && (
        <Droppable direction="horizontal" droppableId="playlist">
          {(provided): ReactElement => (
            <Grid
              ref={provided.innerRef}
              {...provided.droppableProps}
              container
              className={classes.container}
            >
              {mapItems()}
            </Grid>
          )}
        </Droppable>
      )}
      <Button disabled={!playlist} onClick={generateVideo}>
        generoi video
      </Button>
      {playlist?.materialId && (
        <Link
          // eslint-disable-next-line no-undef
          href={`${process.env.REACT_APP_DOWNLOAD_MATERIAL_URL}/${playlist.materialId}`}
          target="_blank"
        >
          lataa video
        </Link>
      )}
    </DragDropContext>
  );
};

export default DragAndDrop;
