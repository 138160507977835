import { redirectTo, RouteComponentProps } from '@reach/router';
import React, { useEffect } from 'react';
import { Layout } from '../components';
import ReportContainer from '../containers/report-container';
import { getToken } from '../lib/storage-util';
const UserCreate: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    document.title = 'Sales Mediamyynti - Raportointi';
    const token = getToken();
    if (!token) {
      redirectTo('/');
    }
  }, []);

  return (
    <Layout tab="/report">
      <ReportContainer></ReportContainer>
    </Layout>
  );
};
export default UserCreate;
