/* eslint-disable @typescript-eslint/no-explicit-any */
import { navigate } from '@reach/router';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { createUploadLink } from 'apollo-upload-client';
import { DocumentNode } from 'graphql';
import { checkSession, setSession } from '../utils/session-utils';
import { clearStorage, getToken } from './storage-util';

class Client {
  constructor(
    private url: string,
    private headers: { [key: string]: string | number } = {}
  ) {}

  client = (): ApolloClient<NormalizedCacheObject> => {
    const cache = new InMemoryCache({
      addTypename: false,
      resultCaching: false,
    });

    const uploadLink = createUploadLink({
      uri: this.url,
      credentials: 'include',
      headers: {
        'X-Forwarded-Proto': 'https',
        'x-authorization-token': getToken(),
        ...this.headers,
        'keep-alive': true,
      },
    });
    return new ApolloClient({
      // Provide required constructor fields
      cache: cache,
      link: uploadLink,

      // Provide some optional constructor fields
      name: 'react-web-client',
      version: '1.3',
      queryDeduplication: false,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore',
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
        mutate: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
      },
    });
  };

  query = async (
    query: DocumentNode,
    variables: { [key: string]: string | number | Date | string[] | any } = {},
    tokenRequired = true
  ): Promise<{ data: any; errors: any }> => {
    const c = this.client();
    try {
      if (tokenRequired && checkSession()) setSession();
      else if (tokenRequired) {
        clearStorage();
        navigate('/');
      }
      const result = await c.query({
        query,
        variables,
      });
      const { errors, data } = result;
      return { data, errors };
    } catch (error) {
      return { data: undefined, errors: [{ message: error.message }] };
    }
  };

  mutate = async (
    mutation: DocumentNode,
    variables: { [key: string]: string | number | Date | boolean | any } = {},
    tokenRequired = true
  ): Promise<{ data: any; errors: any }> => {
    const c = this.client();
    try {
      if (tokenRequired && checkSession()) setSession();
      else if (tokenRequired) {
        clearStorage();
        navigate('/');
      }
      const result = await c.mutate({
        mutation,
        variables,
      });
      const { errors, data } = result;
      return { data, errors };
    } catch (error) {
      return { data: undefined, errors: [{ message: error.message }] };
    }
  };
}

export default Client;
