import {
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { ProductInterface, ProviderInterface } from '../interfaces';
import productClient from '../lib/product-client';
import DragAndDrop from './dragAndDrop';
const useStyles = makeStyles(() => ({
  container: {
    width: '390px',
    // height: (props: any) => props.height,
    backgroundColor: '#B1B8C00D',
    border: '1px solid #B1B8C026',
    padding: '20px',
    margin: '12.5px',
  },
}));

const Playlist: React.FC = () => {
  const classes = useStyles();
  const [selectedDate, setDate] = useState(new Date());
  const [selectedVariation, setVariation] = useState('');
  const [productProvider, setProvider] = useState<ProviderInterface>();
  const [productId, setProductId] = useState<string>('');
  const [product, setProduct] = useState<ProductInterface>();

  useEffect(() => {
    const getProvider = async (): Promise<void> => {
      const provider = await productClient.getProvider(
        // eslint-disable-next-line no-undef
        process.env.REACT_APP_PROVIDER_UUID || ''
      );

      const defaultProduct = provider.products?.find(
        (product) => product.defaultProduct
      );

      if (defaultProduct) setProductId(defaultProduct.id);

      setProvider({
        id: provider.id!,
        name: provider.name!,
        products: provider.products!,
      });
    };

    getProvider();
  }, []);

  useEffect(() => {
    const getProduct = async (): Promise<void> => {
      if (productId) {
        const product = await productClient.getProduct(productId);

        if (product) {
          setProduct({
            ...product,
          });
        }
      }
    };
    getProduct();
  }, [productId]);

  const onChange = (value: any) => {
    setDate(value.toDate());
  };
  const onChangeSelect = (e: any) => {
    setVariation(e.target.value);
  };

  const onChangeProduct = (e: any) => {
    setProductId(e.target.value);
  };

  return (
    <Grid direction="row" justify="space-between" container>
      <Grid item direction="column">
        {' '}
        <Typography>Soittolista</Typography>
        <Grid item container direction="row" spacing={2}>
          <Grid item>
            <DatePicker value={selectedDate} onChange={onChange}></DatePicker>
          </Grid>
          <Grid item>
            <Select value={selectedVariation} onChange={onChangeSelect}>
              {product &&
                product.variations?.map((variation) => {
                  return (
                    <MenuItem key={variation.id} value={variation.id}>
                      {variation.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </Grid>
        </Grid>
        <Grid item>
          <DragAndDrop
            selectedDate={selectedDate}
            variation={selectedVariation}
          ></DragAndDrop>
        </Grid>
      </Grid>
      <Grid item>
        <Grid item> </Grid>
      </Grid>
    </Grid>
  );
};

export default Playlist;
