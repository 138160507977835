import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { PlaylistItem } from '../interfaces';
const useStyles = makeStyles(() => ({
  item: {
    width: '173px',
    height: '233px',
  },
  headerTitle: {
    fontSize: '12px',
    textAlign: 'center',
  },
  headerRoot: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    padding: '5px',
    height: '100%',
    maxHeight: '25px',
  },
  cardActions: {
    padding: '0px',
    height: '100%',
    maxHeight: '36px',
    backgroundColor: 'rgba(177, 184, 192, 0.1)',
  },
  cardContent: {
    height: '100%',
    paddingLeft: '10px',
    paddingTop: '10px',
    paddingRight: '5px',
    paddingBottom: '10px',
  },
  cardSubtitle: {},
  detailsItem: { width: '100%' },
  detailsItemContainer: { justifyContent: 'space-between' },
  detailsText: {
    fontSize: '12px',
    color: '#00000099',
  },
  contentContainer: { justifyContent: 'space-between', height: '100%' },
  orderName: {
    fontSize: '14px',
    color: '#00000099',
    fontWeight: 500,
  },
  orderLength: {
    color: '#E30312',
    fontSize: '12px',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  viewButton: {
    width: '100%',
    height: '100%',
  },
}));

interface ContentProps {
  item: PlaylistItem;
  placement: number;
}

const DraggableContent: React.FC<ContentProps> = ({
  item,
  placement,
}: ContentProps) => {
  const classes = useStyles();
  const { campaign } = item;

  return (
    <Grid container className={classes.item} direction="column" wrap="nowrap">
      <CardHeader
        classes={{
          title: classes.headerTitle,
          root: classes.headerRoot,
        }}
        title={
          moment(new Date().setUTCMinutes(0, placement - item.quantity)).format(
            'mm:ss'
          ) +
          ' - ' +
          moment(new Date().setUTCMinutes(0, placement)).format('mm:ss')
        }
      ></CardHeader>
      <CardContent className={classes.cardContent}>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          className={classes.contentContainer}
        >
          <Grid item>
            <Typography className={classes.orderLength}>
              {moment(new Date().setUTCMinutes(0, item.quantity)).format(
                'mm:ss'
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.orderName}>
              {item.campaign ? item.campaign.campaignName : ''}
            </Typography>
          </Grid>
          <Grid item container>
            <Grid
              item
              container
              direction="row"
              wrap="nowrap"
              className={classes.detailsItemContainer}
            >
              <Grid item className={classes.detailsItem}>
                <Typography className={classes.detailsText}>
                  tilausnro
                </Typography>
              </Grid>
              <Grid item className={classes.detailsItem}>
                <Typography className={classes.detailsText}>123</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              wrap="nowrap"
              className={classes.detailsItemContainer}
            >
              <Grid className={classes.detailsItem} item>
                <Typography className={classes.detailsText}>asiakas</Typography>
              </Grid>
              <Grid className={classes.detailsItem} item>
                <Typography className={classes.detailsText}>
                  {campaign ? campaign.customer.customerName : ''}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              wrap="nowrap"
              className={classes.detailsItemContainer}
            >
              <Grid item className={classes.detailsItem}>
                <Typography className={classes.detailsText}>
                  esitysaika
                </Typography>
              </Grid>
              <Grid item className={classes.detailsItem}>
                <Typography className={classes.detailsText}>12</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Link
          className={classes.viewButton}
          // eslint-disable-next-line no-undef
          href={`${process.env.REACT_APP_VIEW_MATERIAL_URL}/${item.materialId}`}
          target="_blank"
        >
          <Button className={classes.viewButton}>Esikatsele</Button>
        </Link>
      </CardActions>
    </Grid>
  );
};

export default DraggableContent;
