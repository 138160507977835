import React, { useEffect, useState } from 'react';
import { RadioButtonGroup } from '../components';
import { useProviderDispatch } from '../context/provider-context';
import { ProviderInterface } from '../interfaces';
import productClient from '../lib/product-client';
import { getUserId } from '../lib/storage-util';
import userClient from '../lib/user-client';

interface ProductChooserProps {
  getFieldProps: Function;
  title: string;
  name: string;
  setProductId: (id?: string) => void;
  campaignData: boolean;
}
const ProductChooser: React.FC<ProductChooserProps> = ({
  getFieldProps,
  name,
  title,
  setProductId,
  campaignData,
}: ProductChooserProps) => {
  const [productProvider, setProvider] = useState<ProviderInterface>();
  const [restrictedProducts, setRestrictedProducts] = useState<string[]>();
  const providerDispatch = useProviderDispatch();

  useEffect(() => {
    const getUser = async (): Promise<void> => {
      try {
        const userId = getUserId();
        if (userId) {
          const user = await userClient.getUser(userId);
          if (
            user.organization.restrictedProducts &&
            user.organization.restrictedProducts.length > 0
          )
            setRestrictedProducts(user.organization.restrictedProducts);
        }
      } catch {
        //pass
      }
    };
    getUser();
  }, []);

  useEffect(() => {
    const getProvider = async (): Promise<void> => {
      try {
        const provider = await productClient.getProvider(
          // eslint-disable-next-line no-undef
          process.env.REACT_APP_PROVIDER_UUID || ''
        );
        setProvider({ ...provider });
        providerDispatch({ type: 'update', payload: { provider } });
      } catch (e) {
        console.log(e);
      }
    };
    getProvider();
  }, []);

  useEffect(() => {
    const defaultProductId = productProvider?.products.find(
      (product) => product.defaultProduct
    )?.id;
    if (!campaignData)
      setProductId(defaultProductId ? defaultProductId : productProvider?.products[0].id);
  }, [productProvider]);

  const options = productProvider?.products
    ? productProvider.products
        .filter((product) => (restrictedProducts ? !restrictedProducts.includes(product.id) : true))
        .map((product) => {
          return { label: product.name, value: product.id };
        })
    : [];

  return (
    <RadioButtonGroup
      options={options}
      getFieldProps={getFieldProps}
      title={title}
      name={name}
    ></RadioButtonGroup>
  );
};
export default ProductChooser;
