import { Grid, makeStyles, OutlinedInput, Typography } from '@material-ui/core';
import React, { ChangeEvent } from 'react';

const useStyles = makeStyles(() => ({
  container: {
    width: '390px',
    // height: (props: any) => props.height,
    backgroundColor: '#B1B8C00D',
    border: '1px solid #B1B8C026',
    padding: '20px',
    margin: '12.5px',
  },
}));

interface FormOutlinedInputProps {
  limitType?: string;
  name: string;
  setFieldValue: Function;
  min?: number;
}

const FormOutlinedInput: React.FC<FormOutlinedInputProps> = ({
  limitType,
  name,
  setFieldValue,
  min,
}: FormOutlinedInputProps) => {
  const classes = useStyles();

  const formLabel = (): string | undefined => {
    switch (limitType) {
      case 'month':
        return `Mainoksien määrä (kpl)`;
      case 'pieces':
        return `Mainoksien määrä (kpl)`;

      default:
        return;
    }
  };

  const onChange = (value: ChangeEvent<HTMLInputElement>): void => {
    if (value && value.currentTarget && value.currentTarget.value)
      setFieldValue(name, value.currentTarget.value + '');
  };

  return (
    <Grid direction="column" container className={classes.container}>
      <Typography>{formLabel()}</Typography>
      <Grid item>
        <OutlinedInput
          onChange={onChange}
          name={name}
          inputProps={{ min }}
          type="number"
        />
      </Grid>
    </Grid>
  );
};

export default FormOutlinedInput;
