interface SelectedMonths {
  year: number;
  month: number;
}
const monthChecker = (
  fullMonthArray: SelectedMonths[],
  first: SelectedMonths,
  month: number,
  year: number,
  style: any
) => {
  if (first) {
    const bigFull = fullMonthArray.find(
      (month: any) =>
        (month.month > first.month && month.year === first.year) ||
        month.year > first.year
    );
    const reversed = [...fullMonthArray.reverse()];

    const smallFull = reversed.find(
      (month: any) =>
        (month.month < first.month && month.year <= first.year) ||
        month.year < first.year
    );

    if (smallFull) {
      if (year === smallFull.year && month < smallFull.month) {
        style.disabled = true;
      }
      if (year < smallFull.year) style.disabled = true;
    }

    if (bigFull) {
      if (year === bigFull.year && month > bigFull.month) {
        style.disabled = true;
      }
      if (year > bigFull.year) style.disabled = true;
    }

    return style;
  }
};

export { monthChecker };
