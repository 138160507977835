import { Grid, makeStyles } from '@material-ui/core';
import { redirectTo } from '@reach/router';
import React, { useEffect } from 'react';
import Image from '../assets/login-background.png';
import { Layout } from '../components';
import LoginContainer from '../containers/login-container';
import { getToken } from '../lib/storage-util';

const useStyles = makeStyles(() => ({
  loginContainer: {
    width: '100%',
    minHeight: '100%',
    backgroundImage: `url(${Image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    justifyContent: 'center',
  },
}));

const Login: React.FC = () => {
  const classes = useStyles();
  useEffect(() => {
    document.title = 'Sales Mediamyynti - Login';
    const token = getToken();
    if (token) {
      redirectTo('/order');
    }
  }, []);

  return (
    <Layout>
      <Grid wrap="nowrap" className={classes.loginContainer} container>
        <LoginContainer />
      </Grid>
    </Layout>
  );
};
export default Login;
