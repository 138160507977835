import {
  ButtonBase,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { ResetPasswordInput } from '../interfaces';
import UserClient from '../lib/user-client';

const useStyles = makeStyles(() => ({
  inputs: {
    width: '328px',
    height: '80px',
    marginBottom: '40px',
  },
  input: {
    color: '#5E8EFC',
    fontSize: '16px',
    letterSpacing: '0.15px',
  },
  error: {
    color: 'red',
    marginBottom: '5px',
  },
  infomsg: {
    color: '#5E8EFC',
    fontSize: '16px',
    letterSpacing: '0.15px',
  },

  buttonBase: {
    height: '48px',
    width: '184px',
    backgroundColor: '#5E8EFC',

    borderRadius: '4px',
  },
  submitText: {
    color: 'white',
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: '1.25px',
  },
  gridCenter: {
    'align-items': 'center',
    'flex-direction': 'column',
  },
}));

interface MatchParams {
  resetToken: string;
}
type Props = RouteComponentProps<MatchParams>;

const SetPasswordForm: React.FC<Props> = ({ resetToken }: Props) => {
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [resetError, setResetError] = useState(false);
  const classes = useStyles();
  const resetTokenIsValid = async (resetToken: string): Promise<void> => {
    try {
      const isResetTokenValid = await UserClient.isValidResetToken(resetToken);
      setTokenIsValid(isResetTokenValid);
    } catch (error) {
      console.log(error);
    }
  };
  const validationSchema = Yup.object({
    password: Yup.string().required('Pakollinen kenttä'),
  });

  const onSubmit = async ({
    password,
  }: {
    password: string;
  }): Promise<void> => {
    try {
      const resetPasswordInput: ResetPasswordInput = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        token: resetToken!,
        password: password,
      };
      await UserClient.resetPassword(resetPasswordInput);
      if (resetError) setResetError(false);
      navigate('/');
    } catch (error) {
      setResetError(true);
    }
  };
  const formik = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema,
    onSubmit: onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  useEffect(() => {
    resetTokenIsValid(resetToken || '');
  }, [resetToken]);

  if (tokenIsValid) {
    return (
      <form onSubmit={formik.handleSubmit}>
        <Grid container className={classes.gridCenter}>
          <Typography className={classes.infomsg}>
            <ul>
              Salasanan tulee:
              <li>Olla vähintään 8 merkkiä pitkä</li>
              <li>Sisältää vähintään yhden pienen ja ison kirjaimen</li>
              <li>Sisältää vähintään yhden numeron</li>
            </ul>
          </Typography>
          <Grid item container className={classes.gridCenter}>
            <Grid className={classes.error}>
              {formik.errors.password ? formik.errors.password : null}
            </Grid>
            <TextField
              helperText="Uusi salasana"
              variant="outlined"
              type="password"
              id="password"
              name="password"
              onChange={formik.handleChange}
              value={formik.values.password}
              className={classes.inputs}
              InputProps={{
                className: classes.input,
              }}
            ></TextField>
          </Grid>
          <Grid item className={classes.gridCenter} container>
            {resetError ? (
              <Typography className={classes.error}>
                Jotain meni vikaan. Tarkista, että salasana täyttää vaatimukset
              </Typography>
            ) : (
              <></>
            )}
            <ButtonBase className={classes.buttonBase} type="submit">
              <Typography className={classes.submitText}>
                Aseta salasana
              </Typography>
            </ButtonBase>
          </Grid>
        </Grid>
      </form>
    );
  }
  return (
    <Grid container className={classes.gridCenter}>
      <Grid item container className={classes.gridCenter}>
        <Typography className={classes.infomsg}>
          Virheellinen tai vanhentunut linkki
        </Typography>
      </Grid>
    </Grid>
  );
};
export default SetPasswordForm;
