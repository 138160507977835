const getToken = (): string | null => {
  return localStorage.getItem('token');
};

const saveToken = (token: string): void => {
  localStorage.setItem('token', token.toString());
};

const clearToken = (): void => {
  localStorage.removeItem('token');
};

const saveUserId = (id: string): void => {
  localStorage.setItem('userId', id.toString());
};

const getUserId = (): string | null => {
  return localStorage.getItem('userId');
};

const clearStorage = (): void => {
  localStorage.removeItem('token');
  localStorage.removeItem('userId');
};

export { getToken, saveToken, clearToken, clearStorage, saveUserId, getUserId };
