import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useOrderFormState } from '../../context/order-form-context';

const useStyles = makeStyles(() => ({
  inputContainer: {
    justifyContent: 'space-between',
  },
  rightItem: {
    width: '400px',
  },
  boldText: {
    fontWeight: 'bold',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
}));

const OrderSummaryFormPriceSummary: React.FC = () => {
  const orderFormState = useOrderFormState();
  const classes = useStyles();
  const { price } = orderFormState;

  return (
    <>
      <Grid
        className={classes.inputContainer}
        container
        alignItems="flex-end"
        style={{ marginBottom: '20px' }}
        item
      >
        <Grid item>
          <Typography className={classes.title}>Yhteensä Alv 0%</Typography>
        </Grid>
        <Grid item className={classes.rightItem}>
          <Typography className={classes.title}>{`${
            price ? (Math.round((price.totalPrice - price.vat) * 100) / 100).toFixed(2) : 0
          }€`}</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.inputContainer}
        alignItems="flex-end"
        style={{ marginBottom: '20px' }}
        item
      >
        <Grid item>
          <Typography className={classes.title}>Alv 25,5%</Typography>
        </Grid>
        <Grid item className={classes.rightItem}>
          <Typography>{`${price ? price.vat.toFixed(2) : 0}€`}</Typography>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.inputContainer}
        alignItems="flex-end"
        style={{ marginBottom: '20px' }}
        item
      >
        <Grid item>
          <Typography className={classes.title}>Yhteensä</Typography>
        </Grid>
        <Grid item className={classes.rightItem}>
          <Typography className={classes.boldText}>
            {`${price ? price.totalPrice.toFixed(2) : 0}€`}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default OrderSummaryFormPriceSummary;
