import { redirectTo, RouteComponentProps } from '@reach/router';
import React, { useEffect } from 'react';
import { Layout } from '../components';
import UserCreateContainer from '../containers/user-create-container';
import { getToken } from '../lib/storage-util';

const UserCreate: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    document.title = 'Sales Mediamyynti - New User';
    const token = getToken();
    if (!token) {
      redirectTo('/');
    }
  }, []);

  return (
    <Layout tab="/newuser">
      <UserCreateContainer></UserCreateContainer>
    </Layout>
  );
};
export default UserCreate;
