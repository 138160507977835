import { setSession } from '../../utils/session-utils';
import { getToken } from '../storage-util';

const downloadOfferPdf = async (campaignId: string): Promise<void> => {
  setSession();
  // eslint-disable-next-line no-undef
  const url = process.env.REACT_APP_CAMPAIGN_OFFER_PDF || '';
  const result = await fetch(`${url}?campaignId=${campaignId}`, {
    headers: {
      'x-authorization-token': getToken() || '',
    },
    credentials: 'include',
  });
  const blob = await result.blob();
  const file = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = file;
  a.download = 'offer.pdf';
  document.body.appendChild(a);
  a.click();
  a.remove();
};

export default downloadOfferPdf;
