import { CampaignDetail, parseCampaignDetail } from './campaign-detail';
import { EmailContext } from './email-context';

export type CampaignInput = {
  description: string;
  customer: string;
  customerEmail: string;
  customerName?: string;
  contactPerson: string;
  materialSupplierEmail: string;
  campaignName: string;
  reference: string;
  offer: boolean;
  campaignDetails: CampaignDetail[];
  //optionals
  floating?: boolean;
  floatingAmount?: number;
  floatingQuantity?: number;
  floatingVariations?: string;
  floatingBatchName?: string;
  materialApproverPhone?: string;
  materialSupplierPhone?: string;
  invoiceInstruction?: string;
  offerText?: string;
  materialGuide?: string;
  advancePayment?: boolean;
  freeText?: string;
  attachments?: { filename: string; path: string }[];
  materialApproverEmail?: string;
  additionalInfo?: string;
  offerPdf?: boolean;
  email?: EmailContext;
};

const parseCampaignInput = ({
  campaignDetails,
  description,
  customer,
  customerEmail,
  customerName,
  contactPerson,
  materialSupplierEmail,
  campaignName,
  reference,
  offer = false,
  floating,
  floatingAmount,
  floatingQuantity,
  floatingVariations,
  floatingBatchName,
  materialApproverEmail,
  materialApproverPhone,
  invoiceInstruction,
  offerText,
  materialGuide,
  advancePayment,
  freeText,
  attachments,
  materialSupplierPhone,
  additionalInfo,
  offerPdf,
  email,
}: Partial<CampaignInput>): CampaignInput => {
  if (!campaignDetails) throw new Error('Missing camapign details');
  const details = campaignDetails.map((detail) => parseCampaignDetail(detail));
  console.log(
    description,
    reference,
    customer,
    customerEmail,
    customerName,
    contactPerson,
    materialSupplierEmail,
    campaignName
  );
  if (
    typeof description !== 'string' ||
    typeof reference !== 'string' ||
    !customer ||
    !customerEmail ||
    !contactPerson ||
    !materialSupplierEmail ||
    !campaignName
  )
    throw new Error('Missing field for campaign input');
  return {
    offer,
    description,
    customer,
    customerEmail,
    customerName,
    contactPerson,
    materialSupplierEmail,
    campaignName,
    reference,
    campaignDetails: details,
    floating,
    floatingAmount,
    floatingQuantity,
    floatingVariations,
    floatingBatchName,
    materialApproverEmail,
    materialApproverPhone,
    invoiceInstruction,
    offerText,
    materialGuide,
    advancePayment,
    freeText,
    attachments,
    materialSupplierPhone,
    additionalInfo,
    offerPdf,
    email,
  };
};

export { parseCampaignInput };
