export const customerInfo = {
  label: 'Asiakkaan tiedot',
  type: 'fields',
  name: 'customerDetails',
  fields: [
    {
      name: 'businessRegistration',
      label: 'Y-tunnus',
      type: 'stringOutlined',
      placeHolder: '',
      required: true,
    },
    {
      name: 'vatNumber',
      label: 'VAT-numero',
      type: 'stringOutlined',
      placeHolder: '',
      required: true,
    },
    {
      name: 'company',
      label: 'Yrityksen virallinen nimi:',
      type: 'string',
      placeHolder: '',
      required: true,
      maxLength: 50,
    },
  ],
};

export const companyAddressInfo = {
  label: 'Yrityksen osoitetiedot',
  type: 'fields',
  name: 'companyDetails',
  fields: [
    {
      name: 'visitAddress',
      label: 'Osoite',
      type: 'string',
      placeHolder: '',
      required: false,
      fullWidth: true,
    },
    {
      name: 'visitZipcode',
      label: 'Postinumero',
      type: 'string',
      placeHolder: '',
      required: false,
    },
    {
      name: 'visitCity',
      label: 'Postitoimipaikka',
      type: 'string',
      placeHolder: '',
      required: false,
    },
    {
      name: 'visitCountry',
      label: 'Maa',
      type: 'select',
      placeHolder: '',
      required: false,
      options: [{}],
    },
    {
      name: 'fax',
      label: 'Osasto',
      type: 'string',
      placeHolder: '',
      required: false,
    },
  ],
};

export const contactPerson = {
  label: 'Yhteyshenkilöt',
  type: 'inputArray',
  name: 'contactPerson',
  fields: [
    {
      name: 'firstname',
      label: 'Etunimi',
      type: 'string',
      placeHolder: '',
      required: false,
    },
    {
      name: 'lastname',
      label: 'Sukunimi',
      type: 'string',
      placeHolder: '',
      required: false,
    },
    {
      name: 'email',
      label: 'Sähköposti',
      type: 'email',
      placeHolder: '',
      required: false,
      fullWidth: true,
    },
    {
      name: 'phone',
      label: 'Puhelin',
      type: 'phone',
      placeHolder: '',
      required: false,
    },
    {
      name: 'department',
      label: 'Osasto',
      type: 'string',
      placeHolder: '',
      required: false,
    },
  ],
};

export const billingInfo = {
  label: 'Yrityksen Laskutustiedot',
  name: 'invoice_address',
  type: 'fields',
  fields: [
    {
      name: 'invoiceAddress',
      label: 'Osoite',
      type: 'string',
      placeHolder: '',
      required: false,
      fullWidth: true,
    },
    {
      name: 'invoiceZipcode',
      label: 'Postinumero',
      type: 'string',
      placeHolder: '',
      required: false,
    },
    {
      name: 'invoiceCity',
      label: 'Postitoimipaikka',
      type: 'string',
      placeHolder: '',
      required: false,
    },
    {
      name: 'invoiceCountry',
      label: 'Maa',
      type: 'select',
      placeHolder: '',
      required: false,
      options: [{}],
    },
    {
      name: 'salesman',
      label: 'Myyjä',
      type: 'select',
      options: [{}],
      placeHolder: '',
      required: false,
      fullWidth: true,
    },
    {
      name: 'freeChoice4',
      label: 'Laskutustapa',
      type: 'select',
      options: [{}],
      placeHolder: '',
      required: false,
      fullWidth: true,
    },
    {
      name: 'freeChoice6',
      label: 'OVT-operaattori',
      type: 'select',
      options: [{}],
      placeHolder: '',
      required: false,
      fullWidth: true,
    },
    {
      name: 'isdn1',
      label: 'Sähköinen laskutusosoite',
      type: 'string',
      placeHolder: '',
      required: false,
      fullWidth: true,
    },
  ],
};
