import { CardMedia, Grid, makeStyles, Typography } from '@material-ui/core';
import { navigate } from '@reach/router';
import React, { useState } from 'react';
import PMLogo from '../assets/punamusta-logo.png';
import LoginForm from '../components/login-form';
import { saveToken, saveUserId } from '../lib/storage-util';
import UserClient from '../lib/user-client';
import { setSession } from '../utils/session-utils';

const useStyles = makeStyles(() => ({
  loginComponent: {
    marginTop: '93px',
    width: '581px',
    height: '739px',
    borderRadius: '10px',
    marginBottom: '20px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 20px #00000029',
    border: '1px solid #70707033',
  },
  title: {
    letterSpacing: ' 0.52px',
    color: '#E30312',
    fontSize: '40px',
    fontWeight: 900,
    lineHeight: '47px',
    marginBottom: '62px',
  },
  loginText: {
    letterSpacing: ' 0.9px',
    color: '#000000',
    fontSize: '18px',
    fontWeight: 900,
    lineHeight: '21px',
    marginBottom: '50px',
    textTransform: 'uppercase',
  },
  logo: { width: '130px', height: '24px', marginTop: '62px' },
  container: {
    height: '100%',
  },
}));

const LoginContainer: React.FC = () => {
  const [loginError, setLoginError] = useState(false);
  const classes = useStyles();

  const handleLogin = async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }): Promise<void> => {
    try {
      const result = await UserClient.getToken(username, password);
      const { token, id } = result;
      if (!token || !id) throw new Error('Can not get userid or Token');
      if (loginError) setLoginError(false);

      saveToken(token);
      saveUserId(id);
      setSession();
      navigate('/order');
    } catch (error) {
      setLoginError(true);
    }
  };

  return (
    <Grid item className={classes.loginComponent}>
      <Grid
        className={classes.container}
        alignItems="center"
        container
        justifyContent="center"
        direction="column"
      >
        <Grid item>
          <Typography className={classes.title} variant="h5">
            MediaMyynti
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.loginText} variant="h5">
            Kirjaudu sisään
          </Typography>
        </Grid>
        <Grid item>
          <LoginForm
            handleLogin={handleLogin}
            loginError={loginError}
          ></LoginForm>
        </Grid>
        <CardMedia
          component="img"
          image={PMLogo}
          className={classes.logo}
        ></CardMedia>
      </Grid>
    </Grid>
  );
};
export default LoginContainer;
