import { Grid, Typography } from '@material-ui/core';
import { FormikErrors } from 'formik';
import moment from 'moment';
import 'moment/locale/fi';
import React, { ReactElement } from 'react';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import { AvailabilityInterface, ProductInterface, ReservationSummary } from '../../interfaces';
import reservationClient from '../../lib/reservation-client';
import { modifiers } from '../../utils/datepicker-modifiers';
import ArrowButton from '../arrow-button';
import OrderDatePickerInputs from './order-date-range-input';

const StyledWrapper = styled.div`
  .DayPicker-Day {
    border-radius: 0;
    margin: 1px;
  }

  .DayPicker-Day--selected {
    background-color: #5e8efc;
    border-radius: 0;
    border: 4px solid #fbfbfc;
  }

  .DayPicker-Day--freeSpace {
    background-color: #c6f3d5;
    color: black;
    border-radius: 0;
    border: 4px solid #fbfbfc;
  }

  .DayPicker-Day--mediumSpace {
    background-color: #f7f119;
    border-radius: 0;
    border: 4px solid #fbfbfc;
  }

  .DayPicker-Day--full {
    background-color: #ff6f75;
    cursor: auto;
    color: black;
    border-radius: 0;
    border: 4px solid #fbfbfc;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: #5e8efc;
    opacity: 0.7;
  }

  .DayPicker-Day--outside {
    background-color: initial;
    border: none;
  }

  .DayPicker-NavBar {
    display: none;
  }

  .asd:hover {
    display: block;
  }

  .DayPicker-Caption > div {
    font-weight: 700 !important;
  }
  background-color: rgba(177, 184, 192, 0.05);
  border: 1px solid #b1b8c026;
`;

const StyledError = styled.h5`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  letter-spacing: 0.25px;
  line-height: 16px;
  font-weight: 400;
  color: #e30312;
`;

type DaySummary = {
  name: string;
  summary: ReservationSummary[];
};

type State = {
  showLeftButton: boolean;
  summaryDate: Date | undefined;
  daySummary: DaySummary[];
  loading: boolean;
};

type OrderDatePickerProps = {
  name: string;
  setDates: Function;
  mappedReservations: AvailabilityInterface | undefined;
  setStartDate: Function;
  startDate: moment.Moment;
  fieldError: string | string[] | FormikErrors<Date>[] | undefined;
  campaignDates: Date[];
  notifyUser: boolean;
  product: ProductInterface;
  noOffset: boolean;
  getMappedReservations: Function;
};

class OrderDatePicker extends React.Component<OrderDatePickerProps> {
  state: Readonly<State> = {
    showLeftButton: false,
    summaryDate: undefined,
    daySummary: [],
    loading: false,
  };

  componentDidMount(): void {
    const { campaignDates, name, setDates } = this.props;

    if (campaignDates) {
      setDates(name, campaignDates);
    }
  }

  componentDidUpdate(nextProps: OrderDatePickerProps): void {
    const { mappedReservations } = this.props;
    const temp = [...nextProps.campaignDates];
    if (nextProps.mappedReservations !== mappedReservations) {
      if (mappedReservations) {
        mappedReservations.full.map((day): void => {
          const sameDay = temp.find((item) => moment(item).isSame(day, 'day'));
          if (sameDay) {
            temp.splice(
              temp.findIndex((item) => moment(item).isSame(day, 'day')),
              1
            );
          }
        });
        if (temp.length !== nextProps.campaignDates.length) {
          this.props.setDates(this.props.name, temp);
          if (this.props.notifyUser)
            alert(
              'Kaikkia haluttuja ajanjaksoja ei pysyttty valitsemaan, sillä ne ovat täyttyneet. Seuraavia päiviä ei voitu valita: ' +
                nextProps.campaignDates
                  .filter((day) => !temp.includes(day))
                  .map((day) => `${day.getDate()}.${day.getMonth()}.${day.getFullYear()}`)
                  .join(', ')
            );
        }
      }
    }
  }

  handleLeftClick = (): void => {
    const { setStartDate, startDate } = this.props;
    if (moment(startDate).subtract(1, 'month').isBefore(moment())) {
      this.setState({ showLeftButton: false });
    }
    if (
      moment(startDate).subtract(1, 'month').isAfter(moment()) ||
      moment(startDate).subtract(1, 'month').isSame(moment(), 'day')
    ) {
      setStartDate(moment(startDate).subtract(1, 'month'));
    }
  };

  handleRightCLick = (): void => {
    const { setStartDate, startDate } = this.props;
    setStartDate(moment(startDate).add(1, 'month'));
    this.setState({ showLeftButton: true });
  };

  handleDayClick = async (day: Date, { selected, disabled, full }: any): Promise<void> => {
    const { campaignDates } = this.props;
    const { name, setDates } = this.props;
    const temp = [...campaignDates];

    if (disabled || full) return;

    if (selected) {
      const selectedIndex = temp.findIndex((selectedDay: Date) =>
        DateUtils.isSameDay(selectedDay, day)
      );
      temp.splice(selectedIndex, 1);
    } else {
      temp.push(day);
    }

    await setDates(name, temp);
  };

  handleDayRange = async (range: any): Promise<void> => {
    const { campaignDates, name, getMappedReservations, setDates } = this.props;
    const mappedReservations = await getMappedReservations(range.startDate, range.endDate);
    const startDate = range.startDate;
    const endDate = range.endDate;
    let dateRange: Date[] = [];
    const date = new Date(startDate);

    while (date <= endDate) {
      dateRange = [...dateRange, new Date(date)];
      date.setDate(date.getDate() + 1);
    }
    if (mappedReservations.full.length > 0) setDates(name, []);
    else setDates(name, dateRange);
  };

  handleMouseOver = (day: Date): void => {
    if (moment(day).isSameOrAfter(moment().add(this.props.noOffset ? 0 : 3, 'day'))) {
      this.setState({ summaryDate: day, loading: true });

      setTimeout(() => {
        if (day === this.state.summaryDate) {
          const { product } = this.props;
          if (product.variations) {
            Promise.all(
              product.variations.map(async (variation) => {
                return {
                  name: variation.name,
                  summary: await reservationClient.getReservationSummary(
                    variation.id,
                    new Date(moment(day).format('YYYY-MM-DD')),
                    new Date(moment(day).format('YYYY-MM-DD'))
                  ),
                };
              })
            ).then((data) => {
              this.setState({ daySummary: data, loading: false });
            });
          }
        }
      }, 750);
    }
  };

  handleMouseLeave = (): void => {
    this.setState({ summaryDate: undefined, daySummary: [] });
  };

  render(): ReactElement {
    const { showLeftButton, daySummary, summaryDate, loading } = this.state;
    const { startDate, mappedReservations, fieldError, campaignDates } = this.props;

    return (
      <>
        <OrderDatePickerInputs
          minDate={new Date(new Date().setDate(new Date().getDate() + (this.props.noOffset ? 1 : 4)))}
          campaignDates={campaignDates}
          handleDateRange={this.handleDayRange}
        />
        <Grid
          container
          style={{ marginLeft: '-35px', marginTop: '12.5px' }}
          direction="column"
          alignItems="flex-start"
        >
          <Grid item container direction="row" alignItems="center">
            <Grid item>
              <ArrowButton
                disableButton={!showLeftButton}
                direction="left"
                handleClick={this.handleLeftClick}
              ></ArrowButton>
            </Grid>
            <Grid item>
              <StyledWrapper>
                <DayPicker
                  onDayMouseEnter={this.handleMouseOver}
                  onDayMouseLeave={this.handleMouseLeave}
                  locale="fi"
                  showWeekNumbers
                  month={moment(startDate).toDate()}
                  localeUtils={MomentLocaleUtils}
                  selectedDays={campaignDates}
                  modifiers={modifiers(mappedReservations, startDate, this.props.noOffset ? 0 : 3)}
                  numberOfMonths={2}
                  disabledDays={{
                    before: moment(new Date())
                      .add(this.props.noOffset ? 1 : 4, 'day')
                      .toDate(),
                  }}
                  onDayClick={this.handleDayClick}
                ></DayPicker>
              </StyledWrapper>
            </Grid>
            <Grid item>
              <ArrowButton direction="right" handleClick={this.handleRightCLick}></ArrowButton>
            </Grid>
          </Grid>
          {summaryDate && (
            <Grid
              item
              style={{
                marginLeft: '48px',
                backgroundColor: 'rgba(177, 184, 192, 0.05)',
                border: ' 1px solid #b1b8c026',
                padding: '10px',
              }}
            >
              <Grid container direction="column">
                <Typography>Päivän täyttöaste</Typography>
                <Typography>{moment(summaryDate).format('DD.MM.YYYY')}</Typography>
                {loading && (
                  <Grid container direction="column" alignContent="center">
                    <Loader type="ThreeDots" color="#E30613" height={50} width={50}></Loader>
                  </Grid>
                )}
                {daySummary.length > 0 &&
                  daySummary.map((day) => (
                    <Grid
                      style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                      }}
                      container
                      direction="row"
                      key={day.name}
                    >
                      <Grid item>{day.name}:</Grid>
                      {day.summary.length > 0 ? (
                        day.summary.map((item, i) => (
                          <Grid key={i + day.name} item>
                            Varattu {item.limit - item.available}/{item.limit}
                          </Grid>
                        ))
                      ) : (
                        <Grid item>Ei varauksia</Grid>
                      )}
                    </Grid>
                  ))}
              </Grid>
            </Grid>
          )}
          <StyledError>{fieldError ? fieldError : null}</StyledError>
        </Grid>
      </>
    );
  }
}

export default OrderDatePicker;
