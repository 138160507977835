export const dayAd = {
  title: 'Mainoksen pituus',
  options: [
    { label: '5 sekuntia', value: '5' },
    { label: '10 sekuntia', value: '10' },
    { label: 'muu', value: 'other' },
  ],
  otherInput: {
    name: 'otherLength',
    type: 'number',
    min: 5,
    adornment: 's',
    step: '5',
  },
};

export const weekAd = {
  title: 'Mainoksen pituus',
  options: [
    { label: '10 sekuntia', value: '10' },
    { label: '20 sekuntia', value: '20' },
    { label: 'muu', value: 'other' },
  ],
  otherInput: {
    name: 'otherLength',
    type: 'number',
    min: 10,
    adornment: 's',
    step: '10',
  },
};
