import gql from 'graphql-tag';
import {
  Playlist,
  PlaylistItem,
  PlaylistItemTemplate,
  UpdateItemInput,
} from '../interfaces';
import Client from './apollo-client';

class PlaylistClient {
  private client: Client;
  constructor() {
    // eslint-disable-next-line no-undef
    const url = process.env.REACT_APP_PLAYLIST_SERVICE_URL || '';
    this.client = new Client(url);
  }
  findPlaylistWithDateVariation = async (
    date: Date,
    productVariationId: string
  ): Promise<Playlist> => {
    try {
      const FIND_PLAYLIST_DATE_VARIATION = gql`
        query findListWithDateVariation(
          $date: DateTime!
          $productVariationId: String!
        ) {
          findListWithDateVariation(
            date: $date
            productVariationId: $productVariationId
          ) {
            id
            date
            name
            productVariationId
            productId
            playlistItems {
              id
              previousItemId
              nextItemId
              campaignId
              materialId
              quantity
            }
          }
        }
      `;
      const result = await this.client.query(FIND_PLAYLIST_DATE_VARIATION, {
        date,
        productVariationId,
      });

      return result.data.findListWithDateVariation;
    } catch (error) {
      throw new Error('Cannot get playlist');
    }
  };

  addPlaylist = async (): Promise<Playlist> => {
    try {
      const ADD_PLAYLIST = gql`
        mutation addPlaylist {
          addPlaylist(
            playlist: {
              date: "Thu Jul 23 2020 10:53:16 GMT+0300 (Eastern European Summer Time)"
              name: "testi"
              productVariationId: "varID"
              productId: "prodID"
            }
          ) {
            id
            date
            name
            productVariationId
            productId
          }
        }
      `;
      const result = await this.client.mutate(ADD_PLAYLIST, {});
      return result.data;
    } catch (error) {
      throw new Error('Cannot get customer');
    }
  };

  addPlaylistItemTemplate = async (
    quantity: number,
    materialId: string,
    name: string
  ): Promise<PlaylistItemTemplate> => {
    try {
      const ADD_PLAYLIST_ITEM_TEMPLATE = gql`
        mutation addPlaylistItemTemplate(
          $quantity: Float!
          $materialId: String!
          $name: String!
        ) {
          addPlaylistItemTemplate(
            playlistItemTemplate: {
              quantity: $quantity
              materialId: $materialId
              name: $name
            }
          ) {
            id
            materialId
            quantity
            name
          }
        }
      `;

      const result = await this.client.mutate(ADD_PLAYLIST_ITEM_TEMPLATE, {
        quantity,
        materialId,
        name,
      });

      return result.data;
    } catch (error) {
      throw new Error('Cannot add Template:' + error.message);
    }
  };

  getPlaylistItemTemplates = async (): Promise<PlaylistItemTemplate[]> => {
    try {
      const GET_PLAYLIST_ITEM_TEMPLATE = gql`
        query listPlaylistItemTemplatess {
          playlistItemTemplates {
            id
            materialId
            quantity
            name
          }
        }
      `;

      const result = await this.client.query(GET_PLAYLIST_ITEM_TEMPLATE, {});

      return result.data.playlistItemTemplates;
    } catch (error) {
      throw new Error('Cannot get Template:' + error.message);
    }
  };
  updatePLaylistItem = async (
    id: string,
    playlistItem: UpdateItemInput
  ): Promise<PlaylistItem> => {
    try {
      const UPDATE_PLAYLIST_ITEM = gql`
        mutation updateItem($id: String!, $playlistItem: UpdateItemInput!) {
          updateItem(id: $id, playlistItem: $playlistItem) {
            id
            playlist {
              id
            }
            previousItemId
            nextItemId
            campaignId
          }
        }
      `;
      const result = await this.client.mutate(UPDATE_PLAYLIST_ITEM, {
        id,
        playlistItem,
      });
      return result.data;
    } catch (error) {
      throw new Error('Cannot get customer');
    }
  };

  addMaterial = async (
    materialId: string,
    campaignId: string
  ): Promise<PlaylistItem> => {
    try {
      const UPDATE_ITEMS = gql`
        mutation updateItemsWithCampaignId(
          $campaignId: String!
          $materialId: String!
        ) {
          updateItemsWithCampaignId(
            campaignId: $campaignId
            materialId: $materialId
          ) {
            id
            playlist {
              id
            }
            previousItemId
            nextItemId
            campaignId
            materialId
          }
        }
      `;
      const result = await this.client.mutate(UPDATE_ITEMS, {
        campaignId,
        materialId,
      });

      return result.data.updateItemsWithCampaignId;
    } catch (error) {
      throw new Error(`Cant't add material`);
    }
  };

  findItemsWithPlaylistId = async (
    playlist: string
  ): Promise<PlaylistItem[]> => {
    try {
      const FIND_ITEMS = gql`
        query findWithPlaylistId($playlist: String!) {
          findWithPlaylistId(playlist: $playlist) {
            id
            playlist {
              id
            }
            previousItemId
            nextItemId
            campaignId
            materialId
            quantity
          }
        }
      `;
      const result = await this.client.query(FIND_ITEMS, {
        playlist,
      });
      return result.data.findWithPlaylistId;
    } catch (error) {
      throw new Error('Cannot get customer');
    }
  };
  generateVideo = async (playlistId: string): Promise<Playlist> => {
    try {
      const GENERATE_VIDEO = gql`
        mutation generateVideo($playlistId: String!) {
          generateVideo(id: $playlistId) {
            id
            date
            name
            productVariationId
            productId
            materialId
          }
        }
      `;
      const result = await this.client.mutate(GENERATE_VIDEO, {
        playlistId,
      });
      return result.data.generateVideo;
    } catch (error) {
      console.log(error);

      throw new Error('Cannot generate video');
    }
  };
}
export default new PlaylistClient();
