import { Grid, makeStyles } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import OrderCustomerTextField from '../components/order-customer-container/order-customer-textfield';
import { useAddOrderDispatch } from '../context/add-order-context';
import { CampaignInterface, CustomerCampaign, CustomerInterface } from '../interfaces';
import OrderCustomerSearchContainer from './order/customer-search-container';

const useStyles = makeStyles({
  grid: {
    'grid-auto-flow': 'row',
    justify: 'flex-start',
    alignItems: 'flex-start',
  },
  gridContainer: {
    width: '100%',
  },
});

interface Props {
  submitting: boolean;
  handleSubmit: Function;
  setCustomerErrors: Function;
  campaignData?: CampaignInterface;
}

const CustomerForm: React.FC<Props> = ({
  submitting,
  handleSubmit,
  setCustomerErrors,
  campaignData,
}: Props) => {
  const [customer, setCustomer] = useState<CustomerInterface | null | undefined>(null);
  const [busineddIDValue, setBusinessIDValue] = useState<string | null>(null);
  const [campaignDetails, setCampaignDetails] = useState<CustomerCampaign>();
  const [customerName, setCustomerName] = useState<string>('');
  const addOrderDispatch = useAddOrderDispatch();

  const classes = useStyles();

  const validationSchema = Yup.object({
    campaignName: Yup.string().required('Pakollinen kenttä'),
    customerName: Yup.string().required('Pakollinen kenttä'),
    materialSupplierEmail: Yup.string()
      .required('Pakollinen kenttä')
      .email('Virheellinen sähköpostiosoite'),
    materialSupplierPhone: Yup.string(),
    materialApproverEmail: Yup.string().email('Virheellinen sähköpostiosoite'),
    materialApproverPhone: Yup.string(),
    contactPerson: Yup.string().required('Pakollinen kenttä'),
    customerId: Yup.string().required('Pakollinen kenttä').min(1),
    customerEmail: Yup.string()
      .required('Pakollinen kenttä')
      .test('email', 'Tarkista emailit', (value: string): boolean => {
        if (!value) return false;
        const emails = value.split(',');
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const result = emails.every((email) => {
          return re.test(String(email).toLowerCase());
        });
        return result;
      }),
  });

  const onSubmit = (): void => {
    handleSubmit(campaignDetails);
  };

  const formik = useFormik({
    initialValues: campaignData
      ? {
          ...campaignData.customer,
          contactPerson: campaignData.contactPerson,
          campaignName: campaignData.campaignName,
          materialSupplierEmail: campaignData.materialSupplierEmail,
          materialApproverEmail: campaignData.materialApproverEmail,
          materialSupplierPhone: campaignData.materialSupplierPhone,
          materialApproverPhone: campaignData.materialApproverPhone,
          customerEmail: campaignData.customerEmail,
          customerId: `${campaignData.customer ? campaignData.customer.companyId : ''}`,
        }
      : {
          customerName: '',
          businessid: '',
          address: '',
          salesman: '',
          contactPerson: '',
          contactEmail: '',
          contactPhone: '',
          invoiceDiscount: '',
          companyId: '',
          campaignName: '',
          materialSupplierEmail: '',
          materialApproverEmail: '',
          materialSupplierPhone: '',
          materialApproverPhone: '',
          customerEmail: '',
          customerId: '',
        },
    validationSchema,
    onSubmit: onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (submitting) {
      formik.submitForm();
    }
  }, [submitting]);

  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      setCustomerErrors(true);
      return;
    }
    setCustomerErrors(false);
  }, [formik.errors]);

  useEffect(() => {
    if (customer && campaignData?.customer.id !== customer.id) {
      addOrderDispatch({
        type: 'update',
        payload: {
          customerDiscount: +customer.invoiceDiscount,
          customerBusinessId: customer.businessid,
        },
      });
    }
    const {
      campaignName: name,
      materialApproverEmail,
      materialSupplierEmail,
      materialApproverPhone,
      materialSupplierPhone,
      customerEmail,
      contactPerson,
      customerId,
    } = formik.values;
    const newCustomerName = formik.values.customerName;
    setCampaignDetails({
      name,
      contactPerson,
      customerName: newCustomerName,
      materialApproverEmail,
      materialSupplierEmail,
      materialApproverPhone,
      materialSupplierPhone,
      customerEmail,
      customerId,
    });
  }, [formik.values, customer]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container className={(classes.grid, classes.gridContainer)} spacing={6}>
        <OrderCustomerTextField
          formik={formik}
          placeholder="Syötä kampanjan nimi"
          label="Kampanjan nimi"
          name="campaignName"
          validationSchema={validationSchema}
        />
        <OrderCustomerSearchContainer
          validationSchema={validationSchema}
          formik={formik}
          customer={customer}
          setCustomerName={setCustomerName}
          setCustomerBusinessId={setBusinessIDValue}
          customerName={customerName}
          setCustomer={setCustomer}
          businessId={busineddIDValue}
          campaignData={campaignData}
        />
        <OrderCustomerTextField
          formik={formik}
          placeholder="Syötä tilaajan nimi"
          label="Tilaajan nimi"
          name="contactPerson"
          validationSchema={validationSchema}
        />
        <OrderCustomerTextField
          formik={formik}
          placeholder="Syötä tilaajan sähköpostiosoite"
          label="Tilaajan sähköposti"
          name="customerEmail"
          validationSchema={validationSchema}
        />
        <OrderCustomerTextField
          formik={formik}
          placeholder="Syötä aineiston hyväksyjän sähköpostiosoite"
          label="Aineiston hyväksyjä"
          name="materialApproverEmail"
          validationSchema={validationSchema}
          required={false}
        />
        <OrderCustomerTextField
          formik={formik}
          placeholder="Syötä aineiston hyväksyjän puhelinnumero"
          label="Aineiston hyväksyjän puhelinnumero"
          name="materialApproverPhone"
          validationSchema={validationSchema}
          required={false}
        />
        <OrderCustomerTextField
          formik={formik}
          placeholder="Syötä aineiston toimittajan sähköpostiosoite"
          label="Aineiston toimittaja"
          name="materialSupplierEmail"
          validationSchema={validationSchema}
        />
        <OrderCustomerTextField
          formik={formik}
          placeholder="Syötä aineiston toimittajan puhelinnumero"
          label="Aineiston toimittajan puhelinnumero"
          name="materialSupplierPhone"
          validationSchema={validationSchema}
          required={false}
        />
      </Grid>
    </form>
  );
};

export default CustomerForm;
