import {
  ButtonBase,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import UserClient from '../lib/user-client';

const useStyles = makeStyles(() => ({
  inputs: {
    width: '328px',
    height: '80px',
    marginBottom: '40px',
  },
  input: {
    color: '#5E8EFC',
    fontSize: '16px',
    letterSpacing: '0.15px',
  },
  error: {
    color: 'red',
    marginBottom: '5px',
  },
  infomsg: {
    color: '#5E8EFC',
    fontSize: '16px',
    letterSpacing: '0.15px',
  },
  buttonBase: {
    height: '48px',
    width: '184px',
    backgroundColor: '#5E8EFC',

    borderRadius: '4px',
  },
  submitText: {
    color: 'white',
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: '1.25px',
  },
  gridCenter: {
    'align-items': 'center',
    'flex-direction': 'column',
  },
}));

const ResetPasswordForm: React.FC = () => {
  const [sentStatus, setSentStatus] = useState(false);
  const classes = useStyles();

  const validationSchema = Yup.object({
    username: Yup.string()
      .required('Pakollinen kenttä')
      .email('Virheellinen sähköpostiosoite'),
  });

  const onSubmit = async ({
    username,
  }: {
    username: string;
  }): Promise<void> => {
    try {
      await UserClient.addToken(username);
      setSentStatus(true);
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      username: '',
    },
    validationSchema,
    onSubmit: onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
  });

  if (sentStatus) {
    return (
      <Grid container className={classes.gridCenter}>
        <Grid item container className={classes.gridCenter}>
          <Typography className={classes.infomsg}>
            Salasanan vaihtoon tarvittava linkki lähetetty sähköpostiisi
          </Typography>
        </Grid>
      </Grid>
    );
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container className={classes.gridCenter}>
        <Grid item container className={classes.gridCenter}>
          <Grid className={classes.error}>
            {formik.errors.username ? formik.errors.username : null}
          </Grid>

          <TextField
            helperText="Sähköposti"
            variant="outlined"
            type="text"
            id="username"
            name="username"
            onChange={formik.handleChange}
            value={formik.values.username}
            className={classes.inputs}
            InputProps={{
              className: classes.input,
            }}
          ></TextField>
        </Grid>
        <Grid item className={classes.gridCenter} container>
          <ButtonBase className={classes.buttonBase} type="submit">
            <Typography className={classes.submitText}>
              Palauta salasana
            </Typography>
          </ButtonBase>
        </Grid>
      </Grid>
    </form>
  );
};

export default ResetPasswordForm;
