export const months = [
  {
    label: 'Tammi',
    value: 1,
    year: '',
  },
  {
    label: 'Helmi',
    value: 2,
    year: '',
  },
  {
    label: 'Maalis',
    value: 3,
    year: '',
  },
  {
    label: 'Huhti',
    value: 4,
    year: '',
  },
  {
    label: 'Touko',
    value: 5,
    year: '',
  },
  {
    label: 'Kesä',
    value: 6,
    year: '',
  },
  {
    label: 'Heinä',
    value: 7,
    year: '',
  },
  {
    label: 'Elo',
    value: 8,
    year: '',
  },
  {
    label: 'Syys',
    value: 9,
    year: '',
  },
  {
    label: 'Loka',
    value: 10,
    year: '',
  },
  {
    label: 'Marras',
    value: 11,
    year: '',
  },
  {
    label: 'Joulu',
    value: 12,
    year: '',
  },
];
