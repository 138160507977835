import {
  Button,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { NewUserInterface, OrganizationInterface } from '../../interfaces';

const useStyles = makeStyles(() => ({
  grid: {
    width: '100%',
    margin: '25px 25px 25px 25px',
  },
  gridContainer: {
    'grid-auto-flow': 'row',
    alignItems: 'flex-start',
    margin: '0',
    width: '100%',
  },
  title: {
    fontSize: '30px',
    letterSpacing: '0.24px',
    lineHeight: '40px',
    fontWeight: 400,
    color: '#000000DE',
    'margin-bottom': '25px',
  },
  label: {
    margin: '5px 5px 5px 5px',
    fontWeight: 'bold',
    float: 'left',
    width: '150px',
  },
  inputField: { margin: '0px 0px 0px 5px', float: 'left', width: '400px' },
  buttonBase: {
    backgroundColor: '#5E8EFC',

    borderRadius: '4px',
    'margin-bottom': '25px',
  },
  submitText: {
    color: 'white',
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: '1.25px',
  },
  formGrids: {
    margin: '25px 0px 0px 0px',
  },
  error: {
    color: 'red',
    marginLeft: '15px',
    marginBottom: '5px',
  },
}));

interface Props {
  organizations: OrganizationInterface[];
  addUser: (user: NewUserInterface) => Promise<void>;
}

const UserForm: React.FC<Props> = ({ organizations, addUser }: Props) => {
  const [noErrors, setNoErrors] = useState(true);
  const [organizationError, setOrganizationError] = useState(false);
  const [userError, setUserError] = useState(false);
  const classes = useStyles();

  const userSchema = Yup.object({
    organization: Yup.string().required('Pakollinen kenttä'),
    email: Yup.string()
      .email('Anna validi sähköposti')
      .required('Pakollinen kenttä'),
    password: Yup.string().required('Pakollinen kenttä'),
    rePassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Salasanat eivät vastaa toisiaan')
      .required('Pakollinen kenttä'),
    phone: Yup.string(),
    name: Yup.string(),
  });

  const onSubmitUser = async (
    {
      organization,
      email,
      password,
      name,
      phone,
    }: {
      organization: string;
      email: string;
      password: string;
      rePassword: string;
      name: string;
      phone: string;
    },
    formikBag: { resetForm: (arg0: {}) => void }
  ): Promise<void> => {
    try {
      if (userError) setUserError(false);
      if (organizationError) setOrganizationError(false);
      const user: NewUserInterface = {
        organization: organization,
        email: email,
        password: password,
        name,
        phone,
      };

      await addUser(user);
      formikBag.resetForm({});
      if (!noErrors) setNoErrors(true);
    } catch (error) {
      setNoErrors(false);
      console.log(error);
    }
  };

  const userFormik = useFormik({
    initialValues: {
      organization: '',
      email: '',
      password: '',
      rePassword: '',
      phone: '',
      name: '',
    },
    validationSchema: userSchema,
    onSubmit: onSubmitUser,
    validateOnChange: false,
    validateOnBlur: false,
  });
  return (
    <>
      <Typography className={classes.title}>Lisää uusi käyttäjä </Typography>
      <form onSubmit={userFormik.handleSubmit} className={classes.grid}>
        <Grid item container spacing={2} className={classes.gridContainer}>
          <Grid item className={classes.formGrids}>
            <Typography className={classes.label}>Organisaatio*</Typography>
          </Grid>
          <Grid item className={classes.formGrids}>
            <Select
              required
              id="userOrganization"
              name="organization"
              label="Organisaation nimi"
              placeholder="Syötä organisaation nimi"
              onChange={userFormik.handleChange}
              value={userFormik.values.organization}
              className={classes.inputField}
            >
              {organizations.map((organization: OrganizationInterface) => {
                return (
                  <MenuItem key={organization.id} value={organization.id}>
                    {organization.name}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
        <Grid className={classes.error}>
          {userFormik.errors.organization
            ? userFormik.errors.organization
            : null}
        </Grid>
        <Grid item container spacing={2}>
          <Grid item className={classes.formGrids}>
            <Typography className={classes.label}></Typography>
          </Grid>
          <Grid item className={classes.formGrids}></Grid>
        </Grid>
        <Grid item container spacing={2} className={classes.gridContainer}>
          <Grid item className={classes.formGrids}>
            <Typography className={classes.label}>Sähköpostiosoite*</Typography>
          </Grid>
          <Grid item className={classes.formGrids}>
            <TextField
              required
              id="userEmail"
              name="email"
              label="Sähköpostiosoite"
              placeholder="Syötä sähköpostiosoite"
              onChange={userFormik.handleChange}
              value={userFormik.values.email}
              className={classes.inputField}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} className={classes.gridContainer}>
          <Grid item className={classes.formGrids}>
            <Typography className={classes.label}>Nimi</Typography>
          </Grid>
          <Grid item className={classes.formGrids}>
            <TextField
              id="userName"
              name="name"
              label="Nimi"
              placeholder="Syötä nimi"
              onChange={userFormik.handleChange}
              value={userFormik.values.name}
              className={classes.inputField}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2} className={classes.gridContainer}>
          <Grid item className={classes.formGrids}>
            <Typography className={classes.label}>Puhelinnumero</Typography>
          </Grid>
          <Grid item className={classes.formGrids}>
            <TextField
              id="userPhone"
              name="phone"
              label="Puhelinnumero"
              placeholder="Syötä numero"
              onChange={userFormik.handleChange}
              value={userFormik.values.phone}
              className={classes.inputField}
            />
          </Grid>
        </Grid>
        <Grid className={classes.error}>
          {userFormik.errors.email ? userFormik.errors.email : null}
        </Grid>
        <Grid item container spacing={2} className={classes.gridContainer}>
          <Grid item className={classes.formGrids}>
            <Typography className={classes.label}>Salasana*</Typography>
          </Grid>
          <Grid item className={classes.formGrids}>
            <TextField
              required
              id="userPassword"
              name="password"
              label="Salasana"
              placeholder="Syötä salasana"
              type="password"
              onChange={userFormik.handleChange}
              value={userFormik.values.password}
              className={classes.inputField}
            />
          </Grid>
        </Grid>
        <Grid className={classes.error}>
          {userFormik.errors.password ? userFormik.errors.password : null}
        </Grid>
        <Grid item container spacing={2} className={classes.gridContainer}>
          <Grid item className={classes.formGrids}>
            <Typography className={classes.label}>
              Salasana uudelleen*
            </Typography>
          </Grid>
          <Grid item className={classes.formGrids}>
            <TextField
              required
              id="userRePassword"
              name="rePassword"
              label="Salasana uudelleen"
              type="password"
              placeholder="Syötä salasana uudelleen"
              onChange={userFormik.handleChange}
              value={userFormik.values.rePassword}
              className={classes.inputField}
            />
          </Grid>
        </Grid>
        <Grid className={classes.error}>
          {userFormik.errors.rePassword ? userFormik.errors.rePassword : null}
        </Grid>
        {userError ? (
          <Typography className={classes.error}>
            Salasanat eivät vastaa toisiaan.
          </Typography>
        ) : (
          <></>
        )}
        <Grid className={classes.error}>
          {noErrors ? noErrors : 'Lisäyksessä tapahtui virhe'}
        </Grid>
        <Grid item className={classes.formGrids}>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonBase}
            type="submit"
          >
            <Typography className={classes.submitText}>
              Lisää käyttäjä
            </Typography>
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default UserForm;
