import ApproveOffer from './approve-offer';
import FileUpload from './file-upload';
import FloatingOrders from './floating-orders';
import Login from './login';
import Logout from './logout';
import OfferList from './offer-list';
import Order from './order';
import OrderList from './order-list';
import OrderTemplate from './order-template';
import OrderUpdate from './order-update';
import Playlist from './playlist';
import Report from './report';
import Reservations from './reservations';
import ResetPassword from './reset-password';
import SetPassword from './set-password';
import UserCreate from './user-create';
export default {
  Login,
  Order,
  OrderList,
  UserCreate,
  Logout,
  ResetPassword,
  SetPassword,
  Playlist,
  FileUpload,
  OfferList,
  OrderTemplate,
  ApproveOffer,
  Reservations,
  Report,
  OrderUpdate,
  FloatingOrders,
};
