// import Nav from './nav';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Calendar } from 'calendar-base';
import { FormikErrors } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useProviderState } from '../../context/provider-context';
import { ReservationWeekSummary, Week } from '../../interfaces';
import reservationClient from '../../lib/reservation-client';
import ArrowButton from '../arrow-button';
import SummaryHoverInfo from '../order-details/summary-hover-info';
import WeekComponent from './week';

interface WeekPickerProps {
  variation: string;
  setOrderWeeks: Function;
  fieldError: string | string[] | FormikErrors<Week>[] | undefined;
  campaignWeeks: Week[];
  notifyUser: boolean;
  campaignId?: string;
  quantity: number;
}

const useStyles = makeStyles(() => ({
  container: { marginLeft: '-47.5px' },
  PickerContainer: {
    minWidth: '800px',
    minHeight: '250px',
    justifyContent: 'center',
    backgroundColor: '#FBFBFC',
    margin: '12.5px',
    padding: '20px',
    border: '1px solid #B1B8C026',
  },
  yearContainer: {
    width: '100%',
  },
  text: {
    textAlign: 'center',
  },
  year: { fontWeight: 'bold', fontSize: '16px' },
}));

const WeekPicker: React.FC<WeekPickerProps> = ({
  variation,
  setOrderWeeks,
  fieldError,
  campaignWeeks,
  notifyUser,
  campaignId,
  quantity,
}: WeekPickerProps) => {
  const [year, setYear] = useState<number>();

  const [mouseWeek, setMouseWeek] = useState<number>();
  const [mouseYear, setMouseYear] = useState<number>();

  const [reservSummary, setReservSummary] =
    useState<ReservationWeekSummary[]>();

  const [fullWeeks, setFullWeeks] = useState<ReservationWeekSummary[]>([]);
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const providerState = useProviderState();

  useEffect(() => {
    //joku validaatio tahan jos on uus eli template poistas valinnan
    //console.log(campaignWeeks);
    if (!year) {
      setYear(currentYear);
    }
  }, []);

  useEffect(() => {
    const getSummary = async (): Promise<void> => {
      if (year && variation) {
        const result = await reservationClient.getReservationWeekSummary(
          variation,
          1,
          year,
          Calendar.calculateWeekNumber({
            year: year || 0,
            month: 11,
            day: 31,
          }),
          year + 1,
          campaignId
        );
        if (result) setReservSummary(result);
      }
    };
    getSummary();
  }, [variation, year]);

  const handleLeftClick = (): void => {
    if (year) {
      if (year > new Date().getFullYear()) {
        setYear(year - 1);
      }
    }
  };

  const handleRightClick = (): void => {
    if (year) {
      setYear(year + 1);
    }
  };

  const disableButton = (): boolean => {
    if (year === new Date().getFullYear()) return true;

    return false;
  };

  useEffect(() => {
    const result = reservSummary?.filter((summary) => summary.available === 0);
    if (result) setFullWeeks(result);
  }, [reservSummary]);

  useEffect(() => {
    if (notifyUser && fullWeeks.length) {
      //pass
      const result = campaignWeeks.filter((week) =>
        fullWeeks.find((f) => f.week === week.week && f.year === week.year)
      );
      if (result.length)
        alert(
          'Seuraavat ajanjaksot olivat jo täyttyneet ja valinnat poistetaan: ' +
            result.map((item) => `${item.week}/${item.year}`).join(', ')
        );
      setOrderWeeks(
        campaignWeeks.filter(
          (m) =>
            !result.find((r: Week) => r.week === m.week && r.year === m.year)
        )
      );
    }
  }, [fullWeeks]);

  const sortWeekArray = (
    array: ReservationWeekSummary[] | Week[]
  ): ReservationWeekSummary[] | Week[] => {
    return array.sort((a, b) => {
      if (a.year < b.year) {
        return -1;
      }
      if (a.year > b.year) {
        return 1;
      }
      if (a.week < b.week) {
        return -1;
      }
      if (a.week > b.week) {
        return 1;
      }
      return 0;
    });
  };

  // pitaa korjata
  const clickWeek = (e: any, year: number, week: number) => {
    let weekArray = [...campaignWeeks];

    const alreadySelected = weekArray.findIndex((selectedWeek) => {
      return selectedWeek.year === year && selectedWeek.week === week;
    });

    if (alreadySelected >= 0) weekArray.splice(alreadySelected, 1);
    else weekArray.push({ year, week });

    weekArray = sortWeekArray(weekArray);

    setOrderWeeks(weekArray);
  };

  const setStyle = (year: number, week: number): any => {
    const weekArray = [...campaignWeeks];
    const reservation = reservSummary?.find(
      (reservMonth) => reservMonth.year === year && reservMonth.week === week
    );

    const selected = weekArray.find(
      (selectedWeek) => selectedWeek.year === year && selectedWeek.week === week
    );

    const style = { color: 'white', disabled: false, fontColor: 'initial' };
    const blue = '94, 142, 252';
    const green = '198, 243, 213';
    const yellow = '247, 241, 25';
    const red = '255, 111, 117';

    if (reservation) {
      if (quantity <= reservation.available && reservation.available > 20) {
        style.color = green;

        if (selected) {
          style.fontColor = '#FFFFFF';
          style.color = blue;
        }
      } else if (
        quantity <= reservation.available &&
        reservation.available > 0
      ) {
        style.color = yellow;

        if (selected) {
          style.fontColor = '#FFFFFF';
          style.color = blue;
        }
      } else if (
        reservation.available < quantity ||
        reservation.available / reservation.limit === 0
      ) {
        style.disabled = true;
        style.color = red;
      }

      if (
        year <= moment().year() &&
        week <
          Calendar.calculateWeekNumber({
            year: moment().year(),
            month: moment().month(),
            day: moment().date(),
          }) +
            1
      ) {
        style.disabled = true;
        style.color = 'white';
      }
    }
    return style;
  };

  const weeknumbers = [...Array(52).keys()].map((value) => value + 1);

  const weeks: number[] = [
    ...new Set([
      ...weeknumbers,
      Calendar.calculateWeekNumber({
        year: year || 0,
        month: 11,
        day: 31,
      }),
    ]),
  ].map((value) => value);

  return (
    <>
      <Grid
        container
        alignItems="center"
        className={classes.container}
        direction="row"
        wrap="nowrap"
      >
        <Grid item>
          <ArrowButton
            disableButton={disableButton()}
            direction="left"
            handleClick={handleLeftClick}
          ></ArrowButton>
        </Grid>
        <Grid
          direction="row"
          wrap="nowrap"
          container
          className={classes.PickerContainer}
        >
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="space-between"
            className={classes.yearContainer}
          >
            <Grid item>
              <Typography className={classes.year}>{year}</Typography>
            </Grid>
            <Grid direction="row" container>
              {weeks.map((week, i) => {
                return (
                  <div
                    onMouseEnter={(): void => {
                      setMouseYear(year);
                      setMouseWeek(week);
                    }}
                    onMouseLeave={(): void => {
                      setMouseYear(0);
                      setMouseWeek(0);
                    }}
                    key={year + '.' + week}
                  >
                    <WeekComponent
                      onClick={clickWeek}
                      style={setStyle(year || 0, week)}
                      label={`${week}`}
                      id={year + '.' + week}
                      year={year || 0}
                      week={+week}
                    ></WeekComponent>
                  </div>
                );
              })}
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <ArrowButton
            direction="right"
            handleClick={handleRightClick}
          ></ArrowButton>
        </Grid>
      </Grid>
      <Grid>
        <Typography>{fieldError ? fieldError : ''}</Typography>
      </Grid>
      <Grid container>
        {!!mouseYear && !!mouseWeek && providerState.product && (
          <SummaryHoverInfo
            product={providerState.product}
            week={mouseWeek}
            year={mouseYear}
          />
        )}
      </Grid>
    </>
  );
};

export default WeekPicker;
