import Cookies from 'js-cookie';

export const setSession = (): void => {
  Cookies.set('sales-mediamyynti-com', 'Authorized', {
    expires: 1 / 24,
    sameSite: 'Strict',
    // eslint-disable-next-line no-undef
    secure: Boolean(process.env.REACT_APP_SECURE_COOKIE),
  });
};

export const clearSession = (): void => {
  Cookies.remove('sales-mediamyynti-com');
};

export const checkSession = (): boolean => {
  return !!Cookies.get('sales-mediamyynti-com');
};
