import { CampaignDetailInterface } from '../interfaces';

export const getDetailValue = (
  camppaignDetails: CampaignDetailInterface[],
  detailLabel: string
): string => {
  const detail = camppaignDetails.find(
    (detail) => detail.label?.toLowerCase() === detailLabel.toLowerCase()
  );
  return detail ? detail.value + '' : '-';
};
