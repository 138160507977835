import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import {
  CampaignDetailInterface,
  ProductSettingInterface,
} from '../../interfaces';

const useStyles = makeStyles(() => ({
  rightItem: {
    width: '400px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
}));

interface SummaryProps {
  summaryTypeFormat?: {
    settings: ProductSettingInterface[];
    settingValues: CampaignDetailInterface[];
    type: string;
  };
}

const OrderSummaryFormDetailsSettings: React.FC<SummaryProps> = ({
  summaryTypeFormat,
}: SummaryProps) => {
  const classes = useStyles();

  if (!summaryTypeFormat || !summaryTypeFormat.settings) {
    return <></>;
  }
  return (
    <>
      {summaryTypeFormat.settings.map((setting) => {
        const value = summaryTypeFormat.settingValues.find(
          (value) => value.key === setting.id
        );
        let valueString = value?.value;
        if (value && value?.value instanceof Object) {
          let combine = '';
          const length = Object.keys(value.value).length - 1;
          Object.entries(value.value).forEach(([key, v], i) => {
            if (v) combine = combine + key + (i === length ? '' : ', ');
          });

          valueString = combine;
        }

        return (
          <Grid container justifyContent="space-between" key={setting.id}>
            <Grid item>
              <Typography className={classes.title}>{setting.label}</Typography>
            </Grid>
            <Grid item className={classes.rightItem}>
              <Typography
                style={{ textTransform: 'capitalize', fontSize: '16px' }}
              >
                {valueString ? valueString : '-'}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
};

export default OrderSummaryFormDetailsSettings;
