import moment from 'moment';
import { DateUtils, Modifiers } from 'react-day-picker';
import { AvailabilityInterface } from '../interfaces';

export const getLimitDay = (): Date => moment().add(3, 'day').toDate();

const freeSpaceModifier =
  (mappedReservations: AvailabilityInterface, thisMonth: Date, limitDay = getLimitDay) =>
  (day: Date): boolean => {
    if (
      mappedReservations.freeSpace.length === 0 &&
      mappedReservations.full.length === 0 &&
      mappedReservations.mediumSpace.length === 0
    )
      return false;
    const reservationDay = mappedReservations.freeSpace.some((reservationDay: Date) =>
      DateUtils.isSameDay(day, reservationDay)
    );
    const isAfter = DateUtils.isDayAfter(day, limitDay());
    const isSameMonth = DateUtils.isSameMonth(day, thisMonth);
    const isSameMonth2 = DateUtils.isSameMonth(day, moment(thisMonth).add(1, 'month').toDate());
    if (isAfter) {
      const result = reservationDay || isSameMonth || isSameMonth2;
      return result;
    }
    return false;
  };

export const modifiers = (
  mappedReservations?: AvailabilityInterface | undefined,
  startDate?: moment.Moment,
  offset = 3
): Partial<Modifiers> => {
  const limitDay = (): Date => moment().add(offset, 'day').toDate();
  const thisMonth = moment(startDate).toDate();
  let modifiers = {};

  if (mappedReservations) {
    modifiers = {
      full: (day: Date): boolean =>
        mappedReservations.full.some((reservDay: Date) => DateUtils.isSameDay(day, reservDay)) &&
        DateUtils.isDayAfter(day, limitDay()),
      freeSpace: freeSpaceModifier(mappedReservations, thisMonth, limitDay),
      mediumSpace: (day: Date): boolean =>
        mappedReservations.mediumSpace.some((reservDay: Date) =>
          DateUtils.isSameDay(day, reservDay)
        ) && DateUtils.isDayAfter(day, limitDay()),
    };
  }

  return modifiers;
};
