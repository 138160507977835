import {
  ReservationDaysMonths,
  ReservationInput,
  ReservationInterface,
  ReservationMonthInput,
  ReservationMonthInterface,
  ReservationMonthSummary,
  ReservationSummary,
  ReservationWeekInput,
  ReservationWeekInterface,
  ReservationWeekSummary,
} from '../interfaces';

import Client from './apollo-client';
import gql from 'graphql-tag';

class ReservationClient {
  private client: Client;
  constructor() {
    // eslint-disable-next-line no-undef
    const url = process.env.REACT_APP_CAMPAIGN_SERVICE_URL || '';
    this.client = new Client(url);
  }

  listReservation = async (): Promise<Partial<ReservationInterface[]>> => {
    try {
      const LIST_RESERVATION = gql`
        query listReservation {
          listReservation {
            id
            created
            date
            productId
            productVariationId
            campaignId
            quantity
          }
        }
      `;
      const result = await this.client.query(LIST_RESERVATION);
      return result.data.listReservation as Partial<ReservationInterface[]>;
    } catch (error) {
      throw new Error('Cannot get resources');
    }
  };

  getReservationSummary = async (
    variationId: string,
    startDate: Date,
    endDate: Date,
    campaign = ''
  ): Promise<ReservationSummary[]> => {
    try {
      const GET_RESERVATION_SUMMARY = gql`
        query getReservationDaySummary(
          $variationId: String!
          $startDate: DateTime!
          $endDate: DateTime!
          $campaign: String!
        ) {
          getReservationDaySummary(
            productVariationId: $variationId
            startDate: $startDate
            endDate: $endDate
            campaign: $campaign
          ) {
            date
            available
            limit
          }
        }
      `;
      const result = await this.client.query(GET_RESERVATION_SUMMARY, {
        variationId,
        startDate,
        endDate,
        campaign,
      });

      return result.data.getReservationDaySummary as ReservationSummary[];
    } catch (error) {
      console.log(error);

      throw new Error('Cannot get reservation day summary');
    }
  };

  getReservationWeekSummary = async (
    variationId: string,
    startWeek: number,
    startYear: number,
    endWeek: number,
    endYear: number,
    campaign = ''
  ): Promise<ReservationWeekSummary[]> => {
    try {
      const GET_RESERVATION_WEEK_SUMMARY = gql`
        query getReservationWeekSummary(
          $variationId: String!
          $startWeek: Float!
          $startYear: Float!
          $endWeek: Float!
          $endYear: Float!
          $campaign: String!
        ) {
          getReservationWeekSummary(
            productVariationId: $variationId
            startYear: $startYear
            startWeek: $startWeek
            endYear: $endYear
            endWeek: $endWeek
            campaign: $campaign
          ) {
            available
            limit
            year
            week
          }
        }
      `;
      const result = await this.client.query(GET_RESERVATION_WEEK_SUMMARY, {
        variationId,
        startWeek,
        startYear,
        endWeek,
        endYear,
        campaign,
      });

      return result.data.getReservationWeekSummary;
    } catch (error) {
      console.log(error);

      throw new Error('Cannot get week summary');
    }
  };

  getReservationMonthSummary = async (
    variationId: string,
    startMonth: number,
    startYear: number,
    endMonth: number,
    endYear: number,
    campaign = ''
  ): Promise<ReservationMonthSummary[]> => {
    try {
      const GET_RESERVATION_MONTH_SUMMARY = gql`
        query getReservationMonthSummary(
          $variationId: String!
          $startMonth: Float!
          $startYear: Float!
          $endMonth: Float!
          $endYear: Float!
          $campaign: String!
        ) {
          getReservationMonthSummary(
            productVariationId: $variationId
            year: $startYear
            month: $startMonth
            endYear: $endYear
            endMonth: $endMonth
            campaign: $campaign
          ) {
            available
            limit
            year
            month
          }
        }
      `;
      const result = await this.client.query(GET_RESERVATION_MONTH_SUMMARY, {
        variationId,
        startMonth,
        startYear,
        endMonth,
        endYear,
        campaign,
      });

      return result.data.getReservationMonthSummary;
    } catch (error) {
      console.log(error);

      throw new Error('Cannot get month summary');
    }
  };

  addReservation = async (reservation: ReservationInput): Promise<ReservationInterface> => {
    try {
      const ADD_RESERVATION = gql`
        mutation addReservation(
          $date: DateTime!
          $product: String!
          $variation: String!
          $campaign: String!
          $quantity: Float!
          $rough: Boolean
          $batch: Float
        ) {
          addReservation(
            reservation: {
              date: $date
              productId: $product
              productVariationId: $variation
              campaignId: $campaign
              quantity: $quantity
              rough: $rough
              batch: $batch
            }
          ) {
            id
          }
        }
      `;
      const { data } = await this.client.mutate(ADD_RESERVATION, {
        ...reservation,
      });
      if (!data.addReservation) throw new Error('Reservation creation failed');
      return data.addReservation;
    } catch (error) {
      console.log(error);
      throw new Error(error.message);
    }
  };

  addReservationMonth = async (
    reservation: ReservationMonthInput
  ): Promise<ReservationMonthInterface> => {
    try {
      const ADD_RESERVATION_MONTH = gql`
        mutation addReservationMonth(
          $month: Float!
          $year: Float!
          $product: String!
          $variation: String!
          $campaign: String!
          $quantity: Float!
          $rough: Boolean
          $batch: Float
        ) {
          addReservationMonth(
            reservation: {
              month: $month
              year: $year
              productId: $product
              productVariationId: $variation
              campaignId: $campaign
              quantity: $quantity
              rough: $rough
              batch: $batch
            }
          ) {
            id
          }
        }
      `;
      const { data } = await this.client.mutate(ADD_RESERVATION_MONTH, {
        ...reservation,
      });
      if (!data.addReservationMonth) throw new Error('Reservation creation failed');

      return data.addReservationMonth;
    } catch (error) {
      console.log(error);
      throw new Error(error.message);
    }
  };

  removeReservationsByCampaingId = async (id: string): Promise<Partial<ReservationInterface[]>> => {
    try {
      const REMOVE_RESERVATIONS_BY_CAMPAIGN_ID = gql`
        mutation removeReservationsByCampaignId($id: String!) {
          removeReservationsByCampaignId(campaignId: $id) {
            message
            errors
          }
        }
      `;
      const result = await this.client.mutate(REMOVE_RESERVATIONS_BY_CAMPAIGN_ID, { id });
      return result.data.removeReservationsByCampaignId as Partial<ReservationInterface[]>;
    } catch (error) {
      throw new Error('Cannot get remove reservations');
    }
  };

  updateRoughReservations = async (id: string): Promise<Partial<ReservationInterface[]>> => {
    try {
      const CONFIRM_ROUGH = gql`
        mutation update($id: String!) {
          confirmRoughReservations(campaign: $id) {
            id
            rough
          }
        }
      `;
      const { data, errors } = await this.client.mutate(CONFIRM_ROUGH, {
        id,
      });
      if (errors || !data.confirmRoughReservations) throw Error('Confirmation failed');
      return data.confirmRoughReservations;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  canConfirmRoughReservations = async (id: string): Promise<boolean> => {
    try {
      const CONFIRM_ROUGH = gql`
        mutation update($id: String!) {
          canConfirmRoughReservations(campaign: $id) {
            canConfirm
          }
        }
      `;
      const { data, errors } = await this.client.mutate(CONFIRM_ROUGH, {
        id,
      });

      if (errors) throw Error('Confirmation failed');
      return data.canConfirmRoughReservations.canConfirm;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  revertupdateRoughReservations = async (id: string): Promise<Partial<ReservationInterface[]>> => {
    try {
      const REVERT_CONFIRM_ROUGH = gql`
        mutation update($id: String!) {
          revertConfirmRoughReservation(campaign: $id) {
            id
            rough
          }
        }
      `;
      const { data, errors } = await this.client.mutate(REVERT_CONFIRM_ROUGH, {
        id,
      });
      if (errors || !data.revertConfirmRoughReservation) throw Error('Confirmation failed');
      return data.revertConfirmRoughReservation;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  confirmRoughReservations = async (id: string): Promise<Partial<ReservationInterface[]>> => {
    try {
      const CONFIRM_ROUGH = gql`
        mutation update($id: String!) {
          confirmRoughReservations(campaign: $id) {
            days {
              date
            }
            months {
              month
              year
            }
          }
        }
      `;
      const { data, errors } = await this.client.mutate(CONFIRM_ROUGH, {
        id,
      });
      console.log(data);

      if (errors || !data.confirmRoughReservations) throw Error('Confirmation failed');
      return data.confirmRoughReservations;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  revertConfirmRoughReservations = async (id: string): Promise<Partial<ReservationInterface[]>> => {
    try {
      const CONFIRM_ROUGH = gql`
        mutation update($id: String!) {
          revertConfirmRoughReservations(campaign: $id) {
            days {
              date
            }
            months {
              month
              year
            }
          }
        }
      `;
      const { data, errors } = await this.client.mutate(CONFIRM_ROUGH, {
        id,
      });
      if (errors || !data.revertConfirmRoughReservations) throw Error('Confirmation failed');
      return data.revertConfirmRoughReservations;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  updateRoughReservationsMonths = async (id: string): Promise<Partial<ReservationInterface[]>> => {
    try {
      const CONFIRM_ROUGH = gql`
        mutation update($id: String!) {
          confirmRoughReservationMonths(campaign: $id) {
            id
            rough
          }
        }
      `;
      const { data, errors } = await this.client.mutate(CONFIRM_ROUGH, {
        id,
      });
      if (errors || !data.confirmRoughReservationMonths) throw Error('Confirmation failed');
      return data.confirmRoughReservationMonths;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  revertConfirmRoughReservationsMonths = async (
    id: string
  ): Promise<Partial<ReservationInterface[]>> => {
    try {
      const REVERT_CONFIRM_ROUGH_MONTHS = gql`
        mutation update($id: String!) {
          revertConfirmRoughReservationMonths(campaign: $id) {
            id
            rough
          }
        }
      `;
      const { data, errors } = await this.client.mutate(REVERT_CONFIRM_ROUGH_MONTHS, {
        id,
      });
      if (errors || !data.revertConfirmRoughReservationMonths) throw Error('Confirmation failed');
      return data.revertConfirmRoughReservationMonths;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getCampaignReservations = async (id: string): Promise<ReservationDaysMonths> => {
    try {
      const GET_CAMPAIGNS = gql`
        query getReservationsByCampaignId($id: String!) {
          getReservationsByCampaignId(campaignId: $id) {
            days {
              id
              created
              date
              productId
              productVariationId
              campaignId
              quantity
            }
            months {
              id
              created
              year
              month
              productId
              productVariationId
              campaignId
              quantity
            }
          }
        }
      `;
      const { data, errors } = await this.client.mutate(GET_CAMPAIGNS, {
        id,
      });
      if (errors || !data.getReservationsByCampaignId) throw Error('Confirmation failed');
      const reservations = data.getReservationsByCampaignId;

      return reservations;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getActiveReservations = async (rough: boolean): Promise<ReservationDaysMonths> => {
    try {
      const GET_CAMPAIGNS = gql`
        query getActiveReservations($rough: Boolean!) {
          getActiveReservations(rough: $rough) {
            days {
              id
              created
              date
              productId
              productVariationId
              campaignId
              quantity
              batch
            }
            weeks {
              id
              created
              week
              year
              productId
              productVariationId
              quantity
              batch
              campaignId
            }
            months {
              id
              created
              year
              month
              productId
              productVariationId
              campaignId
              quantity
              batch
            }
          }
        }
      `;
      const { data, errors } = await this.client.mutate(GET_CAMPAIGNS, {
        rough,
      });
      if (errors || !data.getActiveReservations) throw Error('Fetching active reservations failed');
      const reservations = data.getActiveReservations;

      return reservations;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getArchivedReservations = async (rough: boolean): Promise<ReservationDaysMonths> => {
    try {
      const GET_CAMPAIGNS = gql`
        query getArchivedReservations($rough: Boolean!) {
          getArchivedReservations(rough: $rough) {
            days {
              id
              created
              date
              productId
              productVariationId
              campaignId
              quantity
              batch
            }
            weeks {
              id
              created
              year
              week
              productId
              productVariationId
              campaignId
              quantity
              batch
            }
            months {
              id
              created
              year
              month
              productId
              productVariationId
              campaignId
              quantity
              batch
            }
          }
        }
      `;
      const { data, errors } = await this.client.mutate(GET_CAMPAIGNS, {
        rough,
      });
      if (errors || !data.getArchivedReservations)
        throw Error('Fetching archived reservations failed');
      const reservations = data.getArchivedReservations;

      return reservations;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getReservationsByStartEnd = async (
    startDate?: Date | undefined,
    endDate?: Date | undefined
  ): Promise<ReservationDaysMonths> => {
    try {
      const GET_CAMPAIGNS_BY_START_END = gql`
        query getReservationsByStartEnd($startDate: DateTime, $endDate: DateTime) {
          getReservationsByStartEnd(dates: { startDate: $startDate, endDate: $endDate }) {
            days {
              id
              created
              date
              productId
              productVariationId
              campaignId
              quantity
            }
            months {
              id
              created
              year
              month
              productId
              productVariationId
              campaignId
              quantity
            }
          }
        }
      `;
      const { data, errors } = await this.client.mutate(GET_CAMPAIGNS_BY_START_END, {
        startDate,
        endDate,
      });

      if (errors || !data.getReservationsByStartEnd)
        throw Error('Fetching reservations by date  failed');
      const reservations = data.getReservationsByStartEnd;

      return reservations;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getReservationsByDateAndVariation = async (
    date: Date,
    variation: string
  ): Promise<ReservationDaysMonths> => {
    try {
      const GET_CAMPAIGNS_BY_START_END = gql`
        query getReservations($date: DateTime!, $productVariationId: String!) {
          getReservationsByDateAndProductVariationId(
            date: $date
            productVariationId: $productVariationId
          ) {
            months {
              id
              created
              year
              month
              productId
              productVariationId
              campaignId
              quantity
            }
            weeks {
              id
              created
              week
              year
              productId
              productVariationId
              campaignId
              quantity
            }
            days {
              id
              created
              date
              productId
              productVariationId
              campaignId
              quantity
            }
          }
        }
      `;
      const { data, errors } = await this.client.query(GET_CAMPAIGNS_BY_START_END, {
        date,
        productVariationId: variation,
      });
      if (errors || !data.getReservationsByDateAndProductVariationId)
        throw Error('Fetching reservations by date  failed');
      const reservations = data.getReservationsByDateAndProductVariationId;

      return reservations;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  getReservationsByDate = async (date: Date): Promise<ReservationInterface[]> => {
    try {
      const GET_CAMPAIGNS_BY_DATE = gql`
        query getReservations($date: DateTime!) {
          getReservationsByDate(date: $date) {
            id
            created
            date
            productId
            productVariationId
            campaignId
            quantity
          }
        }
      `;
      const { data, errors } = await this.client.query(GET_CAMPAIGNS_BY_DATE, {
        date,
      });

      if (errors || !data.getReservationsByDate)
        throw Error('Fetching reservations by date  failed');
      const reservations = data.getReservationsByDate;

      return reservations;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  addReservationWeek = async (
    reservation: ReservationWeekInput
  ): Promise<ReservationWeekInterface> => {
    try {
      const ADD_RESERVATION_WEEK = gql`
        mutation addReservationWeek(
          $week: Float!
          $year: Float!
          $product: String!
          $variation: String!
          $campaign: String!
          $quantity: Float!
          $rough: Boolean
          $batch: Float
        ) {
          addReservationWeek(
            reservation: {
              week: $week
              year: $year
              productId: $product
              productVariationId: $variation
              campaignId: $campaign
              quantity: $quantity
              rough: $rough
              batch: $batch
            }
          ) {
            id
          }
        }
      `;
      const { data } = await this.client.mutate(ADD_RESERVATION_WEEK, {
        ...reservation,
      });
      if (!data.addReservationWeek) throw new Error('Reservation creation failed');

      return data.addReservationWeek;
    } catch (error) {
      console.log(error);
      throw new Error(error.message);
    }
  };
}

export default new ReservationClient();
