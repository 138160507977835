import { Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useAddOrderState } from '../context/add-order-context';
import { useOrderFormDispatch, useOrderFormState } from '../context/order-form-context';
import {
  CampaignDetailInterface,
  CampaignInterface,
  OrderSummary,
  ProductSettingInterface,
} from '../interfaces';
import calculatePrice from '../lib/use-cases/calculate-price';
import { parseCampaignPriceInput } from '../types/campaign-price-input';
import OrderBillingInfo from './order-details/order-billing-info';
import OrderMediaDiscount from './order-details/order-media-discount';
import OrderSummaryFormCustomerDiscount from './summary-form/summary-form-customer-discount';
import OrderSummaryFormDetails from './summary-form/summary-form-details';
import OrderSummaryFormPriceSummary from './summary-form/summary-form-price-summary';
import SummaryFormTextArea from './summary-form/summary-form-text-area';
import SummaryFormTextAreaEditor from './summary-form/summary-form-text-area-editor';
import OrderSummaryListprice from './summary-form/summary-listprice';
const useStyles = makeStyles(() => ({
  inputContainer: {
    justifyContent: 'space-between',
  },
  rightItem: {
    width: '400px',
  },
  label: {
    fontSize: '14px',
  },
  boldText: {
    fontWeight: 'bold',
  },
  container: {
    paddingLeft: '24px',
    width: '800px',
    marginTop: '60px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
}));

interface SummaryProps {
  handleSubmit: Function;
  submitting: boolean;
  summaryTypeFormat?: {
    settings: ProductSettingInterface[];
    settingValues: CampaignDetailInterface[];
    type: string;
  };
  campaignData?: CampaignInterface;
  setErrors: (values: boolean) => void;
}

const OrderSummaryForm: React.FC<SummaryProps> = ({
  handleSubmit,
  submitting,
  summaryTypeFormat,
  campaignData,
  setErrors,
}: SummaryProps) => {
  const [values, setValues] = useState<OrderSummary>();
  const [discountSwitch, setDiscountSwitch] = useState(true);
  const addOrder = useAddOrderState();
  const orderFormState = useOrderFormState();
  const orderFormDispatch = useOrderFormDispatch();
  const classes = useStyles();

  const validationSchema = Yup.object({
    reference: Yup.string().max(40, 'Pituus enimmillään 40 merkkiä'),
    invoiceDiscount: !discountSwitch
      ? Yup.number().when('discountType', {
          is: '%',
          then: Yup.number().max(100, 'Alennusprosentin pitää olla 100 tai alle'),
        })
      : Yup.number(),
    discountType: Yup.string(),
    invoiceInstructions: Yup.string(),
    materialGuide: Yup.string(),
    additionalInfo: Yup.string(),
  });

  const onSubmit = async (): Promise<void> => {
    handleSubmit(values);
  };

  const getInitialValues = (): any => {
    const initial: any = {
      reference: campaignData ? campaignData.reference : '',
      invoiceDiscount: campaignData
        ? campaignData.campaignPrice.discountAmount
          ? campaignData.campaignPrice.discountAmount
          : campaignData.campaignPrice.discountPercent
        : 0,
      discountType: campaignData ? (campaignData.campaignPrice.discountAmount ? '€' : '%') : '%',
      discountAmount: campaignData
        ? campaignData.campaignPrice.discountAmount
          ? campaignData.campaignPrice.discountAmount
          : 0
        : 0,
      discountPercent: campaignData
        ? campaignData.campaignPrice.discountPercent
          ? campaignData.campaignPrice.discountPercent
          : 0
        : 0,
      additionalInfo: campaignData
        ? campaignData.additionalInfo
          ? campaignData.additionalInfo
          : ''
        : '',
      materialGuide:
        campaignData && campaignData.materialGuide
          ? campaignData.materialGuide
          : `Toimitathan valmiin aineiston osoitteeseen mediamyynti.aineistot@punamusta.com viimeistään kaksi arkipäivää ennen kampanjan alkua. Otsikoi sähköpostiviestisi mainitsemalla, mihin mediaan mainoksesi on tulossa sekä yrityksesi nimi. Esimerkiksi: Ratikka / diginäytöt / yrityksesi nimi tai Ratikka / kokoteippaukset / yrityksesi nimi tai Keskustori / diginäytöt / yrityksesi nimi.`, //'Valmis aineisto toimitetaan ratikka.aineistot@punamusta.com -osoitteeseen kaksi arkipäivää ennen kampanjan alkua.', // 'Aineisto toimitetaan erikseen lähetettävän aineistolinkin kautta. Tilauksen yhteydessä ilmoitettu aineiston toimittaja saa sähköpostitse linkin ja ohjeet aineiston toimittamiseen.',
    };
    if (campaignData?.freeText) initial['freeText'] = campaignData.freeText;
    if (campaignData?.invoiceInstruction) {
      let invoiceInstruction = campaignData.invoiceInstruction;
      invoiceInstruction = invoiceInstruction.replace('ENNAKKOLASKU', '');
      invoiceInstruction = invoiceInstruction.replace('OSALASKU', '');
      initial['invoiceInstruction'] = invoiceInstruction;
    }
    return initial;
  };

  const formik = useFormik({
    initialValues: getInitialValues(),
    validationSchema,
    onSubmit: onSubmit,
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
  });

  const calculatePriceHandler = async (order: any): Promise<void> => {
    try {
      const price = await calculatePrice(
        order,
        addOrder.customPrice,
        addOrder.customListPrice,
        orderFormState.batches,
        addOrder.limitType,
        !!orderFormState.price?.mediaDiscount,
        addOrder.floatingOrder
      );
      const campaignPrice = parseCampaignPriceInput({
        ...price,
        campaign: campaignData ? campaignData.id : '',
        discountAmount: order.isPercentage ? 0 : order.discountValue,
        discountPercent: order.isPercentage ? order.discountValue : 0,
      });

      orderFormDispatch({
        type: 'updateCampaignPrice',
        payload: campaignPrice,
      });
    } catch (e) {
      //pass
    }
  };

  useEffect(() => {
    if (campaignData) {
      if (campaignData.campaignPrice.discountAmount) {
        setDiscountSwitch(false);
      }
      if (campaignData.campaignPrice.discountPercent) {
        setDiscountSwitch(false);
      }
    }
  }, []);

  useEffect(() => {
    if (submitting) {
      formik.submitForm();
    }
  }, [submitting]);

  useEffect(() => {
    const batch =
      orderFormState && orderFormState.batches.length ? orderFormState.batches[0] : undefined;
    if (!batch) return;

    const order = {
      productId: orderFormState.productId,
      customerDiscount: addOrder.customerDiscount,
      isPercentage: formik.values.discountType === '%',
      discountValue: !orderFormState.price
        ? 0
        : formik.values.discountType === '%'
        ? orderFormState.price.discountPercent
        : orderFormState.price.discountAmount,
      vat: 25.5,
    };

    if (order.productId) {
      calculatePriceHandler(order);
    }
  }, [
    addOrder.limitType,
    orderFormState.productId,
    orderFormState.batches,
    orderFormState.price?.discountAmount,
    orderFormState.price?.discountPercent,
    addOrder.customerBusinessId,
    formik.values.discountType,
    addOrder.customListPrice,
    orderFormState.price?.mediaDiscount,
  ]);

  useEffect(() => {
    const newValues = { ...formik.values };
    if (newValues.discountType === '%' && newValues.discountPercent === 0) {
      newValues.discountPercent = newValues.invoiceDiscount;
    }
    if (newValues.discountType === '€' && newValues.discountAmount === 0) {
      newValues.discountAmount = newValues.invoiceDiscount;
    }
    setValues(newValues);
  }, [formik.values]);

  useEffect(() => {
    if (Object.keys(formik.errors).length > 0) {
      setErrors(true);
      return;
    }
    setErrors(false);
  }, [formik.errors]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid className={classes.container} container>
        <Grid
          container
          className={classes.inputContainer}
          alignItems="flex-end"
          item
          style={{ marginBottom: '60px' }}
        >
          <Grid item>
            <Typography className={classes.title}>Laskunviite</Typography>
          </Grid>
          <Grid className={classes.rightItem} item>
            <Grid container>
              <TextField
                name="reference"
                onChange={formik.handleChange}
                placeholder=""
                value={formik.values.reference}
              ></TextField>
              <Typography className={classes.error}>
                {formik.errors.reference ? formik.errors.reference : null}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <SummaryFormTextAreaEditor
          name="freeText"
          title="Vapaakenttä"
          placeholder=""
          formik={formik}
        />
        <OrderBillingInfo />
        <SummaryFormTextAreaEditor
          name="invoiceInstruction"
          title="Laskutusohje"
          placeholder=""
          formik={formik}
        />
        <SummaryFormTextAreaEditor
          name="materialGuide"
          minHeight="200px"
          title="Aineisto-ohje"
          placeholder=""
          formik={formik}
        />
        <SummaryFormTextArea
          name="additionalInfo"
          title="Lisäinfo Sisu-tiimille"
          placeholder=""
          formik={formik}
        />
        <OrderSummaryFormDetails summaryTypeFormat={summaryTypeFormat} />
        <OrderSummaryListprice />
        <Grid
          container
          className={classes.inputContainer}
          alignItems="flex-end"
          item
          style={{ marginBottom: '35px' }}
        >
          <Grid item>
            <Typography className={classes.boldText}>
              Asiakkaan kiinteä alennus multipressistä
            </Typography>
          </Grid>
          <Grid item className={classes.rightItem}>
            <Typography className={`${classes.boldText} ${classes.title}`}>
              {addOrder.customerDiscount} %
            </Typography>
          </Grid>
        </Grid>
        <OrderSummaryFormCustomerDiscount
          discountSwitch={discountSwitch}
          setDiscountSwitch={setDiscountSwitch}
          formik={formik}
        />
        <OrderMediaDiscount />
        <OrderSummaryFormPriceSummary />
      </Grid>
    </form>
  );
};

export default OrderSummaryForm;
