import {
  Button,
  ClickAwayListener,
  Collapse,
  Grid,
  IconButton,
  makeStyles,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { navigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import {
  CampaignInterface,
  ProductVariationInterface,
  ProviderInterface,
  ReservationInterface,
} from '../../interfaces';
import campaignClient from '../../lib/campaign-client';
import { capitalizeString, doubleDecimalString } from '../../utils/string-utils';
import LogTable from './campaign-row/log-table';
import ReservationTable from './campaign-row/reservation-table';
import CampaignStatusSelect from './campaign-status-select';
import ApproveModal from './offer-approve-modal';

interface ReservationGroup {
  date?: Date;
  month?: number;
  year?: number;
  variations: string[];
  week?: number;
}

interface Props {
  singleCampaign?: CampaignInterface;
  type: string;
  variations: ProductVariationInterface[];
  refresh: () => Promise<void>;
  provider?: ProviderInterface;
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  popperContainer: {
    background: 'white',
    marginTop: '10px',
  },
  detailTitle: {
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '0.21px',
  },
  detaiValue: {
    fontSize: '16px',
    letterSpacing: '0.15px',
  },
  detailContainer: {
    margin: '5px',
  },
  boldCell: {
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '0.15px',
  },
  button: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    backgroundColor: '#5E8EFC',
    boxShadow: '0px 1px 3px #00000033',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    color: '#FFFFFF',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: 'rgba(94, 142, 252,0.7)',
    },
  },
  headerRow: {
    backgroundColor: '#FAFAFB',
    color: '#000000',
  },
  logTable: {
    marginTop: '20px',
  },
});

const FloatingCampaign: React.FC<Props> = ({
  singleCampaign,
  type,
  variations,
  refresh,
  provider,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);
  const classes = useRowStyles();
  // const [sortedReservations, setSortedReservations] = useState<
  //   ReservationInterface[]
  // >([]);
  const [popperOpen, setPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    if (singleCampaign && singleCampaign.reservations && singleCampaign.isMonth) {
      const reservationsCopy = [...singleCampaign.reservations];
      if (reservationsCopy.length > 0) {
        //const sorted = sortMonthReservations(reservationsCopy);
        // setSortedReservations([...sorted]);
      }
    }
  }, [singleCampaign]);

  if (!singleCampaign) return null;

  const {
    id,
    campaignName,
    customer,
    customerEmail,
    materialApproverEmail,
    materialSupplierEmail,
    materialApproverPhone,
    materialSupplierPhone,
    contactPerson: customerPerson,
    campaignPrice,
    campaignDetails,
    reservations = [],
    jobNumber,
    user,
    isMonth,
    status,
    freeText = '',
    floatingOrderStatus = {},
    campaignNumber,
  } = singleCampaign;

  const {
    customerName = '-',
    businessid = '-',
    address = '-',
    invoiceDiscount = '-',
    invoiceAddresses,
  } = customer || {};

  if (!id) return null;

  const { totalPrice = 0, vat = 0, discount = 0, listPrice = 0 } = campaignPrice || {};

  const companyDetails = [
    { label: 'Toiminimi', value: customerName },
    { label: 'Asiakastunnus', value: businessid ? businessid : '-' },
    { label: 'Alennusprosentti', value: invoiceDiscount + '%' },
    { label: 'Toimitusosoite', value: address },
    {
      label: 'Laskutusosoite',
      value: invoiceAddresses && invoiceAddresses[0] ? invoiceAddresses[0].invoice_address : '',
    },
  ];

  const contactPersonDetails = [
    { label: 'Tilaajan nimi', value: customerPerson },
    { label: 'Tilaajan sähköposti', value: customerEmail },
    { label: 'Aineiston toimittaja', value: materialSupplierEmail },
    {
      label: 'Aineiston toimittajan puhelinnumero',
      value: materialSupplierPhone,
    },
    { label: 'Aineiston hyväksyjä', value: materialApproverEmail },
    {
      label: 'Aineiston hyväksyjän puhelinnumero',
      value: materialApproverPhone,
    },
  ];

  let material = '-';

  const orderDetails = [
    {
      label: 'Mainoksen pituus',
      value: `${
        reservations[0] ? reservations[0].quantity + (isMonth ? ' kappaletta' : ' sekuntia') : '-'
      }`,
    },
    { label: 'Työnumero', value: jobNumber ? jobNumber : '-' },
    { label: 'Tilausnumero', value: campaignNumber ? campaignNumber : '-' },
    ...campaignDetails.map((detail) => {
      if (detail.label === 'Aineisto') material = detail.value;
      return { label: detail.label, value: detail.value };
    }),
  ];

  orderDetails.push({ label: 'Vapaakenttä', value: freeText });

  const orderDetails2 = [
    { label: 'Hinta ovh', value: `${doubleDecimalString(listPrice)}€` },
    {
      label: 'Asiakaskohtainen alennus',
      value: `${
        (discount / listPrice) * 100 ? doubleDecimalString((discount / listPrice) * 100) : 0
      }% / -${doubleDecimalString(discount)}€`,
    },
    {
      label: 'Mediatoimistoalennus 15%',
      value: `${
        campaignPrice.mediaDiscount
          ? doubleDecimalString(campaignPrice.mediaDiscount) + '€'
          : 'Ei annettu'
      }`,
    },
    {
      label: 'Yhteensä Alv 0%',
      value: `${doubleDecimalString(listPrice - discount - campaignPrice.mediaDiscount)}€`,
    },
    { label: 'Yhteensä Alv 25,5%', value: `${doubleDecimalString(vat)}€` },
    { label: 'Yhteensä', value: `${doubleDecimalString(totalPrice)}€` },
  ];

  const mapDetails = (array: { [label: string]: string | number }[]): any => {
    return array.map((detail) => (
      <Grid className={classes.detailContainer} key={detail.label} container direction="column">
        <Typography className={classes.detailTitle}>{detail.label}</Typography>
        <Typography className={classes.detaiValue}>{capitalizeString(detail.value)}</Typography>
      </Grid>
    ));
  };

  const onClickNew = (): void => {
    navigate('/ordertemplate', { state: { singleCampaign } });
  };

  const onClickUpdate = (): void => {
    navigate('/orderUpdate', { state: { singleCampaign } });
  };

  const onRemove = async (id?: string): Promise<void> => {
    if (!id) return;
    const confirmation = window.confirm(
      'Oletko varma että tilaus varauksineen poistetaan järjestelmästä?'
    );
    if (confirmation) {
      try {
        await campaignClient.removeCampaign(id);
        window.location.reload();
      } catch (error) {
        alert(error.message);
      }
    }
  };

  const mapVariationLabel = (): string => {
    let variationIds: string[] = [];
    singleCampaign.reservations?.forEach((reservation) => {
      if (reservation.productVariationId) variationIds.push(reservation.productVariationId);
    });

    variationIds = variationIds.filter((v, i, a) => a.indexOf(v) === i);

    const labels = variationIds
      .map((a) => {
        const variation = variations.find((variation) => variation.id === a);
        return variation;
      })
      .sort((a, b) => {
        return ('' + a?.name).localeCompare('' + b?.name);
      });

    let variationLabel = '';

    labels.forEach((label, i) => {
      if (!label) return;
      variationLabel = variationLabel + label?.name + (i === labels.length - 1 ? '' : ', ');
    });

    if (labels.length === 3) variationLabel = 'Koko päivä';

    return variationLabel;
  };

  const getFilteredAndSortedReservations = (
    reservations: ReservationInterface[] = []
  ): ReservationGroup[] => {
    const groupedReservations: ReservationGroup[] = [];

    reservations.forEach((current: ReservationInterface) => {
      const found = groupedReservations.find((item: ReservationGroup) => {
        if (item.date && current.date)
          return new Date(item.date).getTime() === new Date(current.date).getTime();
        else {
          return false;
        }
      });
      if (found) {
        found.variations.push(current.productVariationId);
      } else {
        groupedReservations.push({
          date: current.date,
          month: current.month,
          year: current.year,
          variations: [current.productVariationId],
          week: current.week,
        });
      }
    });

    const orderedReservations = groupedReservations.sort((a, b) => {
      if (a.date && b.date)
        return new Date(a.date).getTime() < new Date(b.date).getTime()
          ? -1
          : new Date(a.date).getTime() === new Date(b.date).getTime()
          ? 0
          : 1;
      if (a.month && a.year && b.month && b.year) {
        const result = a.year < b.year ? -1 : a.year > b.year ? 1 : a.month < b.month ? -1 : 1;
        return result;
      }
      return 0;
    });

    return orderedReservations;
  };

  const reservationGroups = getFilteredAndSortedReservations(singleCampaign.reservations);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handlePopper = (event: any) => {
    setAnchorEl(event.currentTarget);
    setPopperOpen(!popperOpen);
  };

  const handleClose = (event: any): void => {
    setPopperOpen(false);
  };

  return (
    <>
      {type === 'offer' && (
        <ApproveModal
          open={ModalOpen}
          setOpen={setModalOpen}
          id={id}
          customer={customerName}
          campaign={campaignName}
        ></ApproveModal>
      )}
      <TableRow className={classes.root} classes={{ root: classes.root }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={(): void => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>

        <TableCell align="left" className={classes.boldCell}>
          {customerName}
        </TableCell>
        <TableCell className={classes.detaiValue}>{campaignName}</TableCell>
        <TableCell align="left" className={classes.detaiValue}>
          <Typography>
            {floatingOrderStatus
              ? `${floatingOrderStatus.filled}/${floatingOrderStatus.ordered}`
              : '-'}
          </Typography>
          <Typography>{mapVariationLabel()}</Typography>
        </TableCell>
        <TableCell align="left" className={classes.detaiValue}>
          {user ? user.name : '-'}
        </TableCell>
        <>
          <TableCell classes={{ root: classes.root }} align="left">
            {`${campaignNumber}${jobNumber ? ` / ${jobNumber}` : ''}`}
          </TableCell>
          {status !== undefined && (
            <CampaignStatusSelect refresh={refresh} campaignId={id} status={status} />
          )}
          <TableCell align="left" className={classes.detaiValue}>
            {capitalizeString(material)}
          </TableCell>
        </>

        <TableCell classes={{ root: classes.root }} align="left">
          <Button onClick={handlePopper} variant="outlined" className={classes.button}>
            Toiminnot
          </Button>
          <Popper id={`popper-button${singleCampaign.id}`} open={popperOpen} anchorEl={anchorEl}>
            <ClickAwayListener onClickAway={handleClose}>
              <Grid
                className={classes.popperContainer}
                container
                justifyContent="center"
                direction="column"
                spacing={1}
              >
                <Grid item>
                  <Button
                    onClick={(): Promise<void> => onRemove(singleCampaign.id)}
                    variant="outlined"
                    className={classes.button}
                  >
                    Poista
                  </Button>
                </Grid>

                <Grid item>
                  <Button onClick={onClickUpdate} variant="outlined" className={classes.button}>
                    Muokkaa
                  </Button>
                </Grid>

                <Grid item>
                  <Button onClick={onClickNew} variant="outlined" className={classes.button}>
                    Käytä pohjana
                  </Button>
                </Grid>
              </Grid>
            </ClickAwayListener>
          </Popper>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={15} style={{ paddingBottom: '20px', paddingTop: 0, width: '100%' }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: '#FAFAFB' }}>
                  <TableCell className={classes.detailTitle}>Yritys</TableCell>
                  <TableCell className={classes.detailTitle}>Yhteyshenkilöt</TableCell>
                  <TableCell className={classes.detailTitle}>Tilauksen Yhteenveto</TableCell>
                  <TableCell className={classes.detailTitle}>Tilauksen Yhteenveto</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      verticalAlign: 'top',
                      borderRight: '1px solid rgba(224, 224, 224, 1)',
                    }}
                  >
                    {mapDetails(companyDetails)}
                  </TableCell>
                  <TableCell
                    style={{
                      verticalAlign: 'top',
                      borderRight: '1px solid rgba(224, 224, 224, 1)',
                    }}
                  >
                    {mapDetails(contactPersonDetails)}
                  </TableCell>
                  <TableCell
                    style={{
                      verticalAlign: 'top',
                      borderRight: '1px solid rgba(224, 224, 224, 1)',
                    }}
                  >
                    {mapDetails(orderDetails)}
                  </TableCell>
                  <TableCell style={{ verticalAlign: 'top' }}>
                    {mapDetails(orderDetails2)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {singleCampaign && (
              <ReservationTable singleCampaign={singleCampaign} provider={provider} />
            )}

            <LogTable singleCampaign={singleCampaign} />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default FloatingCampaign;
