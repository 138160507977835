// import Nav from './nav';
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import { FormikErrors } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Month, ReservationMonthSummary } from '../../interfaces';
import reservationClient from '../../lib/reservation-client';
import { monthChecker } from '../../utils/monthpicker-checker';
import ArrowButton from '../arrow-button';
import MonthComponent from './month';
import { months } from './months';

interface MonthPickerProps {
  variation: string;
  setOrderMonths: Function;
  fieldError: string | string[] | FormikErrors<Month>[] | undefined;
  campaignMonths: Month[];
  notifyUser: boolean;
  campaignId?: string;
}

interface SelectedMonths {
  year: number;
  month: number;
}

const useStyles = makeStyles(() => ({
  container: { marginLeft: '-47.5px' },
  PickerContainer: {
    minWidth: '800px',
    minHeight: '250px',
    justifyContent: 'center',
    backgroundColor: '#FBFBFC',
    margin: '12.5px',
    padding: '20px',
    border: '1px solid #B1B8C026',
  },
  yearContainer: {
    width: '100%',
  },
  text: {
    textAlign: 'center',
  },
  year: { fontWeight: 'bold', fontSize: '16px' },
}));

const MonthPicker: React.FC<MonthPickerProps> = ({
  variation,
  setOrderMonths,
  fieldError,
  campaignMonths,
  notifyUser,
  campaignId,
}: MonthPickerProps) => {
  const [year, setYear] = useState<Date>();
  const [year2, setyear2] = useState<Date>();
  const [selectedMonths, setSelectedMonths] = useState<SelectedMonths[]>(campaignMonths);
  const [reservSummary, setReservSummary] = useState<ReservationMonthSummary[]>();
  const [fullMonths, setFullMonths] = useState<ReservationMonthSummary[]>([]);
  const classes = useStyles();

  useEffect(() => {
    if (!year || !year2) {
      setYear(moment().toDate());
      setyear2(moment().add(1, 'year').toDate());
    }
  }, []);

  useEffect(() => {
    const getSummary = async (): Promise<void> => {
      if (year && year2 && variation) {
        const result = await reservationClient.getReservationMonthSummary(
          variation,
          1,
          new Date().getFullYear(),
          12,
          year2.getFullYear() + 10,
          campaignId
        );

        if (result) setReservSummary(result);
      }
    };
    getSummary();
  }, [variation, year]);

  useEffect(() => {
    setOrderMonths(selectedMonths);
  }, [selectedMonths]);

  const handleLeftClick = () => {
    if (year && year2) {
      if (moment(year).isAfter(moment(), 'year')) {
        setYear(moment(year).subtract(1, 'year').toDate());
        setyear2(moment(year2).subtract(1, 'year').toDate());
      }
    }
  };

  const handleRightClick = () => {
    if (year && year2) {
      setYear(moment(year).add(1, 'year').toDate());
      setyear2(moment(year2).add(1, 'year').toDate());
    }
  };

  const disableButton = () => {
    if (moment(year).isSame(moment(), 'year')) return true;

    return false;
  };

  useEffect(() => {
    const result = reservSummary?.filter((summary) => summary.available <= 0);

    if (result) setFullMonths(result);
  }, [reservSummary]);

  useEffect(() => {
    if (notifyUser && fullMonths.length) {
      //pass
      const result = campaignMonths.filter((month) =>
        fullMonths.find((f) => f.month === month.month && f.year === month.year)
      );
      if (result.length)
        alert(
          'Seuraavat ajanjaksot olivat jo täyttyneet ja valinnat poistetaan: ' +
            result.map((item) => `${item.month}/${item.year}`).join(', ')
        );
      setSelectedMonths(
        campaignMonths.filter(
          (m) => !result.find((r: Month) => r.month === m.month && r.year === m.year)
        )
      );
    }
  }, [fullMonths]);

  const sortMonthArray = (array: ReservationMonthSummary[] | SelectedMonths[]) => {
    return array.sort((a, b) => {
      if (a.year < b.year) {
        return -1;
      }
      if (a.year > b.year) {
        return 1;
      }
      if (a.month < b.month) {
        return -1;
      }
      if (a.month > b.month) {
        return 1;
      }
      return 0;
    });
  };

  const clickMonth = (e: any, year: number, month: number) => {
    let monthArray = [...selectedMonths];

    const alreadySelected = monthArray.findIndex((selectedMonth) => {
      return selectedMonth.year === year && selectedMonth.month === month;
    });

    if (
      monthArray.length > 2 ||
      (monthArray.length === 2 &&
        monthArray[0].month === monthArray[monthArray.length - 1].month - 1)
    ) {
      return setSelectedMonths([]);
    }

    if (alreadySelected >= 0) monthArray.splice(alreadySelected, 1);
    else monthArray.push({ year, month });

    monthArray = sortMonthArray(monthArray);

    if (monthArray.length === 2) {
      const first = monthArray[0];
      const last = monthArray[monthArray.length - 1];
      const startYear = first.year;
      const startMonth = first.month;
      const endYear = last.year;
      const endMonth = last.month;
      monthArray = [];

      for (let i = startYear; i <= endYear; i++) {
        for (let j = 1; j <= 12; j++) {
          if (startYear < endYear) {
            if (i === startYear) {
              if (j >= startMonth) monthArray.push({ year: i, month: j });
            }

            if (i > startYear && i < endYear) {
              monthArray.push({ year: i, month: j });
            }
            if (i === endYear) {
              if (j <= endMonth) {
                monthArray.push({ year: i, month: j });
              }
            }
          }
          if (i === startYear && i === endYear) {
            if (j >= startMonth && j <= endMonth) {
              monthArray.push({ year: i, month: j });
            }
          }
        }
      }
    }

    setSelectedMonths(monthArray);
  };

  const setStyle = (year: number, month: number, i: number) => {
    const monthArray = [...selectedMonths];
    const reservation = reservSummary?.find(
      (reservMonth) => reservMonth.year === year && reservMonth.month === month
    );

    const selected = monthArray.find(
      (selectedMonth) => selectedMonth.year === year && selectedMonth.month === month
    );

    let style = { color: 'white', disabled: false, fontColor: 'initial' };
    const blue = '94, 142, 252';
    const green = '198, 243, 213';
    const yellow = '247, 241, 25';
    const red = '255, 111, 117';
    if (reservation) {
      if (reservation.available / reservation.limit > 0.33) {
        style.color = green;

        if (selected) {
          style.fontColor = '#FFFFFF';
          style.color = blue;
        }
      }

      if (reservation.available / reservation.limit < 0.34) {
        style.color = yellow;

        if (selected) {
          style.fontColor = '#FFFFFF';
          style.color = blue;
        }
      }
      if (reservation.available / reservation.limit <= 0) {
        style.disabled = true;
        style.color = red;
      }

      if (year <= moment().year() && month < moment().month() + 1) {
        style.disabled = true;
        style.color = 'white';
      }
    }

    const first = monthArray[0];

    const fullMonthArray = sortMonthArray([...fullMonths]);

    const checkedMonth = monthChecker(fullMonthArray, first, month, year, style);
    if (checkedMonth) style = checkedMonth;
    return style;
  };

  const getYearString = (year?: Date) => {
    return year ? parseInt(moment(year).format('YYYY')) : 0;
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        className={classes.container}
        direction="row"
        wrap="nowrap"
      >
        <Grid item>
          <ArrowButton
            disableButton={disableButton()}
            direction="left"
            handleClick={handleLeftClick}
          ></ArrowButton>
        </Grid>
        <Grid direction="row" wrap="nowrap" container className={classes.PickerContainer}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="space-evenly"
            className={classes.yearContainer}
          >
            <Grid item>
              <Typography className={classes.year}>
                {year ? moment(year).format('YYYY') : ''}
              </Typography>
            </Grid>
            <Grid direction="row" container>
              {months.map((month, i) => {
                return (
                  <MonthComponent
                    key={getYearString(year) + '.' + month.value}
                    onClick={clickMonth}
                    style={setStyle(getYearString(year), month.value, i)}
                    label={month.label}
                    id={getYearString(year) + '.' + month.value}
                    year={getYearString(year)}
                    month={month.value}
                  ></MonthComponent>
                );
              })}
            </Grid>
          </Grid>
          <Divider
            variant="middle"
            style={{ height: 'inherit', width: '1px' }}
            orientation="vertical"
          ></Divider>
          <Grid
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            className={classes.yearContainer}
          >
            <Grid item>
              <Typography className={classes.year}>
                {year2 ? moment(year2).format('YYYY') : ''}
              </Typography>
            </Grid>
            <Grid direction="row" justifyContent="space-evenly" container>
              {months.map((month, i) => {
                return (
                  <MonthComponent
                    key={getYearString(year2) + '.' + month.value}
                    onClick={clickMonth}
                    style={setStyle(getYearString(year2), month.value, i)}
                    label={month.label}
                    id={getYearString(year2) + '.' + month.value}
                    year={getYearString(year2)}
                    month={month.value}
                  ></MonthComponent>
                );
              })}
            </Grid>
          </Grid>
        </Grid>

        <Grid item>
          <ArrowButton direction="right" handleClick={handleRightClick}></ArrowButton>
        </Grid>
      </Grid>
      <Grid>
        <Typography>{fieldError ? fieldError : ''}</Typography>
      </Grid>
    </>
  );
};

export default MonthPicker;
