import { Box, Grid, makeStyles, TextField } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles({
  label: {
    margin: '25px 25px 5px 25px',
    fontWeight: 'bold',
    float: 'left',
    width: '150px',
  },
  inputField: { margin: '0px 25px 5px 25px' },
  customer: { width: '500px', float: 'left' },
  grid: {
    'grid-auto-flow': 'row',
    justify: 'flex-start',
    alignItems: 'flex-start',
  },
  error: {
    color: 'red',
    marginLeft: '225px',
    marginBottom: '5px',
  },
});

interface Props {
  formik: any;
  validationSchema: any;
  name: string;
  label: string;
  placeholder: string;
  required?: boolean;
}

const OrderCustomerTextField: React.FC<Props> = ({
  formik,
  validationSchema,
  name,
  label,
  placeholder,
  required = true,
}: Props) => {
  const classes = useStyles();

  const validateSingleField = (
    event: React.FocusEvent<HTMLInputElement>
  ): void => {
    const field = event.currentTarget.name;
    formik.registerField(field, { validationSchema });
    formik.validateField(field);
  };

  return (
    <>
      <Grid item container className={classes.grid}>
        <Grid item>
          <Box className={classes.label}>{label}</Box>
        </Grid>
        <Grid item>
          <TextField
            required={required}
            id={name}
            name={name}
            value={formik.values[name]}
            label={label}
            onChange={formik.handleChange}
            onBlur={validateSingleField}
            placeholder={placeholder}
            className={(classes.inputField, classes.customer)}
          />
        </Grid>
      </Grid>
      <Grid className={classes.error}>
        {formik.errors[name] ? formik.errors[name] : null}
      </Grid>
    </>
  );
};

export default OrderCustomerTextField;
