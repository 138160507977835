import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { CampaignInterface, CampaignLog } from '../../../interfaces';
import campaignClient from '../../../lib/campaign-client';
import userClient from '../../../lib/user-client';
import CTableCell from '../../table/table-cell';

interface Props {
  singleCampaign: CampaignInterface;
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  detaiValue: {
    fontSize: '16px',
    letterSpacing: '0.15px',
  },
  boldCell: {
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '0.15px',
  },
  headerRow: {
    backgroundColor: '#FAFAFB',
    color: '#000000',
  },
  logTable: {
    marginTop: '20px',
  },
});

const LogTable: React.FC<Props> = ({ singleCampaign }: Props) => {
  const classes = useRowStyles();
  const [logs, setLogs] = useState<CampaignLog[]>([]);

  useEffect(() => {
    const getLogs = async (): Promise<void> => {
      if (singleCampaign?.id) {
        try {
          const data = await campaignClient.getCampaigLogsByCampaignId(
            singleCampaign.id
          );
          setLogs(data);
        } catch {
          //pass
        }
      }
    };
    getLogs();
  }, []);

  return (
    <Table className={classes.logTable}>
      <TableHead>
        <TableRow className={classes.headerRow}>
          <TableCell className={classes.boldCell}>Käyttäjä</TableCell>
          <TableCell className={classes.boldCell}>Tuote</TableCell>
          <TableCell className={classes.boldCell}>Luotu</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {logs.map((log) => (
          <TableRow key={log.id}>
            <CTableCell
              resolver={async (): Promise<string> => {
                const user = await userClient.getUser(log.userId);
                if (!user) {
                  return log.userId;
                }
                return user.name ? user.name : user.email ? user.email : '-';
              }}
            />
            <TableCell align="left" className={classes.detaiValue}>
              <Typography>{log.action}</Typography>
            </TableCell>
            <CTableCell
              resolver={async (): Promise<string> => {
                const date = new Date(log.created);
                return `${date.toLocaleDateString(
                  'de-De'
                )} ${date.toLocaleTimeString('de-De')}`;
              }}
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default LogTable;
