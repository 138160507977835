import {
  Grid,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { ReactNode } from 'react';

interface Option {
  label: string;
  value: string;
}

interface SchemaFieldsProps {
  input: {
    label: string;
    name: string;
    placeHolder: string;
    type: string;
    required: boolean;
    options?: Option[];
    fullWidth?: boolean;
    maxLength?: number;
  };
  handleChange: any;
  values: any;
  inputArrayName?: string;
  formikErrors: any;
  formik?: any;
  touched: { [key: string]: boolean };
  updating?: boolean;
}
const useStyles = makeStyles(() => ({
  textField: {},
  errorText: {
    fontSize: '12px',
    letterSpacing: '0.25px',
    lineHeight: '16px',
    fontWeight: 400,
    color: '#E30312',
  },
  menuItem: {
    textTransform: 'capitalize',
  },
}));

const SchemaFields: React.FC<SchemaFieldsProps> = ({
  input,
  handleChange,
  values,
  inputArrayName,
  formikErrors,
  formik,
  touched,
  updating,
}: SchemaFieldsProps) => {
  const classes = useStyles();
  const fieldProps = formik.getFieldProps(input.name);
  const { label, type, name, placeHolder, options } = input;
  const SchemaField = (): ReactNode => {
    switch (type) {
      case 'string': {
        const [name1, name2] = input.name.split('.');
        let disabled = false;
        let value = '';
        if (name1 && name2) value = values[name1][name2];
        else {
          value = values[name1];
        }
        if (!value) value = '';
        if (name1 === 'company' && updating) disabled = true;
        return (
          <TextField
            required={input.required}
            label={label}
            name={inputArrayName ? inputArrayName : name}
            onChange={handleChange}
            placeholder={placeHolder}
            value={value}
            helperText={label}
            fullWidth={true}
            inputProps={{ maxLength: input.maxLength || 150 }}
            onBlur={fieldProps.onBlur}
            className={classes.textField}
            disabled={disabled}
          ></TextField>
        );
      }
      case 'phone':
        return (
          <TextField
            required={input.required}
            label={label}
            variant="outlined"
            name={inputArrayName ? inputArrayName : name}
            onChange={handleChange}
            value={values[name] ? values[name] : ''}
            fullWidth={true}
            onBlur={fieldProps.onBlur}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={
              {
                // startAdornment: (
                //   <InputAdornment position="start">+358</InputAdornment>
                // ),
              }
            }
          ></TextField>
        );
      case 'email':
        return (
          <TextField
            required={input.required}
            label={label}
            name={inputArrayName ? inputArrayName : name}
            onChange={handleChange}
            placeholder={placeHolder}
            value={values[name] ? values[name] : ''}
            helperText={label}
            onBlur={fieldProps.onBlur}
            fullWidth={true}
          ></TextField>
        );
      case 'number':
        return (
          <TextField
            required={input.required}
            name={inputArrayName ? inputArrayName : input.name}
            label={input.label}
            onChange={handleChange}
            variant="outlined"
            placeholder={input.placeHolder}
            value={values[input.name] ? values[input.name] : ''}
            onBlur={fieldProps.onBlur}
            fullWidth={true}
          ></TextField>
        );
      case 'stringOutlined': {
        let disabled = false;

        if (input.name === 'businessRegistration' && updating) disabled = true;

        return (
          <TextField
            required={input.required}
            name={inputArrayName ? inputArrayName : input.name}
            label={input.label}
            onChange={handleChange}
            variant="outlined"
            onBlur={fieldProps.onBlur}
            placeholder={input.placeHolder}
            value={values[input.name] ? values[input.name] : ''}
            fullWidth={true}
            disabled={disabled}
          ></TextField>
        );
      }
      case 'select':
        return (
          <TextField
            required={input.required}
            select
            name={inputArrayName ? inputArrayName : name}
            onChange={handleChange}
            value={values[name] ? values[name] : ''}
            fullWidth={true}
            onBlur={fieldProps.onBlur}
            placeholder={input.placeHolder}
            label={input.label}
          >
            {options &&
              options.map(
                ({ label, value }: Option, index: number): ReactNode => {
                  return (
                    <MenuItem key={`${value}-${index}`} value={value}>
                      <Typography className={classes.menuItem}>
                        {label}
                      </Typography>
                    </MenuItem>
                  );
                }
              )}
          </TextField>
        );
      default:
        return <></>;
    }
  };

  return (
    <Grid item>
      {SchemaField()}

      <Typography className={classes.errorText}>
        {formikErrors[input.name]}
      </Typography>
    </Grid>
  );
};

export default SchemaFields;
