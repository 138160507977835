// import Nav from './nav';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import Loader from 'react-loader-spinner';

const useStyles = makeStyles(() => ({
  loading: {
    position: 'fixed',
    top: '0',
    left: ' 0',
    width: ' 100%',
    height: '100%',
    backgroundColor: 'rgba(16, 16, 16, 0.2)',
  },
  spinner: {
    width: '200px',
    height: '200px',
    color: '#5E8EFC',
  },
}));

interface Props {
  showSpinner: boolean;
}

const LoadingSpinner: React.FC<Props> = ({ showSpinner }: Props) => {
  const classes = useStyles();
  if (!showSpinner) return <></>;
  return (
    <Grid
      container
      className={classes.loading}
      justifyContent="center"
      alignContent="center"
    >
      <Loader
        type="ThreeDots"
        color="#E30613"
        height={100}
        width={100}
      ></Loader>
    </Grid>
  );
};

export default LoadingSpinner;
