import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import {
  ProductInterface,
  ReservationSummary,
  ReservationWeekSummary,
} from '../../interfaces';
import reservationClient from '../../lib/reservation-client';

type Props = {
  summaryDate?: Date;
  product: ProductInterface;
  week?: number;
  year?: number;
};

type DaySummary = {
  name: string;
  summary: ReservationSummary[];
};

const SummaryHoverInfo: React.FC<Props> = ({
  week,
  year,
  summaryDate,
  product,
}: Props) => {
  const [daySummary, setDaySummary] = useState<DaySummary[]>([]);
  const [weekSummary, setWeekSummary] = useState<ReservationWeekSummary[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (product.variations) {
      if (summaryDate) {
        Promise.all(
          product.variations.map(async (variation) => {
            return {
              name: variation.name,
              summary: await reservationClient.getReservationSummary(
                variation.id,
                new Date(moment(summaryDate).format('YYYY-MM-DD')),
                new Date(moment(summaryDate).format('YYYY-MM-DD'))
              ),
            };
          })
        ).then((data) => {
          setDaySummary(data);
          setLoading(false);
        });
      } else if (week && year) {
        Promise.all(
          product.variations.map(async (variation) => {
            return await reservationClient.getReservationWeekSummary(
              variation.id,
              week,
              year,
              week,
              year
            );
          })
        ).then((data) => {
          setWeekSummary((prevState) => data.flat());
          setLoading(false);
        });
      }
    }
  }, [summaryDate, week, year]);

  return (
    <Grid
      item
      style={{
        backgroundColor: 'rgba(177, 184, 192, 0.05)',
        border: ' 1px solid #b1b8c026',
        padding: '10px',
      }}
    >
      <Grid container direction="column">
        <Typography>Päivän täyttöaste</Typography>
        <Typography>
          {summaryDate
            ? moment(summaryDate).format('DD.MM.YYYY')
            : `Viikko ${week}/${year}`}
        </Typography>
        {loading && (
          <Grid container direction="column" alignContent="center">
            <Loader
              type="ThreeDots"
              color="#E30613"
              height={50}
              width={50}
            ></Loader>
          </Grid>
        )}
        {week &&
          year &&
          weekSummary.length > 0 &&
          weekSummary.map((weekO) => (
            <Grid
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
              }}
              container
              direction="row"
              key={week + 'week-summary'}
            >
              <Grid item>{week}:</Grid>
              <Grid key={week + year + 'varaustilanne'} item>
                Varattu {weekO.limit - weekO.available}/{weekO.limit}
              </Grid>
            </Grid>
          ))}
        {daySummary.length > 0 &&
          daySummary.map((day) => (
            <Grid
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
              }}
              container
              direction="row"
              key={day.name}
            >
              <Grid item>{day.name}:</Grid>
              {day.summary.length > 0 ? (
                day.summary.map((item, i) => (
                  <Grid key={i + day.name} item>
                    Varattu {item.limit - item.available}/{item.limit}
                  </Grid>
                ))
              ) : (
                <Grid item>Ei varauksia</Grid>
              )}
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

export default SummaryHoverInfo;
