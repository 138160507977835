import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import { acceptCampaignOffer } from '../../lib/use-cases';
import LoadingSpinner from '../loader';

interface OfferApproveProps {
  open: boolean;
  setOpen: Function;
  id: string;
  customer: string;
  campaign: string;
}
const useStyles = makeStyles({
  title: {
    fontWeight: 'bold',
    fontSize: '20px',
  },
  content: {
    fontSize: '16px',
  },
  contentBold: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginRight: '4px',
  },
  dialog: {
    bottom: '31%',
    left: '30%',
  },
  decline: {
    backgroundColor: '#707070',
    boxShadow: ' 0px 1px 3px #00000033',
    '&:hover': {
      backgroundColor: 'rgba(112, 112, 112,0.9)',
    },
  },
  approve: {
    backgroundColor: '#5E8EFC',
    '&:hover': {
      backgroundColor: 'rgba(94, 142, 252,0.9)',
    },
  },
});
const OfferApproveModal = ({
  open,
  setOpen,
  id,
  customer,
  campaign,
}: OfferApproveProps) => {
  const [accepting, setAccepting] = useState<boolean>(false);
  const classes = useStyles();
  const handleCancel = () => {
    setOpen(false);
  };

  const handleApprove = async () => {
    setAccepting(true);
    await acceptCampaignOffer(id, setAccepting);
    setAccepting(false);

    setOpen(false);
  };

  return (
    <Dialog classes={{ paper: classes.dialog }} hideBackdrop open={open}>
      <DialogTitle>
        <Typography className={classes.title}>Oletko varma?</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography className={classes.content}>
          Valintasi odottaa hyväksynnän varmistusta
        </Typography>
        <Grid container direction="row">
          <Typography className={classes.contentBold}>{customer}:</Typography>
          <Typography className={classes.content}>{campaign} </Typography>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className={classes.decline}
          onClick={handleCancel}
          color="primary"
        >
          Peruuta
        </Button>
        <Button
          name="approve"
          onClick={handleApprove}
          color="primary"
          className={classes.approve}
          autoFocus
          variant="contained"
        >
          Hyväksy
        </Button>
        <LoadingSpinner showSpinner={accepting}></LoadingSpinner>
      </DialogActions>
    </Dialog>
  );
};

export default OfferApproveModal;
