import { redirectTo, RouteComponentProps } from '@reach/router';
import React, { useEffect } from 'react';
import { FileUpload, Layout } from '../components';
import { getToken } from '../lib/storage-util';

const FileUploadView: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    document.title = 'Sales Mediamyynti - New Order';
    const token = getToken();
    if (!token) {
      redirectTo('/');
    }
  }, []);

  return (
    <Layout>
      <FileUpload></FileUpload>
    </Layout>
  );
};
export default FileUploadView;
