import {
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import React, { ReactNode, useEffect, useState } from 'react';
import CampaignHeaderColumn from '../components/campaign-list/campaign-header-column';
import LoadingSpinner from '../components/loader';
import {
  ProviderInterface,
  ReservationsPageItemInterface
} from '../interfaces';
import campaignClient from '../lib/campaign-client';
import productClient from '../lib/product-client';
import reservationClient from '../lib/reservation-client';
const useStyles = makeStyles(() => ({
  grid: {
    width: '100%',
    margin: '0 auto',
  },

  gridContainer: {
    'grid-auto-flow': 'row',
    alignItems: 'flex-start',
    margin: '0',
    width: '100%',
  },
  mainContainer: {
    'grid-auto-flow': 'row',
    alignItems: 'flex-start',
    margin: '0',
    width: '100%',
    paddingTop: '70px',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '70px',
  },
  title: {
    fontSize: '34px',
    letterSpacing: '0.24px',
    lineHeight: '40px',
    fontWeight: 400,
    color: '#000000DE',
    'margin-bottom': '25px',
  },
  table: {
    minWidth: 1200,
  },
  headerCell: {},
  labelActive: { color: '#FFFFFF !important', opacity: '1 !important' },
  icon: {
    display: 'none ',
  },
  headerLabel: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    letterSpacing: '0.15px',
    fontSize: '16px',
    '&:hover': {
      color: '#FFFFFF',
      opacity: 1,
    },
    '&:focus': {
      color: '#FFFFFF',
      opacity: 1,
    },
  },
  headerRow: {
    backgroundColor: '#000000',
  },
  labelContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sortLabel: {
    width: '100%',
  },
  HeaderCell: {
    minWidth: '100px',
  },
  select: {
    width: '200px',
    margin: 'auto',
  },
}));

type Order = 'desc' | 'asc' | undefined;

type OrderBy =
  | 'created'
  | 'username'
  | 'reservationCount'
  | 'campaignName'
  | 'reservationDate'
  | 'campaignNumber'
  | undefined;

interface Option {
  label: string;
  value: string;
}
const ReservationsListContainer: React.FC = () => {
  const [filteredCampaigns, setFiltered] =
    useState<(ReservationsPageItemInterface | undefined)[]>();
  const [order, setOrder] = React.useState<Order>('desc');
  const [fetching, setFetching] = useState(false);
  const [orderBy, setOrderBy] = React.useState<OrderBy>('created');

  const [productProvider, setProvider] = useState<ProviderInterface>();
  const [variation, setVariation] = useState<string>();
  const [reservations, setReservations] = useState<
    ReservationsPageItemInterface[]
  >([]);
  const [date, setDate] = useState<MaterialUiPickersDate>(null);
  const classes = useStyles();

  useEffect(() => {
    const getProvider = async (): Promise<void> => {
      const provider = await productClient.getProvider(
        // eslint-disable-next-line no-undef
        process.env.REACT_APP_PROVIDER_UUID || ''
      );
      if (provider && provider.id && provider.name && provider.products)
        setProvider({
          id: provider.id,
          name: provider.name,
          products: provider.products,
        });
    };
    getProvider();
  }, []);

  useEffect(() => {
    setFetching(true);
    const getReservations = async (): Promise<void> => {
      if (!date || !variation) {
        setFetching(false);
        return;
      }
      const start = new Date(date.format('YYYY-MM-DD'));

      const reservations =
        await reservationClient.getReservationsByDateAndVariation(
          start,
          variation
        );
      const flatReservations = [
        ...reservations.days,
        ...reservations.months,
        ...reservations.weeks,
      ];
      Promise.all(
        flatReservations.map(async (reservation) => {
          const campaign = await campaignClient.getCampaign(
            reservation.campaignId
          );
          if (!campaign) return reservation;
          return {
            ...reservation,
            username: campaign.user ? campaign.user.name : '-',
            campaignName: `${campaign.campaignName} - työnumero: ${campaign.jobNumber}`,
            customerName: campaign.customer.customerName,
            campaignNumber: campaign.campaignNumber,
          };
        })
      ).then((data) => {
        setReservations(data);
        setFetching(false);
      });
    };
    getReservations();
  }, [date, variation]);

  const handleSort = (property: OrderBy, orderStyle: Order): void => {
    if (!reservations) {
      return;
    }
    const sortCampaigns = reservations.sort((a: any, b: any) => {
      if (a && b) {
        if (property) {
          if (property === 'reservationCount') {
            return b.quantity - a.quantity;
          }
          if (property === 'created') {
            return (
              new Date(a.created).getTime() - new Date(b.created).getTime()
            );
          }

          return ('' + b[property]).localeCompare('' + a[property]);
        }
      }
      return 0;
    });
    const isDesc = orderStyle === 'desc';
    if (isDesc) sortCampaigns.reverse();
    setReservations(sortCampaigns);
  };

  const handleRequestSort = async (property: OrderBy): Promise<void> => {
    const isDesc = order === 'desc';
    const newOrder = isDesc ? 'asc' : 'desc';
    setOrder(newOrder);
    handleSort(property, newOrder);
    setOrderBy(property);
  };

  useEffect(() => {
    if (orderBy) handleSort(orderBy, order);
    setFiltered(reservations);
  }, [reservations]);

  const createSortHandler = (property: OrderBy) => (): void => {
    handleRequestSort(property);
  };

  if (!productProvider) return null;
  const options = productProvider.products
    .map((p) => p.variations)
    .flat()
    .map((variation) => {
      return { label: variation?.name || '', value: variation?.id || '' };
    });

  const dateChange = (date: MaterialUiPickersDate): void => {
    setDate(date);
  };

  return (
    <Grid container spacing={2} className={classes.mainContainer}>
      <Grid item container className={classes.grid}>
        <Typography className={classes.title}>
          Päiväkohtaiset tilaukset
        </Typography>
        <Grid
          container
          direction="row"
          justify="space-evenly"
          style={{ marginBottom: '30px' }}
        >
          <Grid item>
            <TextField
              select
              label="Tuote"
              name={'variation'}
              onChange={(e): void => setVariation(e.target.value)}
              value={variation}
              fullWidth={true}
              className={classes.select}
            >
              {options.map((variation: Option, index: number): ReactNode => {
                return (
                  <MenuItem
                    key={`${variation.value}-${index}`}
                    value={variation.value}
                  >
                    <Typography>{variation.label}</Typography>
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item>
            <DatePicker
              label="Valitse päivä"
              onChange={dateChange}
              value={date}
            ></DatePicker>
          </Grid>
        </Grid>
        <Grid item container spacing={2} className={classes.gridContainer}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow className={classes.headerRow}>
                  <CampaignHeaderColumn
                    label="Varaus luotu"
                    sortImage="created"
                    sortHandler={createSortHandler('created')}
                    order={order}
                    orderBy={orderBy}
                  />{' '}
                  <CampaignHeaderColumn
                    label="Tilausnumero"
                    sortImage="campaignNumber"
                    sortHandler={createSortHandler('campaignNumber')}
                    order={order}
                    orderBy={orderBy}
                  />{' '}
                  <CampaignHeaderColumn
                    label="Varattu määrä"
                    sortImage="reservationCount"
                    sortHandler={createSortHandler('reservationCount')}
                    order={order}
                    orderBy={orderBy}
                  />{' '}
                  <CampaignHeaderColumn
                    label="Asiakas"
                    sortImage="reservationDate"
                    sortHandler={createSortHandler('reservationDate')}
                    order={order}
                    orderBy={orderBy}
                  />{' '}
                  <CampaignHeaderColumn
                    label="Kampanja"
                    sortImage="campaignName"
                    sortHandler={createSortHandler('campaignName')}
                    order={order}
                    orderBy={orderBy}
                  />
                  <CampaignHeaderColumn
                    label="Myyjä"
                    sortImage="username"
                    sortHandler={createSortHandler('username')}
                    order={order}
                    orderBy={orderBy}
                  />
                  {/* <TableCell align="center">Materiaali</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCampaigns &&
                  filteredCampaigns.map((reservation) => {
                    if (!reservation) return <></>;
                    return (
                      <TableRow key={reservation.id}>
                        <TableCell>{`${new Date(
                          reservation.created || ''
                        ).toLocaleDateString('de-De')} ${new Date(
                          reservation.created || ''
                        ).toLocaleTimeString('de-De')}`}</TableCell>
                        <TableCell>
                          {reservation.campaignNumber
                            ? reservation.campaignNumber
                            : '-'}
                        </TableCell>
                        <TableCell>{reservation.quantity}</TableCell>
                        <TableCell>{reservation.customerName}</TableCell>
                        <TableCell>{reservation.campaignName}</TableCell>
                        <TableCell>{reservation.username}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <LoadingSpinner showSpinner={fetching}></LoadingSpinner>
    </Grid>
  );
};

export default ReservationsListContainer;
