/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  makeStyles,
  Step,
  StepConnector,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  step: {
    height: '35px',
    width: '35px',

    '&$completed': {
      color: '#5E8EFC',
    },
    '&$active': {
      color: '#5E8EFC',
    },
    '&$disabled': {
      color: 'grey',
    },
    display: 'none',
  },
  active: {},
  completed: {},
  stepContent: {
    borderLeft: '0px',
    marginTop: '44px',
  },
  stepTitle: {
    fontSize: '34px',
    letterSpacing: '0.24px',
    lineHeight: '40px',
    fontWeight: 400,
    color: '#000000DE',
    marginLeft: '50px',
  },
  stepper: {
    marginTop: '55px',
    marginLeft: '150px',
    padding: '0px',
    paddingBottom: '24px',
    paddingRight: '24px',
  },
}));

interface Props {
  items: any[];
}

const OrderStepper: React.FC<Props> = ({ items }: Props) => {
  const classes = useStyles();

  return (
    <Stepper
      className={classes.stepper}
      connector={
        <StepConnector classes={{ line: classes.stepContent }}></StepConnector>
      }
      activeStep={0}
      orientation="vertical"
    >
      {items.map((item) => (
        <Step
          key={'step' + item.id}
          ref={item.ref}
          id={item.id}
          expanded={true}
        >
          {item.title && (
            <StepLabel
              StepIconProps={{
                classes: {
                  root: classes.step,
                  completed: classes.completed,
                  active: classes.active,
                },
              }}
            >
              <Typography className={classes.stepTitle}>
                {item.title}
              </Typography>
            </StepLabel>
          )}
          {/* mahdollisesti optionaali tuo class */}
          <StepContent className={item.class ? classes.stepContent : ''}>
            {item.content}
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export default OrderStepper;
