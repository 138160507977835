import { Button, Dialog, DialogContent, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import OfferEditorContainer from '../../containers/offer-editor-container';
import OfferEmailContainer from '../../containers/offer-email-container';
import { useAddOrderState } from '../../context/add-order-context';
import { useOrderFormState } from '../../context/order-form-context';
import {
  CampaignInterface,
  CustomerCampaign,
  MultipressCalculatedPrice,
  OrderDetails,
  OrderSummary,
} from '../../interfaces';
import { addCampaignOffer } from '../../lib/use-cases';
import LoadingSpinner from '../loader';

const useStyles = makeStyles(() => ({
  paper: {
    minWidth: '95vw',
    minHeight: '70vh',
    maxHeight: '92vh',
  },
  content: { margin: '10px' },
  title: { textAlign: 'center' },
  sendButton: {
    backgroundColor: '#5E8EFC',
  },
  cancelButton: {
    backgroundColor: '#FF6F75',
  },
}));

interface EditorModalProps {
  modalOpen: boolean;
  onClickModal: () => void;
  orderDetails?: OrderDetails;
  campaign?: CustomerCampaign;
  orderSummary?: OrderSummary;
  price?: MultipressCalculatedPrice;
  setSending?: Function;
  campaignData?: CampaignInterface;
}

type Attachments = { filename: string; path: string }[];

const EditorModal: React.FC<EditorModalProps> = ({
  modalOpen,
  onClickModal,
  orderDetails,
  campaign,
  orderSummary,
  price,
  setSending,
  campaignData,
}: EditorModalProps) => {
  const classes = useStyles();
  const [text, setText] = useState('');
  const addOrderContext = useAddOrderState();
  const [spinner, setSpinner] = useState(false);
  const [materials, setMaterials] = useState<Attachments>([]);
  const [preview, setPreview] = useState(false);
  const [emailContext, setEmailContext] = useState();
  const orderFormState = useOrderFormState();

  const clickSend = (): void => {
    if (orderDetails && campaign && price && setSending && orderSummary) {
      const campaignCopy = { ...campaign };
      campaignCopy.offerText = text;

      addCampaignOffer(
        orderDetails,
        campaignCopy,
        {
          ...orderSummary,
          advancePayment: orderFormState.isMultiMonth ? !orderFormState.partialPayment : true,
        },
        price,
        setSending,
        orderFormState.batches,
        addOrderContext,
        materials,
        false,
        emailContext
      );
    }
  };

  const clickSendPdf = (): void => {
    if (orderDetails && campaign && price && setSending && orderSummary) {
      const campaignCopy = { ...campaign };
      campaignCopy.offerText = text;

      addCampaignOffer(
        orderDetails,
        campaignCopy,
        {
          ...orderSummary,
          advancePayment: orderFormState.isMultiMonth ? !orderFormState.partialPayment : true,
        },
        price,
        setSending,
        orderFormState.batches,
        addOrderContext,
        materials,
        true,
        emailContext
      );
    }
  };

  const clickNext = (): void => {
    setPreview(true);
  };

  useEffect(() => {
    if (campaignData && campaignData.attachments) setMaterials(campaignData.attachments);
  }, [campaignData]);

  const handleBack = (): void => {
    if (preview) {
      setPreview(false);
    } else {
      onClickModal();
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={modalOpen}
      onClose={(event, reason): void => {
        if (reason !== 'backdropClick') {
          onClickModal();
        }
        onClickModal();
      }}
    >
      <LoadingSpinner showSpinner={spinner}></LoadingSpinner>
      <DialogContent className={classes.content}>
        {!preview && (
          <OfferEditorContainer
            setSpinner={setSpinner}
            orderDetails={orderDetails}
            campaignData={campaignData}
            setText={setText}
          />
        )}
        <OfferEmailContainer
          campaign={campaign ? { ...campaign, offerText: text } : campaign}
          orderDetails={orderDetails}
          orderSummary={orderSummary}
          attachments={materials}
          render={preview}
          saveContext={setEmailContext}
        />
        <Grid container direction="row" justifyContent="space-between">
          <Button onClick={handleBack} variant="contained" color="secondary">
            takaisin
          </Button>
          {!preview && (
            <Button onClick={clickNext} variant="contained" color="primary">
              Seuraava
            </Button>
          )}
          {preview && (
            <>
              <Button onClick={clickSend} variant="contained" color="primary">
                lähetä tarjous
              </Button>
              <Button onClick={clickSendPdf} variant="contained" color="primary">
                tallenna ja lataa PDF-muodossa
              </Button>{' '}
            </>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default EditorModal;
