import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import StringInput from '../components/form/string-input';
import TextInput from '../components/form/text-input';
import { useAddOrderState } from '../context/add-order-context';
import { useOrderFormState } from '../context/order-form-context';
import { CustomerCampaign, OrderDetails, OrderSummary } from '../interfaces';
import campaignClient from '../lib/campaign-client';
import { parseCampaignInput } from '../types/campaign-input';
import { parseCampaignPriceInput } from '../types/campaign-price-input';
import { EmailContext } from '../types/email-context';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    minHeight: '100%',
    maxHeight: '700px',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  iframe: {
    width: '100%',
    minWidth: '100%',
    height: '100%',
    minHeight: '600px',
  },
  form: {
    padding: '2rem',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
    height: '100%',
    maxHeight: '600px',
    overflowY: 'scroll',
  },
  title: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
}));

type OfferEmailContainerProps = {
  campaign?: CustomerCampaign;
  orderDetails?: OrderDetails;
  orderSummary?: OrderSummary;
  attachments: { filename: string; path: string }[];
  render: boolean;
  saveContext: (x: any) => void;
};

const OfferEmailContainer: React.FC<OfferEmailContainerProps> = ({
  campaign,
  orderDetails,
  orderSummary,
  attachments,
  render,
  saveContext,
}: OfferEmailContainerProps) => {
  const classes = useStyles();
  const addOrderContext = useAddOrderState();
  const orderFormState = useOrderFormState();
  const [emailContext, setEmailContext] = useState<EmailContext>();
  const [emailHtml, setEmailHtml] = useState<string>();

  const getEmailContext = async (): Promise<void> => {
    if (orderDetails && campaign && orderSummary) {
      const { floatingOrder } = addOrderContext;
      const campaignInput = parseCampaignInput({
        ...campaign,
        campaignName: campaign.name,
        description: '',
        campaignDetails: orderDetails.campaignDetails,
        ...orderSummary,
        attachments,
        customer: campaign.customerId,
        offer: true,
        advancePayment: orderFormState.isMultiMonth ? !orderFormState.partialPayment : true,
        floating: floatingOrder ? floatingOrder.floating : false,
        floatingAmount: floatingOrder ? floatingOrder.floatingAmount : 1,
        floatingQuantity: floatingOrder ? floatingOrder.quantity : 5,
        floatingVariations: floatingOrder ? floatingOrder.variations.join(',') : '',
        floatingBatchName: floatingOrder ? floatingOrder.batchName : '',
      });

      const priceInput = parseCampaignPriceInput({
        campaign: 'preview',
        ...orderFormState.price,
      });

      const context = await campaignClient.generateEmailContext(campaignInput, priceInput);

      setEmailContext(context);
    }
  };

  const getEmailHtml = async (): Promise<void> => {
    if (!emailContext) return;

    const html = await campaignClient.generateEmailOfferHtml(emailContext);
    setEmailHtml(html);
  };

  useEffect(() => {
    if (!emailContext && render) getEmailContext();
    else {
      if (emailContext) {
        setEmailContext({
          ...emailContext,
          offerText: campaign?.offerText,
        });
      }
    }
  }, [render]);

  useEffect(() => {
    getEmailHtml();
    saveContext(emailContext);
  }, [emailContext]);

  const priceRowSchema = [
    {
      key: 'productName',
      type: 'string',
      label: 'Tuotteen nimi',
    },
    {
      key: 'rowPrice',
      type: 'string',
      label: 'Tuotteen hinta',
    },
  ];

  // const offerEmailFormSchema = [
  //   // {
  //   //   label: 'Tarjousteksti',
  //   //   key: 'offerText',
  //   //   type: 'textArea',
  //   // },
  //   // {
  //   //   label: 'Vapaa kenttä',
  //   //   key: 'freeText',
  //   //   type: 'text',
  //   // },
  //   // {
  //   //   label: 'Tarjousaika',
  //   //   key: 'orderTime',
  //   //   type: 'string',
  //   // },
  //   // {
  //   //   label: 'Viitenumero',
  //   //   key: 'reference',
  //   //   type: 'string',
  //   // },
  //   // {
  //   //   label: 'Tilaajan nimi',
  //   //   key: 'ordererName',
  //   //   type: 'string',
  //   // },
  //   // {
  //   //   label: 'Tilaajan y-tunnus',
  //   //   key: 'ordererBusinessID',
  //   //   type: 'string',
  //   // },
  //   // {
  //   //   label: 'Tilaajan laskutusosoite',
  //   //   key: 'ordererBillingAddress',
  //   //   type: 'string',
  //   // },
  //   // {
  //   //   label: 'Tilaajan yhteyshenkilön nimi',
  //   //   key: 'ordererContactName',
  //   //   type: 'string',
  //   // },
  //   // {
  //   //   label: 'Tilaajan yhteyshenkilön email',
  //   //   key: 'ordererContactEmail',
  //   //   type: 'string',
  //   // },
  //   // {
  //   //   label: 'Tuotteen nimi',
  //   //   key: 'productName',
  //   //   type: 'string',
  //   // },
  //   // {
  //   //   label: 'Aineiston suunnittelu teksti',
  //   //   key: 'costText',
  //   //   type: 'text',
  //   // },
  // ];

  const rest = [
    {
      label: 'Alennuksen määrä',
      key: 'discount',
      type: 'string',
    },
    {
      label: 'Alennusprosentti',
      key: 'discountPercent',
      type: 'string',
    },
    {
      label: 'Yhteensä Alv 0%',
      key: 'productPriceWithoutTaxes',
      type: 'string',
    },
    // {
    //   label: 'Tilauksen arvolisävero',
    //   key: 'productVAT',
    //   type: 'string',
    // },
    // {
    //   label: 'Tilauksen kokonaishinta',
    //   key: 'productTotal',
    //   type: 'string',
    // },
    {
      label: 'Mainostoimistoalennus',
      key: 'mediaDiscount',
      type: 'string',
    },
  ];

  if (!render) return null;
  return (
    <Grid item className={classes.container}>
      {emailHtml && <iframe className={classes.iframe} srcDoc={emailHtml}></iframe>}
      {emailContext && (
        <Grid className={classes.form}>
          {/* {offerEmailFormSchema.map((item) =>
            item.type === 'text' ? (
              <TextInput
                key={item.key}
                label={item.label}
                value={(emailContext as any)[item.key] ? (emailContext as any)[item.key] : ''}
                setValue={(newValue: string): void => {
                  setEmailContext({
                    ...emailContext,
                    [item.key]: newValue,
                  });
                }}
              />
            ) : (
              <StringInput
                key={item.key}
                label={item.label}
                value={(emailContext as any)[item.key] ? (emailContext as any)[item.key] : ''}
                setValue={(newValue: string): void => {
                  setEmailContext({
                    ...emailContext,
                    [item.key]: newValue,
                  });
                }}
              />
            )
          )} */}
          {emailContext.priceRows?.map((row, index) => (
            <>
              <div className={classes.title}>Tuoterivi {index + 1}</div>
              {priceRowSchema.map((pr) => (
                <StringInput
                  key={row.productId + pr.key}
                  label={pr.label}
                  value={(row as any)[pr.key] ? (row as any)[pr.key] : ''}
                  setValue={(newValue: string): void => {
                    const { priceRows = [] } = emailContext;
                    const newRows = [
                      ...priceRows.slice(0, index),
                      {
                        ...row,
                        [pr.key]: newValue,
                      },
                      ...(priceRows.length > index ? priceRows.slice(index + 1) : []),
                    ];
                    setEmailContext({
                      ...emailContext,
                      priceRows: newRows,
                    });
                  }}
                />
              ))}
            </>
          ))}
          {rest.map((item) =>
            item.type === 'text' ? (
              <TextInput
                key={item.key}
                label={item.label}
                value={(emailContext as any)[item.key] ? (emailContext as any)[item.key] : ''}
                setValue={(newValue: string): void => {
                  setEmailContext({
                    ...emailContext,
                    [item.key]: newValue,
                  });
                }}
              />
            ) : (
              <StringInput
                key={item.key}
                label={item.label}
                value={(emailContext as any)[item.key] ? (emailContext as any)[item.key] : ''}
                setValue={(newValue: string): void => {
                  setEmailContext({
                    ...emailContext,
                    [item.key]: newValue,
                  });
                }}
              />
            )
          )}
        </Grid>
      )}
    </Grid>
  );
};
export default OfferEmailContainer;
