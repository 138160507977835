import { TableCell, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, { useEffect, useState } from 'react';

interface Props {
  resolver: () => Promise<string> | string;
}

const useStyles = makeStyles(() => ({
  detaiValue: {
    fontSize: '16px',
    letterSpacing: '0.15px',
  },
}));

const CTableCell: React.FC<Props> = ({ resolver }: Props) => {
  const [content, setContent] = useState<string>();
  const classes = useStyles();

  useEffect(() => {
    const init = async (): Promise<void> => {
      const value = await resolver();
      setContent(value);
    };
    init();
  }, []);

  return (
    <TableCell align="left" className={classes.detaiValue}>
      <Typography>{content}</Typography>
    </TableCell>
  );
};

export default CTableCell;
