import { ReservationInput, ReservationInterface } from '../../interfaces';
import { OrderBatch } from '../../types/order-batch';
import reservationClient from '../reservation-client';

interface ReservationsRequest {
  product: string;
  campaign: string;
  batches: OrderBatch[];
}

const handleBatch = async (
  batch: OrderBatch,
  product: string,
  campaign: string
): Promise<ReservationInterface[]> => {
  if (!batch.dates) return [];
  const reservationData: ReservationInput[][] = batch.dates.map(
    (date: Date): ReservationInput[] => {
      const base = {
        date: date.toISOString().substring(0, 10),
        campaign,
        product,
        quantity: batch.quantity,
        rough: true,
        batch: batch.id,
      };
      const reservation = batch.variations.map((variation: string) => {
        return { ...base, variation };
      });
      return reservation;
    }
  );
  const reservations = reservationData.flat();
  const creations = reservations.map((reservation) =>
    reservationClient.addReservation({ ...reservation })
  );
  const result = await Promise.all(creations);
  return result;
};
const addReservations = async ({
  product,
  campaign,
  batches,
}: ReservationsRequest): Promise<ReservationInterface[]> => {
  const creations = batches.map((batch) =>
    handleBatch(batch, product, campaign)
  );
  const result = await Promise.all(creations);
  return result.flat();
};

export default addReservations;
