import {
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FormOutlinedInput, RadioButtonGroup } from '../..';
import VariationChooser from '../../../containers/variation-chooser-container';
import {
  useAddOrderDispatch,
  useAddOrderState,
} from '../../../context/add-order-context';
import { useOrderFormState } from '../../../context/order-form-context';
import { CampaignInterface, ProductInterface } from '../../../interfaces';
import { dayAd, weekAd } from '../../../lib/consts/radio-button-options';
import { OrderBatch } from '../../../types/order-batch';

const useStyles = makeStyles(() => ({
  errorText: {
    fontSize: '16px',
    letterSpacing: '0.25px',
    lineHeight: '16px',
    fontWeight: 400,
    color: '#E30312',
  },
  inputContainer: {
    justifyContent: 'space-between',
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
}));

interface FloatingProps {
  product?: ProductInterface;
  campaignData?: CampaignInterface;
}

const FloatingOrderChooser: React.FC<FloatingProps> = ({
  campaignData,
  product,
}: FloatingProps) => {
  const classes = useStyles();
  // muuta
  const [radioValue, setRadioValue] = useState('5');

  // naa kentat
  const [quantity, setQuantity] = useState<number>(5);
  const [floatingOrder, setFloatingOrder] = useState(false);
  const [variations, setVariations] = useState<string[]>([]);
  const [floatingAmount, setFloatingAmount] = useState(1);
  const [batchName, setBatchName] = useState<string>('');
  const [batchNameAuto, setBatchNameAuto] = useState<boolean>(true);

  const addOrderDispatch = useAddOrderDispatch();
  const addOrderState = useAddOrderState();
  const orderFormState = useOrderFormState();

  const autoName = (): string => {
    if (!product || !variations) return '';
    const names = variations
      .map((variation) => {
        if (product && product.variations) {
          const variationProd = product.variations.find((prodVar) => {
            return prodVar.id === variation;
          });
          if (variationProd) return variationProd.name;
        }
        return variation;
      })
      .sort((a, b) => {
        if (a > b) return 1;
        if (a < b) return -1;
        return 0;
      });
    return (
      `${product.name}`.charAt(0).toUpperCase() +
      `${product.name}, ${names.join(', ')}, ${
        product.limitType !== 'month' ? quantity + ' sek, ' : ''
      }${
        product.limitType !== 'month'
          ? product.limitType === 'week'
            ? 'viikkoa'
            : floatingAmount + ' päivää'
          : floatingAmount + ' kuukautta'
      }`.slice(1)
    );
  };

  const validateQuantity = (): string => {
    if (product && product.limitType === 'month') {
      if (quantity > 0) return '';
      return 'Virhe';
    }
    if (product && product.limitType === 'week') {
      if (quantity >= 10 && quantity % 10 === 0) return '';
      return 'Arvon tulee olla jaollinen kymmenellä';
    }
    if (quantity >= 5 && quantity % 5 === 0) return '';
    return 'Arvon tulee olla jaollinen viidellä';
  };

  const validateBatchName = (): string => {
    return batchName.length > 0 ? '' : 'Pakollinen kenttä';
  };

  const handleVariations = (variation: string, value: boolean): void => {
    // jos false poistetaan
    if (!value)
      setVariations((pvariations) =>
        pvariations.filter((v) => v !== variation)
      );
    else {
      setVariations((prevBatch) => [...prevBatch, variation]);
    }
  };

  const handleQuantity = (event: any, value: string): void => {
    const name = event.target.name;
    if (name === 'length') {
      setRadioValue(`${value}`);
      setQuantity(value !== 'other' ? +value : 0);
      return;
    }
    if (event.target) setQuantity(+event.target.value);
  };

  const getQuantity = (value: any): any => {
    const limitType = product?.limitType;
    if (addOrderState.floatingOrder && addOrderState.floatingOrder.floating) {
      const radioLengths = limitType === 'week' ? [10, 20] : [5, 10];
      if (radioLengths.includes(addOrderState.floatingOrder.quantity))
        return {
          name: value,
          value: `${addOrderState.floatingOrder.quantity}`,
          onChange: handleQuantity,
        };
      if (value === 'length')
        return {
          name: value,
          value: `other`,
          onChange: handleQuantity,
        };
      return {
        name: value,
        value: addOrderState.floatingOrder.quantity,
        onChange: handleQuantity,
      };
    }
    if (value === 'length')
      return { name: value, value: radioValue, onChange: handleQuantity };
    return { name: value, value: quantity, onChange: handleQuantity };
  };

  useEffect(() => {
    //if (campaignData) return;
    if (batchNameAuto && product) {
      const names = variations
        .map((variation) => {
          if (product && product.variations) {
            const variationProd = product.variations.find((prodVar) => {
              return prodVar.id === variation;
            });
            if (variationProd) return variationProd.name;
          }
          return variation;
        })
        .sort((a, b) => {
          if (a > b) return 1;
          if (a < b) return -1;
          return 0;
        });
      const name =
        `${product.name}`.charAt(0).toUpperCase() +
        `${product.name}, ${names.join(', ')}, ${
          product.limitType !== 'month' ? quantity + ' sek, ' : ''
        }${
          product.limitType !== 'month'
            ? product.limitType === 'week'
              ? 'viikkoa'
              : floatingAmount + ' ' + 'päivää'
            : floatingAmount + ' ' + 'kuukautta'
        }`.slice(1);
      if (name !== batchName) setBatchName(name);
    }
    addOrderDispatch({
      type: 'update',
      payload: {
        floatingOrder: {
          floating: floatingOrder,
          quantity,
          variations: variations,
          floatingAmount: floatingAmount,
          batchName,
        },
      },
    });
  }, [
    floatingAmount,
    floatingOrder,
    variations,
    quantity,
    batchNameAuto,
    batchName,
    product,
  ]);

  useEffect(() => {
    const { floatingOrder: order } = addOrderState;
    if (order && order.floating) {
      if (order.floatingAmount) {
        const unitAmount = orderFormState.batches.reduce(
          (cur: number, batch: OrderBatch): number => batch.unitAmount + cur,
          0
        );
        if (unitAmount > order.floatingAmount) {
          addOrderDispatch({
            type: 'update',
            payload: {
              floatingOrderError: 'Liikaa varauksia kelluvassa tilauksessa',
            },
          });
          return;
        }
        const errors = [];
        if (validateQuantity()) errors.push(validateQuantity());
        if (validateBatchName()) errors.push(validateBatchName());
        if (floatingAmount <= 0)
          errors.push('Ajanjaksojen määrä on oltava vähintän 1');
        //tahan ei saa menna viela
        addOrderDispatch({
          type: 'update',
          payload: {
            floatingOrderError: errors.join(','),
          },
        });
      }
    }
  }, [orderFormState.batches]);

  useEffect(() => {
    if (floatingOrder) {
      const errors = [];
      if (validateQuantity()) errors.push(validateQuantity());
      if (validateBatchName()) errors.push(validateBatchName());
      if (floatingAmount <= 0)
        errors.push('Ajanjaksojen määrä on oltava vähintän 1');
      addOrderDispatch({
        type: 'update',
        payload: {
          floatingOrderError: errors.join(','),
        },
      });
    }
  }, [
    floatingAmount,
    floatingOrder,
    variations,
    quantity,
    batchNameAuto,
    batchName,
  ]);

  useEffect(() => {
    if (campaignData?.floatingVariations) {
      setVariations(campaignData.floatingVariations.split(','));
    }
    if (campaignData) setFloatingOrder(!!campaignData.floating);
    if (campaignData?.floatingAmount)
      setFloatingAmount(campaignData.floatingAmount);
    if (campaignData?.floatingQuantity)
      setQuantity(campaignData.floatingQuantity);
    if (campaignData?.floatingBatchName) {
      const name = autoName();
      if (name !== campaignData.floatingBatchName) {
        setBatchNameAuto(false);
        setBatchName(campaignData.floatingBatchName);
      } else {
        setBatchNameAuto(true);
        setBatchName(campaignData.floatingBatchName);
      }
      return;
    }
  }, [campaignData]);

  useEffect(() => {
    if (product && product.variations) {
      if (product.limitType === 'month') {
        setQuantity(1);
      } else if (product.limitType === 'week') {
        setQuantity(10);
      } else {
        setQuantity(5);
      }
      if (product.variations.length === 1)
        setVariations(product.variations.map((vari) => vari.id));
    } else {
      if (variations.length > 0) setVariations([]);
    }
    if (autoName() === campaignData?.floatingBatchName) {
      setBatchNameAuto(true);
      setBatchName(campaignData.floatingBatchName);
    }
  }, [product]);

  if (!product) return <></>;

  return (
    <>
      {product && (
        <FormControl>
          <FormControlLabel
            labelPlacement="end"
            style={{ width: '80%', marginTop: '20px' }}
            label="Kelluva mainos"
            control={
              <Switch
                checked={floatingOrder}
                onChange={(): void => {
                  setFloatingOrder(!floatingOrder);
                }}
                color="primary"
              ></Switch>
            }
          ></FormControlLabel>
        </FormControl>
      )}
      {floatingOrder && (
        <>
          <TextField
            name="floatingAmount"
            onChange={(e): void => {
              setFloatingAmount(+e.target.value);
            }}
            onBlur={(e): void => {
              setFloatingAmount(Math.ceil(floatingAmount));
            }}
            value={addOrderState.floatingOrder?.floatingAmount}
            onFocus={(event): void => event.target.select()}
            type="number"
            style={{ marginTop: '20px' }}
            inputProps={{
              min: 1,
            }}
          ></TextField>
          <Typography
            style={{
              marginTop: '30px',
              marginLeft: '10px',
            }}
          >
            {product && product.limitType === 'month'
              ? 'Kuukautta'
              : product.limitType === 'week'
              ? 'Viikkoa'
              : 'Päivää'}
          </Typography>
        </>
      )}
      {floatingOrder && (
        <Grid>
          {product && product.variations && product?.variations?.length > 1 && (
            <VariationChooser
              variations={product?.variations}
              setFieldValue={handleVariations}
              touched={true}
              selectedVariations={variations}
              maxVariations={product?.maxVariations}
            ></VariationChooser>
          )}
          {product?.limitType === 'second' || product?.limitType === 'week' ? (
            <RadioButtonGroup
              getFieldProps={getQuantity}
              name="length"
              {...(product.limitType === 'second' ? dayAd : weekAd)}
              fieldError={validateQuantity()}
            ></RadioButtonGroup>
          ) : (
            product?.minOrderQuantity > 1 && (
              <FormOutlinedInput
                name="length"
                limitType={product?.limitType}
                setFieldValue={(name: string, value: string): void =>
                  setQuantity(Math.round(+value))
                }
                min={5}
              ></FormOutlinedInput>
            )
          )}
          <Grid
            container
            className={classes.inputContainer}
            alignItems="flex-end"
            item
            style={{ marginTop: '30px', marginBottom: '30px' }}
          >
            <Grid item>
              <Typography className={classes.title}>
                Tilausrivi koko tilaukseen
              </Typography>
            </Grid>
            <TextField
              style={{ width: '80%' }}
              name="batch-name"
              disabled={batchNameAuto}
              onChange={(e): void => {
                setBatchName(e.target.value);
              }}
              onBlur={(): void => {
                //jotin
                addOrderDispatch({
                  type: 'update',
                  payload: {
                    floatingOrder: {
                      floating: floatingOrder,
                      quantity,
                      variations: variations,
                      floatingAmount: floatingAmount,
                      batchName,
                    },
                  },
                });
              }}
              placeholder=""
              value={batchName}
            ></TextField>
            <Typography className={classes.error}>
              {validateBatchName()}
            </Typography>
            <FormControl>
              <FormControlLabel
                labelPlacement="end"
                style={{ width: '80%', marginTop: '20px' }}
                label="Anna tilausrivi käsin"
                control={
                  <Switch
                    checked={!batchNameAuto}
                    onChange={(): void => {
                      setBatchNameAuto(!batchNameAuto);
                    }}
                    color="primary"
                  ></Switch>
                }
              ></FormControlLabel>
            </FormControl>
          </Grid>
          {addOrderState.floatingOrder && addOrderState.floatingOrderError && (
            <div className={classes.errorText}>
              {addOrderState.floatingOrderError}
            </div>
          )}
        </Grid>
      )}
    </>
  );
};

export default FloatingOrderChooser;
