export type CampaignDetail = {
  key: string;
  value: string | {} | { [key: string]: boolean };
  settingId: string;
  label?: string;
};

const parseCampaignDetail = ({
  key,
  value,
  settingId,
  label,
}: Partial<CampaignDetail>): CampaignDetail => {
  if (key && settingId && typeof value !== 'undefined') {
    return {
      key,
      settingId,
      value,
      label,
    };
  }
  throw new Error('Missing fields for campaign detail');
};

export { parseCampaignDetail };
