import {
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import CampaignHeaderColumn from '../components/campaign-list/campaign-header-column';
import {
  CampaignInterface,
  ProviderInterface,
  ReportTableObj,
  ReservationDaysMonths,
} from '../interfaces';
import productClient from '../lib/product-client';
import { getDetailValue } from '../utils/campaign-detail-utils';
import { doubleDecimalString } from '../utils/string-utils';
const useStyles = makeStyles(() => ({
  grid: {
    width: '100%',
    margin: '0 auto',
  },

  gridContainer: {
    'grid-auto-flow': 'row',
    alignItems: 'flex-start',
    margin: '0',
    width: '100%',
  },
  mainContainer: {
    'grid-auto-flow': 'row',
    alignItems: 'flex-start',
    margin: '0',
    width: '100%',
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingBottom: '70px',
  },
  title: {
    fontSize: '34px',
    letterSpacing: '0.24px',
    lineHeight: '40px',
    fontWeight: 400,
    color: '#000000DE',
    marginBottom: '25px',
  },
  table: {
    minWidth: 1200,
  },
  headerCell: {},
  labelActive: { color: '#FFFFFF !important', opacity: '1 !important' },
  icon: {
    display: 'none ',
  },
  headerLabel: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    letterSpacing: '0.15px',
    fontSize: '16px',
    '&:hover': {
      color: '#FFFFFF',
      opacity: 1,
    },
    '&:focus': {
      color: '#FFFFFF',
      opacity: 1,
    },
  },
  headerRow: {
    backgroundColor: '#000000',
  },
  labelContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sortLabel: {
    width: '100%',
  },
  HeaderCell: {
    minWidth: '100px',
  },
  select: {
    width: '200px',
    margin: 'auto',
  },
}));

type Order = 'desc' | 'asc' | undefined;

type OrderBy =
  | 'created'
  | 'username'
  | 'reservationCount'
  | 'campaignName'
  | 'reservationDate'
  | 'campaignNumber'
  | 'gross'
  | 'net'
  | 'customerName'
  | 'product'
  | 'material'
  | undefined;

interface Option {
  label: string;
  value: string;
}

interface Props {
  campaigns?: CampaignInterface[];
  setTableCampaigns: (value: ReportTableObj[]) => void;
}

interface ProductInfo {
  count: { value: number; label: string };
  productString: string;
}

const ReportTableContainer: React.FC<Props> = ({
  campaigns,
  setTableCampaigns,
}: Props) => {
  const [tableArray, setTableArray] = useState<ReportTableObj[]>();
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<OrderBy>('created');
  const [provider, setProvider] = useState<ProviderInterface>();

  const classes = useStyles();
  useEffect(() => {
    const getProvider = async (): Promise<void> => {
      const providerRes = await productClient.getProvider(
        // eslint-disable-next-line no-undef
        process.env.REACT_APP_PROVIDER_UUID || ''
      );
      setProvider(providerRes);
    };
    getProvider();
  }, []);

  const handleSort = (
    property: OrderBy,
    orderStyle: Order,
    initialCampaigns?: ReportTableObj[]
  ): void => {
    const array = initialCampaigns ? initialCampaigns : tableArray;

    if (array) {
      let sortCampaigns = array.sort((a: any, b: any) => {
        if (a && b) {
          if (property) {
            if (property === 'reservationCount') {
              return ('' + b.count.label).localeCompare('' + a.count.label);
            }
            if (property === 'created') {
              return (
                new Date(a.created).getTime() - new Date(b.created).getTime()
              );
            }
            if (property === 'gross') {
              return b.gross - a.gross;
            }
            if (property === 'net') {
              return b.net - a.net;
            }
            if (property === 'customerName') {
              return ('' + b.customer).localeCompare('' + a.customer);
            }
            if (property === 'product') {
              return ('' + a.product).localeCompare('' + b.product);
            }
            return ('' + b[property]).localeCompare('' + a[property]);
          }
        }
        return 0;
      });

      const isDesc = orderStyle === 'desc';
      if (isDesc) sortCampaigns = [...sortCampaigns.reverse()];

      setTableArray(sortCampaigns);
    }
  };

  const handleRequestSort = async (property: OrderBy): Promise<void> => {
    const isDesc = order === 'desc';
    const newOrder = isDesc ? 'asc' : 'desc';
    setOrder(newOrder);
    handleSort(property, newOrder);
    setOrderBy(property);
  };

  const getCampaignProductInfo = (
    reservations: ReservationDaysMonths
  ): ProductInfo => {
    const productInfo: ProductInfo = {
      count: { value: 0, label: '' },
      productString: '',
    };

    if (reservations) {
      const { months, days, weeks } = reservations;

      if (days.length > 0) {
        if (provider) {
          const uniqueDays = [...new Set(days.map((day) => day.date))];
          const batches = [...new Set(days.map((day) => day.batch))];

          const product = provider.products.find(
            (product) => product.id === days[0].productId
          );
          if (product) {
            const productVariations = [
              ...new Set(days.map((day) => day.productVariationId)),
            ].map((variation) => {
              const foundVariation = product?.variations?.find(
                (item) => item.id === variation
              );
              if (foundVariation) return foundVariation.name;
              else return '';
            });

            const productString = `${product.name ? product.name : ''} (${
              product.variations
                ? product.variations.every((variation) =>
                    productVariations.includes(variation.name)
                  )
                  ? 'Koko päivä'
                  : productVariations.join(', ')
                : ''
            })`;
            productInfo.productString = productString;
            productInfo.count = {
              value: uniqueDays.length,
              label:
                uniqueDays.length > 0
                  ? `${uniqueDays.length} Päivää${
                      batches.length > 1 ? ` (${batches.length} jaksoa)` : ''
                    }`
                  : '-',
            };
          }
        }
      }
      if (months && months.length > 0) {
        if (provider) {
          const product = provider.products.find(
            (product) => product.id === months[0].productId
          );
          const batches = [...new Set(months.map((month) => month.batch))];
          if (product) {
            productInfo.productString = product.name;
            productInfo.count = {
              value: months.length,
              label:
                months.length > 0
                  ? `${months.length} Kuukautta${
                      batches.length > 1 ? ` (${batches.length} jaksoa)` : ''
                    }`
                  : '-',
            };
          }
        }
      }
      if (weeks.length > 0) {
        if (provider) {
          const product = provider.products.find(
            (product) => product.id === weeks[0].productId
          );
          const batches = [...new Set(weeks.map((week) => week.batch))];
          if (product) {
            productInfo.productString = product.name;
            productInfo.count = {
              value: weeks.length,
              label:
                weeks.length > 0
                  ? `${weeks.length} Viikkoa${
                      batches.length > 1 ? ` (${batches.length} jaksoa)` : ''
                    }`
                  : '-',
            };
          }
        }
      }
    }
    return productInfo;
  };

  const getFloatingProductInfo = (campaign: CampaignInterface): ProductInfo => {
    const { floatingAmount, floatingVariations } = campaign;
    const productInfo: ProductInfo = {
      count: { value: 0, label: '' },
      productString: '',
    };

    const variArray = floatingVariations?.split(',');

    const product = provider?.products.find((product) =>
      product.variations?.find((variation) => variArray?.includes(variation.id))
    );
    if (variArray && product) {
      const productVariations = variArray.map((variation) => {
        const foundVariation = product?.variations?.find(
          (item) => item.id === variation
        );
        if (foundVariation) return foundVariation.name;
        else return '';
      });
      productInfo.productString = `${product?.name}${
        product?.limitType === 'month' || product?.limitType === 'week'
          ? ''
          : ` (${
              product.variations
                ? product.variations.every((variation) =>
                    productVariations.includes(variation.name)
                  )
                  ? 'Koko päivä'
                  : productVariations.join(', ')
                : ''
            })`
      }`;
      if (floatingAmount) {
        productInfo.count = {
          value: floatingAmount,
          label: `${floatingAmount} ${
            product?.limitType === 'month'
              ? 'Kuukautta'
              : product?.limitType === 'week'
              ? 'Viikkoa'
              : 'Päivää'
          }`,
        };
      }
    }

    return productInfo;
  };

  const capitalizeString = (str: string): string => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const mapCampaigns = (campaigns: CampaignInterface[]): ReportTableObj[] => {
    return campaigns.map((campaign) => {
      const reservations: unknown = campaign.reservations;
      const productInfo = campaign.floating
        ? getFloatingProductInfo(campaign)
        : getCampaignProductInfo(reservations as ReservationDaysMonths);

      return {
        id: campaign.id,
        created: campaign.created,
        count: productInfo.count,
        product: capitalizeString(productInfo.productString),
        material: capitalizeString(
          getDetailValue(campaign.campaignDetails, 'aineisto')
        ),
        customer: campaign.customer.customerName,
        campaignName: campaign.campaignName,
        gross: doubleDecimalString(campaign.campaignPrice.listPrice),
        net: doubleDecimalString(
          campaign.campaignPrice.listPrice - campaign.campaignPrice.discount
        ),
        username: campaign.user.name,
      };
    });
  };

  useEffect(() => {
    if (campaigns) {
      const mappedCampaigns = mapCampaigns(campaigns);

      setTableCampaigns(mappedCampaigns);

      if (orderBy) handleSort(orderBy, order, mappedCampaigns);
      else setTableArray(mappedCampaigns);
    }
  }, [campaigns]);

  const createSortHandler = (property: OrderBy) => (): void => {
    handleRequestSort(property);
  };

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item container className={classes.grid}>
        <Typography className={classes.title}>
          Myyjien tekemät kampanjat
        </Typography>

        <Grid item container spacing={2} className={classes.gridContainer}>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow className={classes.headerRow}>
                  <CampaignHeaderColumn
                    label="Tilauspäivä"
                    sortImage="created"
                    sortHandler={createSortHandler('created')}
                    order={order}
                    orderBy={orderBy}
                  />{' '}
                  <CampaignHeaderColumn
                    label="Varattu määrä"
                    sortImage="reservationCount"
                    sortHandler={createSortHandler('reservationCount')}
                    order={order}
                    orderBy={orderBy}
                  />{' '}
                  <CampaignHeaderColumn
                    label="Tuote / tuotteet"
                    sortImage="product"
                    sortHandler={createSortHandler('product')}
                    order={order}
                    orderBy={orderBy}
                  />{' '}
                  <CampaignHeaderColumn
                    label="Valmis aineisto / tilaustyö"
                    sortImage="material"
                    sortHandler={createSortHandler('material')}
                    order={order}
                    orderBy={orderBy}
                  />{' '}
                  <CampaignHeaderColumn
                    label="Asiakas"
                    sortImage="customerName"
                    sortHandler={createSortHandler('customerName')}
                    order={order}
                    orderBy={orderBy}
                  />{' '}
                  <CampaignHeaderColumn
                    label="Kampanja"
                    sortImage="campaignName"
                    sortHandler={createSortHandler('campaignName')}
                    order={order}
                    orderBy={orderBy}
                  />{' '}
                  <CampaignHeaderColumn
                    label="Brutto"
                    sortImage="gross"
                    sortHandler={createSortHandler('gross')}
                    order={order}
                    orderBy={orderBy}
                  />
                  <CampaignHeaderColumn
                    label="Netto"
                    sortImage="net"
                    sortHandler={createSortHandler('net')}
                    order={order}
                    orderBy={orderBy}
                  />
                  <CampaignHeaderColumn
                    label="Myyjä"
                    sortImage="username"
                    sortHandler={createSortHandler('username')}
                    order={order}
                    orderBy={orderBy}
                  />
                  {/* <TableCell align="center">Materiaali</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableArray &&
                  tableArray.map((campaign) => {
                    if (!campaign) return <></>;
                    return (
                      <TableRow key={campaign.id}>
                        <TableCell>{`${new Date(
                          campaign.created || ''
                        ).toLocaleDateString('de-De')} ${new Date(
                          campaign.created || ''
                        ).toLocaleTimeString('de-De')}`}</TableCell>
                        <TableCell>{campaign.count.label}</TableCell>
                        <TableCell>{campaign.product}</TableCell>

                        <TableCell>{campaign.material}</TableCell>
                        <TableCell>{campaign.customer}</TableCell>

                        <TableCell>{campaign.campaignName}</TableCell>
                        <TableCell>{campaign.gross}€</TableCell>
                        <TableCell>{campaign.net}€</TableCell>

                        <TableCell>{campaign.username}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      {/* {fetching && <LoadingSpinner></LoadingSpinner>} */}
    </Grid>
  );
};

export default ReportTableContainer;
