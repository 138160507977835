import { Grid, makeStyles } from '@material-ui/core';
import { navigate } from '@reach/router';
import React, { useEffect } from 'react';
import Image from '../assets/login-background.png';
import { Layout } from '../components';
import { clearStorage } from '../lib/storage-util';
import { clearSession } from '../utils/session-utils';

const useStyles = makeStyles(() => ({
  loginContainer: {
    width: '100%',
    minHeight: '100%',
    backgroundImage: `url(${Image})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
}));

const Logout: React.FC = () => {
  const classes = useStyles();
  useEffect(() => {
    clearStorage();
    clearSession();
    navigate('/');
  }, []);

  return (
    <Layout>
      <Grid wrap="nowrap" className={classes.loginContainer} container></Grid>
    </Layout>
  );
};
export default Logout;
