import { ContentState, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import htmlToDraft from 'html-to-draftjs';
import React, { FC, useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useProviderState } from '../../context/provider-context';
import {
  CampaignInterface,
  OrderDetails,
  UserInterface,
} from '../../interfaces';
import { template } from './templates/template';

interface EditorProps {
  orderDetails?: OrderDetails;
  currentUser: UserInterface;
  setText: Function;
  campaignData?: CampaignInterface;
}

const TextEditor: FC<EditorProps> = ({
  currentUser,
  setText,
  campaignData,
}: EditorProps) => {
  const providerState = useProviderState();
  const blocksFromHtml = htmlToDraft(
    campaignData?.offerText
      ? campaignData.offerText
      : template({
          currentUser,
          week: providerState.product
            ? providerState.product.limitType === 'week'
            : false,
        })
  );
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(contentState)
  );

  const onEditorStateChange = (editorState: EditorState): void => {
    setEditorState(editorState);
  };

  useEffect(() => {
    const html = stateToHTML(editorState.getCurrentContent(), {});
    setText(html);
  }, [editorState]);

  return (
    <Editor
      editorState={editorState}
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      onEditorStateChange={onEditorStateChange}
      toolbar={{
        options: ['inline', 'blockType', 'link', 'list', 'history'],
        blockType: {
          options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
        },
        inline: {
          options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
      }}
    />
  );
};

export default TextEditor;
