import {
  ReservationMonthInput,
  ReservationMonthInterface,
} from '../../interfaces';
import { OrderBatch } from '../../types/order-batch';
import reservationClient from '../reservation-client';

interface Month {
  year: number;
  month: number;
}

interface ReservationsRequest {
  product: string;
  campaign: string;
  rough: boolean;
  batches: OrderBatch[];
}

//TODO paivita
const handleBatch = async (
  batch: OrderBatch,
  campaign: string,
  product: string,
  rough: boolean
): Promise<ReservationMonthInterface[]> => {
  if (!batch.months) return [];
  const reservationData: ReservationMonthInput[][] = batch.months.map(
    (month: Month): ReservationMonthInput[] => {
      const base = {
        year: month.year,
        month: month.month,
        campaign,
        product,
        quantity: batch.quantity,
        rough,
        batch: batch.id,
      };
      const reservation = batch.variations.map(
        (variation: string): ReservationMonthInput => {
          return { ...base, variation };
        }
      );
      return reservation;
    }
  );
  const reservations: ReservationMonthInput[] = reservationData.flat();
  const creations = reservations.map((reservation) =>
    reservationClient.addReservationMonth({ ...reservation })
  );
  const result = await Promise.all(creations);
  return result;
};

const addMonthReservations = async ({
  product,
  campaign,
  rough,
  batches,
}: ReservationsRequest): Promise<ReservationMonthInterface[]> => {
  const promises = batches.map(async (batch) =>
    handleBatch(batch, campaign, product, rough)
  );
  const reservations = await Promise.all(promises);
  return reservations.flat();
};

export default addMonthReservations;
