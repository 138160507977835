import { redirectTo, RouteComponentProps } from '@reach/router';
import React, { useEffect } from 'react';
import { Layout } from '../components';
import PlaylistComponent from '../components/playlist-component';
import { getToken } from '../lib/storage-util';

const Playlist: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    document.title = 'Sales Mediamyynti - Playlist';
    const token = getToken();
    if (!token) {
      redirectTo('/');
    }
  }, []);

  return (
    <Layout tab="/playlist">
      <PlaylistComponent></PlaylistComponent>
    </Layout>
  );
};
export default Playlist;
