// import Nav from './nav';
import { Grid } from '@material-ui/core';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './header';

interface LayoutProps {
  children: React.ReactNode;
  tab?: string;
}

const Layout: React.FC<LayoutProps> = ({ children, tab }: LayoutProps) => {
  return (
    <Grid container wrap="nowrap" direction="column" style={{ height: '100%' }}>
      <Grid item>
        <Header tab={tab}></Header>
      </Grid>

      <Grid item style={{ height: '100%' }}>
        <ToastContainer />

        <Grid item>{/* <Nav /> */}</Grid>
        {children}
      </Grid>
    </Grid>
  );
};

export default Layout;
