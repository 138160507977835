// import Nav from './nav';
import { Button, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

interface WeekProps {
  label: string;
  style: {
    color: string;
    disabled: boolean;
    fontColor: string;
  };
  onClick: Function;
  id: string;
  year: number;
  week: number;
}

interface StyleProps {
  [key: string]: string;
}

const useStyles = makeStyles(() => ({
  container: {
    width: '75px',
    border: '4px solid  #FBFBFC',
    height: '48px',
    justifyContent: 'center',
    backgroundColor: (props: StyleProps) =>
      `rgba(${props.color},${props.opacity})`,
    '&:hover': {
      backgroundColor: (props: StyleProps) => `rgba(94, 142, 252,0.7)`,
    },
  },
  text: {
    textAlign: 'center',
    color: (props: StyleProps) => props.fontColor,
    fontSize: '12px',
    opacity: '1 !important',
  },
}));

const Week: React.FC<WeekProps> = ({
  label,
  style,
  onClick,
  id,
  year,
  week,
}: WeekProps) => {
  const styleProps = {
    color: style.color,
    fontColor: style.fontColor,
    opacity: style.disabled
      ? style.color === '255, 111, 117'
        ? '1'
        : '0.6'
      : '1',
  };
  const classes = useStyles(styleProps);
  return (
    <Button
      key={id}
      disabled={style.disabled}
      onClick={(e) => onClick(e, year, week)}
      id={id}
      className={classes.container}
    >
      <Typography className={classes.text}>{label}</Typography>
    </Button>
  );
};

export default Week;
