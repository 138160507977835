import React from 'react';
import SummaryForm from '../components/order-summary-form';
import {
  CampaignDetailInterface,
  CampaignInterface,
  ProductSettingInterface,
} from '../interfaces';
interface SummaryProps {
  handleSubmit: Function;
  submitting: boolean;
  summaryTypeFormat?: {
    settings: ProductSettingInterface[];
    settingValues: CampaignDetailInterface[];
    type: string;
  };
  campaignData?: CampaignInterface;
  setErrors: (value: boolean) => void;
}
const OrderSummaryContainer: React.FC<SummaryProps> = ({
  handleSubmit,
  submitting,
  summaryTypeFormat,
  campaignData,
  setErrors,
}: SummaryProps) => {
  return (
    <SummaryForm
      submitting={submitting}
      handleSubmit={handleSubmit}
      summaryTypeFormat={summaryTypeFormat}
      campaignData={campaignData}
      setErrors={setErrors}
    ></SummaryForm>
  );
};
export default OrderSummaryContainer;
