import { Grid, IconButton } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react';
import SchemaFields from './add-customer/add-customer-schema-fields';

interface Field {
  name: string;
  label: string;
  type: string;
  placeHolder: string;
  fullWidth: boolean;
  required: boolean;
}
interface Input {
  name: string;
  label: string;
  type: string;
  fields: Field[];
}

interface InputArrayProps {
  input: Input;
  handleChange: any;
  setFieldValue: Function;
  formikValues?: string | any;
  formik?: any;
  formikErrors: any;
  touched: any;
  updating: boolean;
}

const InputArray: React.FC<InputArrayProps> = ({
  input,
  handleChange,
  setFieldValue,
  formikValues,
  formikErrors,
  formik,
  touched,
  updating,
}: InputArrayProps) => {
  const [dataArray, setDataArray] = useState<any>([]);

  useEffect(() => {
    setDataArray(formikValues);
  }, [formikValues]);

  const addContact = (i: number): void => {
    setFieldValue(`${input.name}.${i}`, {
      firstname: '',
      lastname: '',
      email: '',
      phone: '',
      department: '',
    });
  };

  const onClickRemove = async (i: number): Promise<void> => {
    const array = [...dataArray];

    array.splice(i, 1);
    setFieldValue(input.name, array);
  };

  return (
    <Grid item container>
      {dataArray.map((item: any, i: any) => {
        return (
          <Grid
            style={{ marginBottom: '20px' }}
            item
            container
            spacing={2}
            key={input.name + i}
          >
            {i > 0 && (
              <Grid justify="flex-end" item container>
                {' '}
                {!updating && (
                  <IconButton
                    style={{
                      color: '#E30312',
                      fontSize: '12px',
                    }}
                    onClick={(): Promise<void> => onClickRemove(i)}
                  >
                    Poista henkilö
                  </IconButton>
                )}
              </Grid>
            )}
            {input.fields.map((field, j) => {
              const errors = formikErrors[input.name];
              const inputErrors = errors && errors[i] ? errors[i] : {};
              return (
                <Grid
                  key={field.name + i}
                  item
                  style={{ width: field.fullWidth ? '100%' : '50%' }}
                >
                  <SchemaFields
                    key={field.name + j}
                    input={field}
                    handleChange={handleChange}
                    formikErrors={inputErrors}
                    formik={formik}
                    values={item}
                    touched={touched}
                    inputArrayName={`${input.name}.${i}.${field.name}`}
                  ></SchemaFields>
                </Grid>
              );
            })}
            <Grid item container justify="space-between">
              {i === dataArray.length - 1 && !updating && (
                <IconButton
                  disableFocusRipple
                  disableRipple
                  style={{
                    color: '#5E8EFC',
                    marginTop: '20px',
                  }}
                  onClick={(): void => addContact(i + 1)}
                >
                  <Add />
                  Lisää uusi yhteyshenkilö
                </IconButton>
              )}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default InputArray;
