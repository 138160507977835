import gql from 'graphql-tag';
import {
  NewUserInterface,
  OrganizationInterface,
  ResetPasswordInput,
  UserInterface,
} from '../interfaces';
import Client from './apollo-client';

class UserClient {
  private client: Client;
  constructor() {
    // eslint-disable-next-line no-undef
    const url = process.env.REACT_APP_USER_SERVICE_URL || '';
    this.client = new Client(url);
  }

  getToken = async (email: string, password: string): Promise<Partial<UserInterface>> => {
    try {
      const GET_TOKEN = gql`
        mutation getToken($password: String!, $email: String!) {
          getToken(user: { email: $email, password: $password }) {
            token
            id
          }
        }
      `;
      const result = await this.client.mutate(GET_TOKEN, { email, password }, false);
      return result.data.getToken as Partial<UserInterface>;
    } catch (error) {
      throw new Error('login failed');
    }
  };

  getUser = async (id: string): Promise<UserInterface> => {
    try {
      const FIND_USER = gql`
        query findUser($id: String!) {
          user(id: $id) {
            id
            email
            name
            phone
            admin
            organization {
              name
              id
              restrictedProducts
              restrictedPaths
            }
          }
        }
      `;
      const result = await this.client.query(FIND_USER, { id });
      return result.data.user as UserInterface;
    } catch (error) {
      throw new Error(error);
    }
  };

  getOrganizationUsers = async (id: string): Promise<UserInterface[]> => {
    try {
      const FIND_USERS_BY_ORGANIZATION = gql`
        query usersByOrganization($id: String!) {
          usersByOrganization(id: $id) {
            id
            email
            name
            phone
            organization {
              name
              id
            }
          }
        }
      `;
      const result = await this.client.query(FIND_USERS_BY_ORGANIZATION, {
        id,
      });
      return result.data.usersByOrganization as UserInterface[];
    } catch (error) {
      throw new Error(error);
    }
  };

  getUsers = async (): Promise<UserInterface[]> => {
    try {
      const USERS = gql`
        query listUsers {
          users {
            id
            email
            name
            phone
            organization {
              name
              id
            }
          }
        }
      `;
      const result = await this.client.query(USERS);
      return result.data.users as UserInterface[];
    } catch (error) {
      throw new Error(error);
    }
  };

  getOrganizations = async (): Promise<OrganizationInterface[]> => {
    try {
      const LIST_ORGANIZATIONS = gql`
        query listOrganizations {
          organizations {
            id
            name
          }
        }
      `;
      const result = await this.client.query(LIST_ORGANIZATIONS);
      return result.data.organizations as OrganizationInterface[];
    } catch (error) {
      throw new Error(error);
    }
  };

  addOrganization = async ({ name }: OrganizationInterface): Promise<OrganizationInterface> => {
    try {
      const ADD_ORGANIZATION = gql`
        mutation addOrganization($name: String!) {
          addOrganization(organization: { name: $name }) {
            id
            name
          }
        }
      `;
      const result = await this.client.mutate(ADD_ORGANIZATION, { name });
      return result.data.addOrganization as OrganizationInterface;
    } catch (error) {
      throw new Error(error);
    }
  };

  addUser = async ({
    email,
    password,
    organization,
    name,
    phone,
  }: NewUserInterface): Promise<NewUserInterface> => {
    try {
      const ADD_USER = gql`
        mutation addUser(
          $email: String!
          $password: String!
          $organization: ID!
          $name: String!
          $phone: String!
        ) {
          addUser(
            user: {
              email: $email
              password: $password
              organization: $organization
              name: $name
              phone: $phone
            }
          ) {
            id
            email
            password
            name
            phone
            organization {
              id
              name
            }
          }
        }
      `;
      const result = await this.client.mutate(ADD_USER, {
        email,
        password,
        organization,
        name,
        phone,
      });
      return result.data.addUser as NewUserInterface;
    } catch (error) {
      throw new Error(error);
    }
  };

  addToken = async (email: string): Promise<void> => {
    try {
      const ADD_TOKEN = gql`
        mutation addToken($email: String!) {
          addToken(user: $email)
        }
      `;
      await this.client.mutate(ADD_TOKEN, { email }, false);
    } catch (error) {
      throw new Error(error);
    }
  };

  resetPassword = async ({ token, password }: ResetPasswordInput): Promise<NewUserInterface> => {
    try {
      const RESET_PASSWORD = gql`
        mutation resetPassword($token: String!, $password: String!) {
          resetPassword(resetPassword: { token: $token, password: $password }) {
            id
            email
            password
            name
            organization {
              id
              name
            }
          }
        }
      `;
      const result = await this.client.mutate(
        RESET_PASSWORD,
        {
          token,
          password,
        },
        false
      );
      return result.data.resetPassword as NewUserInterface;
    } catch (error) {
      throw new Error(error);
    }
  };

  isValidResetToken = async (token: string): Promise<boolean> => {
    try {
      const IS_VALID_TOKEN = gql`
        query isValidToken($token: String!) {
          isValidToken(token: $token)
        }
      `;
      const result = await this.client.query(IS_VALID_TOKEN, { token }, false);
      return result.data.isValidToken as boolean;
    } catch (error) {
      throw new Error(error);
    }
  };
}
export default new UserClient();
