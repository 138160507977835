import { redirectTo, RouteComponentProps } from '@reach/router';
import React, { useEffect } from 'react';
import { Layout } from '../components';
import OrderContainer from '../containers/order-container';
import { AddOrderProvider } from '../context/add-order-context';
import { OrderFormProvider } from '../context/order-form-context';
import { ProviderProvider } from '../context/provider-context';
import { getToken } from '../lib/storage-util';

const OrderTemplate: React.FC<RouteComponentProps> = ({ location }: RouteComponentProps) => {
  useEffect(() => {
    document.title = 'Sales Mediamyynti - New Offer';
    const token = getToken();
    if (!token) {
      redirectTo('/');
    }
  }, []);
  const { state = { singleCampaign: null } } = location as any;
  const campaign = state.singleCampaign;
  return (
    <Layout tab="/order">
      <OrderFormProvider>
        <ProviderProvider>
          <AddOrderProvider>
            <OrderContainer campaignData={campaign} approveOffer={true} />
          </AddOrderProvider>
        </ProviderProvider>
      </OrderFormProvider>
    </Layout>
  );
};
export default OrderTemplate;
