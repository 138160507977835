import {
  FormControl,
  FormControlLabel,
  Input,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '390px',
    backgroundColor: '#B1B8C00D',
    border: '1px solid #B1B8C026',
    padding: '20px',
    margin: '12.5px',
  },
  radio: {
    '&$checked': {
      color: '#5E8EFC',
    },
  },
  checked: {},
  formGroup: {
    padding: '14px',
  },
  title: {
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '19px',
    fontWeight: 'bold',
    color: '#000000',
  },
  text: {
    fontSize: '14px',
    letterSpacing: '0.25px',
    lineHeight: '16px',
    fontWeight: 400,
    color: '#000000DE',
  },
  errorText: {
    fontSize: '12px',
    letterSpacing: '0.25px',
    lineHeight: '16px',
    fontWeight: 400,
    color: '#E30312',
  },
}));

interface OtherInputProps {
  name?: string;
  type: string;
  min?: number;
  max?: number;
  step?: string;
  adornment?: string;
}

interface RadioProps {
  getFieldProps: Function;
  title: string;
  options: { label: string; value: string }[];
  otherInput?: OtherInputProps;
  fieldError?: string;
  name: string;
  disabled?: boolean;
}

const RadioButtonGroup: React.FC<RadioProps> = ({
  getFieldProps,
  name,
  otherInput,
  options,
  title,
  fieldError,
  disabled = false,
}: RadioProps) => {
  const classes = useStyles();
  const field = getFieldProps(name);
  const otherField = otherInput ? getFieldProps(otherInput.name) : null;

  return (
    <FormControl className={classes.formControl}>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.errorText}>
        {fieldError ? fieldError : null}
      </Typography>

      <RadioGroup
        name={field.name}
        value={field.value || ''}
        onChange={field.onChange}
        className={classes.formGroup}
      >
        {options.map((item) => {
          return (
            <FormControlLabel
              key={item.value}
              value={item.value.toString()}
              className={classes.text}
              control={
                <Radio
                  disabled={disabled}
                  classes={{
                    root: classes.radio,
                    checked: classes.checked,
                    colorSecondary: classes.radio,
                  }}
                />
              }
              label={item.label}
            ></FormControlLabel>
          );
        })}
      </RadioGroup>
      {field.value === 'other' && (
        <Input
          name={otherField.name}
          value={otherField.value ? otherField.value : ''}
          onChange={otherField.onChange}
          type={otherInput?.type}
          disabled={!!disabled}
          inputProps={{
            min: otherInput?.min,
            max: otherInput?.max,
            step: otherInput?.step,
          }}
          endAdornment={otherInput?.adornment}
        ></Input>
      )}
    </FormControl>
  );
};

export default RadioButtonGroup;
