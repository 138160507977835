import React, { createContext, ReactNode, useReducer } from 'react';
import { ProductInterface, ProviderInterface } from '../interfaces';

type State = {
  provider?: ProviderInterface;
  productId?: string;
  product?: ProductInterface;
};

type Action = { type: 'update'; payload: Partial<State> };
type Dispatch = (action: Action) => void;

const ProviderContext = createContext<State | undefined>(undefined);
const ProviderDispatchContext = createContext<Dispatch | undefined>(undefined);

const handlePayload = (payload: State, state: State): State => {
  if (payload.productId && state.provider) {
    payload.product = state.provider.products.find(
      (prod) => prod.id === payload.productId
    );
  }
  if (state.productId && payload.provider && !state.product) {
    payload.product = payload.provider.products.find(
      (prod) => prod.id === state.productId
    );
  }
  return payload;
};
const providerReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'update':
      return { ...state, ...handlePayload(action.payload, state) };
    default: {
      throw new Error('ProviderContext error');
    }
  }
};

interface Props {
  children: ReactNode;
  initialValues?: State;
}

const ProviderProvider: React.FC<Props> = ({
  children,
  initialValues,
}: Props) => {
  const initialState = initialValues || {};

  const [state, dispatch] = useReducer(providerReducer, {
    ...initialState,
  });

  return (
    <ProviderContext.Provider value={state}>
      <ProviderDispatchContext.Provider value={dispatch}>
        {children}
      </ProviderDispatchContext.Provider>
    </ProviderContext.Provider>
  );
};

const useProviderState = (): State => {
  const context = React.useContext(ProviderContext);
  if (context === undefined) throw new Error('Use ProviderProvider');
  return context;
};

const useProviderDispatch = (): Dispatch => {
  const context = React.useContext(ProviderDispatchContext);
  if (context === undefined) throw new Error('Use ProviderProvider');
  return context;
};

export { ProviderProvider, useProviderState, useProviderDispatch };
