import { Button, Grid, makeStyles, TextField } from '@material-ui/core';
import Autocomplete, {
  AutocompleteRenderInputParams,
} from '@material-ui/lab/Autocomplete';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { CampaignInterface, ReportTableObj } from '../interfaces';
import campaignClient from '../lib/campaign-client';
import { postExcelData } from '../lib/excel-client';
import { getUserId } from '../lib/storage-util';
import userClient from '../lib/user-client';
import ReportTableContainer from './report-table-container';
const useStyles = makeStyles(() => ({
  container: {
    padding: '20px',
  },
  autocomplete: {
    minWidth: '150px',
    marginTop: '15px',
  },
  inputContainer: {
    padding: '30px',
  },
  datePicker: {
    marginRight: '15px',
  },
  submitButton: {
    marginTop: '15px',
  },
}));

const ReportContainer: React.FC = () => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [orgUsers, setOrgUsers] = useState<{ label: string; value: string }[]>(
    []
  );

  const [campaigns, setCampaigns] = useState<CampaignInterface[]>();
  const [tableCampaigns, setTableCampaigns] = useState<ReportTableObj[]>();
  const [autoComplete, setAutoComplete] =
    useState<{ label: string; value: string }[]>();
  const classes = useStyles();

  useEffect(() => {
    const id = getUserId();
    if (id) {
      const getUsers = async (): Promise<void> => {
        const user = await userClient.getUser(id);
        if (user && user.organization.id) {
          const result = await userClient.getUsers();
          if (result)
            setOrgUsers(
              result.map((item) => ({ label: item.name + '', value: item.id }))
            );
        }
      };
      getUsers();
    }
  }, []);

  const onChangeStart = (e: MaterialUiPickersDate): void => {
    if (e) setStartDate(e.toDate());
  };
  const onChangeEnd = (e: MaterialUiPickersDate): void => {
    if (e) setEndDate(e.toDate());
  };

  const onSubmit = async (): Promise<void> => {
    if (tableCampaigns && startDate && endDate) {
      const data = {
        fileName: `Raportti_${moment().format('YYYY_MM_DD')}.xlsx`,
        excelData: {
          workbook: { creator: '' },
          sheets: [
            {
              name: `Raportti ${startDate?.toLocaleDateString(
                'fi'
              )}-${endDate?.toLocaleDateString('fi')}`,
              data: tableCampaigns.map((campaign) => ({
                'Tilaus tehty': moment(campaign.created).format('DD.MM.YYYY'),
                'Tilattu määrä': campaign.count.label,
                Tuote: campaign.product,
                Materiaali: campaign.material,
                Asiakas: campaign.customer,
                Kampanja: campaign.campaignName,
                Brutto: campaign.gross,
                Netto: campaign.net,
                Myyjä: campaign.username,
              })),
            },
          ],
        },
      };

      postExcelData(data);
    }
  };

  useEffect(() => {
    const getCampaigns = async (): Promise<void> => {
      if (autoComplete && startDate && endDate) {
        const result = await campaignClient.listActiveCampaignsByUserIdsandDate(
          autoComplete.map((item) => item.value),
          new Date(moment(startDate).format('YYYY-MM-DD')),
          //lisätään päivä, että näkyy valitun päivän reservaatiot
          new Date(moment(endDate).add(1, 'day').format('YYYY-MM-DD'))
        );
        if (result) setCampaigns(result);
      }
    };
    getCampaigns();
  }, [autoComplete, startDate, endDate]);

  const onChangeAuto = (
    e: React.ChangeEvent<{}>,
    value: {
      label: string;
      value: string;
    }[]
  ): void => {
    setAutoComplete(value);
  };
  return (
    <Grid className={classes.container} container direction="column">
      <Grid
        item
        className={classes.inputContainer}
        container
        direction="column"
      >
        <Grid item container direction="row">
          <Grid item container>
            <DatePicker
              className={classes.datePicker}
              label="Alkamispäivä"
              value={startDate}
              onChange={onChangeStart}
            ></DatePicker>
            <DatePicker
              label="Loppumispäivä"
              value={endDate}
              onChange={onChangeEnd}
            ></DatePicker>
          </Grid>
          <Grid item>
            <Autocomplete
              className={classes.autocomplete}
              multiple
              options={orgUsers}
              getOptionLabel={(option: {
                label: string;
                value: string;
              }): string => option.label}
              onChange={onChangeAuto}
              renderInput={(
                params: AutocompleteRenderInputParams
              ): React.ReactNode => (
                <TextField {...params} variant="standard" label="Myyjät" />
              )}
            ></Autocomplete>
          </Grid>
        </Grid>
        <Grid item>
          <Button
            className={classes.submitButton}
            disabled={!campaigns || campaigns.length === 0}
            variant="outlined"
            onClick={onSubmit}
          >
            Lataa raportti
          </Button>
        </Grid>
      </Grid>

      <ReportTableContainer
        campaigns={campaigns}
        setTableCampaigns={setTableCampaigns}
      ></ReportTableContainer>
    </Grid>
  );
};

export default ReportContainer;
