/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { ContentState, EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { FormikErrors } from 'formik';
import htmlToDraft from 'html-to-draftjs';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface Props {
  name: string;
  title: string;
  placeholder: string;
  minHeight?: string;
  formik: {
    handleChange: (
      eventOrPath: string | ChangeEvent<any>
    ) => void | ((eventOrTextValue: string | ChangeEvent<any>) => void);
    errors: FormikErrors<any>;
    values: any;
    setFieldValue: (name: string, value: string) => void;
  };
}

const useStyles = makeStyles((theme) => ({
  rightItem: {
    width: '400px',
  },
  container: {
    paddingLeft: '24px',
    width: '800px',
    marginTop: '60px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
  textArea: (props: { minHeight: string }) => ({
    minWidth: '300px',
    border: '1px solid rgb(133, 133, 133)',
    height: '100%',
    borderRadius: '3px',
  }),
  inputContainer: {
    justifyContent: 'space-between',
  },
  editor: {
    height: '100%',
  },
}));

const SummaryFormTextAreaEditor: React.FC<Props> = ({
  name,
  title,
  formik,
  minHeight = '100px',
}: Props) => {
  const classes = useStyles({ minHeight });

  const blocksFromHtml = htmlToDraft(`${formik.values[name] ? formik.values[name] : ''}`);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);

  const [editorState, setEditorState] = useState(() => EditorState.createWithContent(contentState));

  const onEditorStateChange = (editorState: EditorState): void => {
    setEditorState(editorState);
  };

  useEffect(() => {
    const html = stateToHTML(editorState.getCurrentContent(), {});

    formik.setFieldValue(name, html);
  }, [editorState]);
  return (
    <Grid
      key={`field-${name}`}
      container
      className={classes.inputContainer}
      item
      style={{ marginBottom: '60px' }}
    >
      <Grid item>
        <Typography className={classes.title}>{title}</Typography>
      </Grid>
      <Grid className={classes.rightItem} item>
        <Grid container>
          <Editor
            editorState={editorState}
            toolbarClassName="toolbarClassName"
            wrapperClassName={classes.textArea}
            editorClassName={classes.editor}
            onEditorStateChange={onEditorStateChange}
            toolbarHidden
          ></Editor>

          <Typography className={classes.error}>
            {formik.errors[name] ? formik.errors[name] : null}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SummaryFormTextAreaEditor;
