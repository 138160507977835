import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import OrganizationForm from '../components/user-create/organization-form';
import UserForm from '../components/user-create/user-form';
import { NewUserInterface, OrganizationInterface } from '../interfaces';
import userClient from '../lib/user-client';

const useStyles = makeStyles(() => ({
  grid: {
    width: '100%',
    margin: '25px 25px 25px 25px',
  },
  gridContainer: {
    'grid-auto-flow': 'row',
    alignItems: 'flex-start',
    margin: '0',
    width: '100%',
  },
}));

const UserCreateContainer: React.FC = () => {
  const [organizations, setOrganizations] = useState<OrganizationInterface[]>(
    []
  );
  const classes = useStyles();

  const getOrganizations = async (): Promise<void> => {
    try {
      const result = await userClient.getOrganizations();
      setOrganizations(result);
    } catch (error) {
      console.log(error);
    }
  };

  const addOrganization = async (
    organization: OrganizationInterface
  ): Promise<void> => {
    await userClient.addOrganization(organization);
    await getOrganizations();
  };

  const addUser = async (user: NewUserInterface): Promise<void> => {
    await userClient.addUser(user);
  };

  useEffect(() => {
    getOrganizations();
  }, []);
  return (
    <Grid container spacing={2} className={classes.gridContainer}>
      <Grid item container className={classes.grid}>
        <OrganizationForm addOrganization={addOrganization}></OrganizationForm>
        <UserForm organizations={organizations} addUser={addUser}></UserForm>
      </Grid>
    </Grid>
  );
};

export default UserCreateContainer;
