import * as Yup from 'yup';
import {
  ProductSettingInterface,
  ProductVariationInterface,
} from '../interfaces';

interface OptionInterface {
  label: string;
  value: string;
  id?: string;
}

interface Schema {
  [key: string]:
    | Yup.BooleanSchema
    | Yup.NumberSchema
    | Yup.DateSchema
    | Yup.StringSchema;
}

export const mapCheckboxValidations = (
  options: (OptionInterface | ProductVariationInterface)[],
  name: string,
  required?: boolean
): Yup.ObjectSchema => {
  const schema: Schema = {};
  options.map((option: OptionInterface | ProductVariationInterface) => {
    if (option.id) {
      schema[option.id] = Yup.bool();
    }
    if (option.label) {
      schema[option.label] = Yup.bool();
    }
  });

  return Yup.object()
    .shape({ [name]: Yup.object().shape(schema) })
    .test(`${name}-test`, '', (obj) => {
      let isEmpty = true;

      Object.entries(obj[name]).map((value) => {
        if (value.find((item) => item === true)) {
          isEmpty = false;
        }
      });

      if (!isEmpty || !required) return true;

      return new Yup.ValidationError('Valitse vähintään yksi', null, name);
    });
};

export const mapSettingsValidations = (
  settings: ProductSettingInterface[]
): Yup.ObjectSchema => {
  let tempSchema = Yup.object().shape({});
  const schema: Schema = {};

  settings.forEach((setting) => {
    let checkboxSchema: Yup.ObjectSchema | undefined;
    switch (setting.type) {
      case 'radio':
        schema[setting.id] = setting.required
          ? Yup.string().required('Pakollinen kenttä')
          : Yup.string();

        if (setting.otherOption) {
          schema[setting.id + '-other'] = Yup.number().when(setting.id, {
            is: (value) => value === 'other',
            then: Yup.number()
              .required('Täytä muu kenttä')
              .test(`other`, '', (obj) => {
                if (setting.otherOption.type === 'number') {
                  if (setting.otherOption.adornment === 's') {
                    if (obj % 5 === 0) return true;
                  }
                } else {
                  if (obj) return true;
                }

                return new Yup.ValidationError(
                  setting.otherOption.errorMessage
                    ? setting.otherOption.errorMessage
                    : 'Täytä kenttä',
                  null,
                  setting.id + '-other'
                );
              }),
            otherwise: Yup.number(),
          });
        }

        break;
      case 'checkbox':
        checkboxSchema = mapCheckboxValidations(
          setting.options,
          setting.id,
          setting.required
        );
        if (checkboxSchema) {
          tempSchema = tempSchema.concat(checkboxSchema);
        }
        break;
      case 'string':
        schema[setting.id] = setting.required
          ? Yup.string().required('Pakollinen kenttä')
          : Yup.string();
        break;
      case 'number':
        schema[setting.id] = setting.required
          ? Yup.number().required('Pakollinen kenttä')
          : Yup.number();
        break;
      case 'date':
        schema[setting.id] = setting.required
          ? Yup.date().required('Pakollinen kenttä')
          : Yup.date();
        break;
      default:
        break;
    }
  });

  return Yup.object().shape(schema).concat(tempSchema);
};
