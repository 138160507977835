import { Router } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { getUserId } from './lib/storage-util';
import userClient from './lib/user-client';
import routes from './routes';

const App: React.FC = () => {
  const [restrictedPaths, setRestrictedPaths] = useState<string[]>();
  useEffect(() => {
    const getUser = async (): Promise<void> => {
      try {
        const userId = getUserId();
        if (userId) {
          const user = await userClient.getUser(userId);
          if (user.organization.restrictedPaths && user.organization.restrictedPaths.length > 0)
            setRestrictedPaths(user.organization.restrictedPaths);
        }
      } catch {
        //pass
      }
    };
    getUser();
  }, []);

  return (
    <Router style={{ height: '100%', minHeight: '100%' }}>
      {routes.map((route) => {
        if (restrictedPaths && restrictedPaths.includes(route.path)) return;
        return <route.component path={route.path} key={route.path}></route.component>;
      })}
    </Router>
  );
};

export default App;
