import { redirectTo, RouteComponentProps } from '@reach/router';
import React, { useEffect } from 'react';
import { Layout } from '../components';
import ReservationListContainer from '../containers/reservations-list-container';
import { getToken } from '../lib/storage-util';

const Reservations: React.FC<RouteComponentProps> = () => {
  useEffect(() => {
    document.title = 'Sales Mediamyynti - Reservations';
    const token = getToken();
    if (!token) {
      redirectTo('/');
    }
  }, []);
  return (
    <Layout tab="/reservations">
      <ReservationListContainer></ReservationListContainer>
    </Layout>
  );
};
export default Reservations;
