import { CampaignInterface, CustomerCampaign, OrderDetails, OrderSummary } from '../interfaces';
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { addCampaign, updateCampaign } from '../lib/use-cases';
import { useAddOrderDispatch, useAddOrderState } from '../context/add-order-context';
import { useOrderFormDispatch, useOrderFormState } from '../context/order-form-context';

import { Calendar } from 'calendar-base';
import OrderButtonsStep from '../components/order/order-form-steps/order-buttons-step';
import OrderCustomerContainer from './order-customer-container';
import OrderDetailsContainer from './order-details-container';
import OrderStepper from '../components/order/order-stepper';
import OrderSummaryStep from '../components/order/order-form-steps/order-summary-step';
import { parseCampaignPriceInput } from '../types/campaign-price-input';
import { parseReservationsToOrderBatches } from '../types/order-batch';
import { useProviderDispatch } from '../context/provider-context';

const useStyles = makeStyles(() => ({
  container: { backgroundColor: '#fff' },
}));

interface Props {
  campaignData?: CampaignInterface;
  approveOffer?: boolean;
  edit?: boolean;
}

const OrderFormContainer: React.FC<Props> = ({
  campaignData,
  approveOffer = false,
  edit = false,
}: Props) => {
  // data
  const [orderDetails, setOrderDetails] = useState<OrderDetails>();
  const [campaign, setCampaignDetails] = useState<CustomerCampaign>();
  const [orderSummary, setSummary] = useState<OrderSummary>();

  //submit stuff
  const [sending, setSending] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState(false);
  const [orderSubmit, setOrderSubmit] = useState(false);
  const [campaignSubmit, setCampaignSubmit] = useState(false);
  const [orderSummarySubmit, setSummarySubmit] = useState(false);

  //errors
  const [detailErrors, setDetailErros] = useState(false);
  const [customerErrors, setCustomerErrors] = useState(false);
  const [summaryErrors, setSummaryErrors] = useState(false);

  //other
  const [offer, setOffer] = useState(false);
  const [summaryTypeFormat, setSummaryTypeFormat] = useState();
  const [openEditorModal, setOpenEditorModal] = useState(false);

  //refs
  const detailRef = useRef(null);
  const customerRef = useRef(null);
  const orderSummaryRef = useRef(null);

  const [pdf, setPdf] = useState(false);

  //hooks
  const addOrderContext = useAddOrderState();
  const addOrderDispatch = useAddOrderDispatch();
  const providerDispatch = useProviderDispatch();
  const classes = useStyles();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const scrollToRef = (ref: any): void => {
    window.scrollTo(0, ref.current.offsetTop - 85);
  };

  const orderFormState = useOrderFormState();
  const orderFormDispatch = useOrderFormDispatch();

  const scrollOnErrors = (): void => {
    if (detailErrors || addOrderContext.floatingOrderError) scrollToRef(detailRef);
    //else if (detailErrors) scrollToRef(detailRef);
    else if (customerErrors) scrollToRef(customerRef);
    else if (summaryErrors) scrollToRef(orderSummaryRef);
  };

  const sendOrder = (): void => {
    if (submitting) return;
    scrollOnErrors();
    setOffer(false);
    setSubmitting(true);
  };

  const sendOrderPDF = (): void => {
    setPdf(true);
    if (submitting) return;
    scrollOnErrors();
    setOffer(false);
    setSubmitting(true);
  };

  const sendOffer = (): void => {
    if (submitting) return;
    scrollOnErrors();
    setOffer(true);
    setSubmitting(true);
  };

  const onSubmitOrder = (values: OrderDetails): void => {
    setOrderDetails(values);
    setOrderSubmit(true);
  };

  const onSubmitCustomer = (values: CustomerCampaign): void => {
    setCampaignDetails(values);
    setCampaignSubmit(true);
  };

  const onSubmitSummary = (values: OrderSummary): void => {
    setSummary(values);
    setSummarySubmit(true);
  };

  useEffect(() => {
    if (!campaignSubmit || !orderSubmit || !orderSummarySubmit) return;
    if (detailErrors || customerErrors || summaryErrors || addOrderContext.floatingOrderError) {
      setCampaignSubmit(false);
      setOrderSubmit(false);
      setSummarySubmit(false);
      setSubmitting(false);
      return;
    }
    if (!orderDetails || !campaign || !orderSummary || !orderFormState.price) return;
    if (campaignSubmit && orderSubmit && orderSummarySubmit) {
      setCampaignSubmit(false);
      setOrderSubmit(false);
      setSummarySubmit(false);
      if (offer) setOpenEditorModal(true);
      else if (edit && campaignData && campaignData.id) {
        const advancePayment = orderFormState.isMultiMonth ? !orderFormState.partialPayment : true;
        const billingInfo = advancePayment ? 'ENNAKKOLASKU ' : 'OSALASKU ';
        updateCampaign(
          orderDetails,
          campaign,
          {
            ...orderSummary,
            invoiceInstruction: billingInfo
              ? billingInfo + (orderSummary.invoiceInstruction ?? '')
              : orderSummary.invoiceInstruction,
            advancePayment: advancePayment,
          },
          orderFormState.price,
          setSending,
          orderFormState.batches,
          addOrderContext,
          campaignData.id,
          pdf
        );
      } else {
        const advancePayment = orderFormState.isMultiMonth ? !orderFormState.partialPayment : true;
        const billingInfo = advancePayment ? 'ENNAKKOLASKU ' : 'OSALASKU ';

        addCampaign(
          orderDetails,
          campaign,
          {
            ...orderSummary,
            invoiceInstruction: billingInfo
              ? billingInfo + (orderSummary.invoiceInstruction ?? '')
              : orderSummary.invoiceInstruction,
            advancePayment: advancePayment,
          },
          orderFormState.price,
          setSending,
          orderFormState.batches,
          addOrderContext,
          approveOffer && campaignData ? campaignData.id : undefined,
          pdf
        );
      }
    }
  }, [campaignSubmit, orderSubmit, orderSummarySubmit]);

  useEffect(() => {
    if (!campaignData) return;

    addOrderDispatch({
      type: 'initByOrder',
      payload: campaignData,
    });
    // provider init
    const { reservations } = campaignData;

    if (edit)
      orderFormDispatch({
        type: 'updateBatches',
        payload: reservations
          ? parseReservationsToOrderBatches({
              reservations,
              priceRows: campaignData.campaignPrice.priceRows,
            })
          : [],
      });
    else {
      //poistetaan vanhat paivat pois
      const batches = reservations
        ? parseReservationsToOrderBatches({
            reservations,
            priceRows: campaignData.campaignPrice.priceRows,
          })
        : [];
      const currentDate = new Date();
      const filtered = batches.map((batch) => {
        if (batch.dates && batch.dates.length > 0) {
          const dates = batch.dates.filter((date) => {
            return date.getTime() > currentDate.getTime();
          });
          return {
            ...batch,
            dates,
          };
        }

        if (batch.months && batch.months.length > 0) {
          const currentMonth = currentDate.getMonth() + 1;
          const currentYear = currentDate.getFullYear();
          const months = batch.months.filter((month) => {
            if (currentYear < month.year) return true;
            if (currentYear > month.year) return false;
            if (currentMonth > month.month) return false;
            return true;
          });
          return {
            ...batch,
            months,
          };
        }

        if (batch.weeks && batch.weeks.length > 0) {
          const currentWeek = Calendar.calculateWeekNumber({
            year: currentDate.getFullYear(),
            month: currentDate.getMonth(),
            day: currentDate.getDate(),
          });
          const currentYear = currentDate.getFullYear();
          const weeks = batch.weeks.filter((week) => {
            if (currentYear < week.year) return true;
            if (currentYear > week.year) return false;
            if (currentWeek > week.week) return false;
            return true;
          });
          return {
            ...batch,
            weeks,
          };
        }

        return batch;
      });

      orderFormDispatch({
        type: 'updateBatches',
        payload: filtered,
      });
    }

    orderFormDispatch({
      type: 'updatePartialPayment',
      payload: !campaignData.advancePayment,
    });

    orderFormDispatch({
      type: 'updateCampaignPrice',
      payload: parseCampaignPriceInput({
        ...campaignData.campaignPrice,
        campaign: campaignData.id,
      }),
    });

    providerDispatch({
      type: 'update',
      payload: {
        productId: reservations && reservations.length > 0 ? reservations[0].productId : '',
      },
    });
  }, [campaignData]);

  const items = [
    {
      ref: detailRef,
      id: 'orderDetails',
      title: 'Tilauksen tiedot',
      class: 'details',
      content: (
        <OrderDetailsContainer
          submitting={submitting}
          setSubmitting={setSubmitting}
          handleSubmit={onSubmitOrder}
          setDetailErrors={setDetailErros}
          setSummaryTypeFormat={setSummaryTypeFormat}
          campaignData={campaignData}
        ></OrderDetailsContainer>
      ),
    },
    {
      ref: customerRef,
      id: 'customerDetails',
      title: 'Tilaajan tiedot',
      class: 'details',
      content: (
        <OrderCustomerContainer
          setCustomerErrors={setCustomerErrors}
          submitting={submitting}
          handleSubmit={onSubmitCustomer}
          campaignData={campaignData}
        ></OrderCustomerContainer>
      ),
    },
    {
      ref: orderSummaryRef,
      id: 'orderSummaryId',
      title: 'Tilauksen yhteenveto',
      class: 'ei oo sita classia talla',
      content: (
        <OrderSummaryStep
          onSubmitSummary={onSubmitSummary}
          submitting={submitting}
          summaryTypeFormat={summaryTypeFormat}
          campaignData={campaignData}
          setSummaryErrors={setSummaryErrors}
          sendOffer={sendOffer}
          sendOrder={sendOrder}
          orderDetails={orderDetails}
          campaign={campaign}
          orderSummary={orderSummary}
          sending={sending}
          setSending={setSending}
          openEditorModal={openEditorModal}
          setOpenEditorModal={setOpenEditorModal}
        />
      ),
    },
    {
      ref: null,
      id: 'orderContainerActions',
      title: null,
      class: null,
      content: (
        <OrderButtonsStep
          sendOrder={sendOrder}
          sendOrderPdf={sendOrderPDF}
          sendOffer={sendOffer}
          sending={sending}
          approveOffer={approveOffer}
          edit={edit}
        ></OrderButtonsStep>
      ),
    },
  ];

  return (
    <Grid container className={classes.container}>
      <OrderStepper items={items} />
    </Grid>
  );
};

export default OrderFormContainer;
