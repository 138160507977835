import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import InputArray from '../../input-array';

interface ContactProps {
  schema: any;
  handleChange: any;
  values: any;
  setFieldValue: Function;
  formikErrors: any;
  formik: any;
  touched: any;
  updating: boolean;
}

const FormContactPerson: React.FC<ContactProps> = ({
  schema,
  handleChange,
  values,
  setFieldValue,
  formikErrors,
  formik,
  touched,
  updating,
}: ContactProps) => {
  return (
    <Grid container>
      <Grid item>
        <Typography>{schema.label}</Typography>
      </Grid>
      <Grid item container spacing={2}>
        <InputArray
          updating={updating}
          formikErrors={formikErrors}
          input={schema}
          handleChange={handleChange}
          formikValues={values[schema.name]}
          setFieldValue={setFieldValue}
          formik={formik}
          touched={touched}
        ></InputArray>
      </Grid>
    </Grid>
  );
};

export default FormContactPerson;
