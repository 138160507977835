/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import SummaryContainer from '../../../containers/order-summary-container';
import { useOrderFormState } from '../../../context/order-form-context';
import {
  CampaignDetailInterface,
  CampaignInterface,
  CustomerCampaign,
  OrderDetails,
  OrderSummary,
  ProductSettingInterface,
} from '../../../interfaces';
import EditorModal from '../../text-editor-modal/editor-modal';

interface Props {
  onSubmitSummary: Function;
  submitting: boolean;
  summaryTypeFormat?: {
    settings: ProductSettingInterface[];
    settingValues: CampaignDetailInterface[];
    type: string;
  };
  campaignData?: CampaignInterface;
  setSummaryErrors: (value: boolean) => void;
  sending: boolean;
  sendOrder: () => void;
  sendOffer: () => void;
  orderDetails?: OrderDetails;
  campaign?: CustomerCampaign;
  orderSummary?: OrderSummary;
  setSending?: Function;
  openEditorModal: boolean;
  setOpenEditorModal: (x: boolean) => void;
}

const OrderSummaryStep: React.FC<Props> = ({
  submitting,
  onSubmitSummary,
  summaryTypeFormat,
  campaignData,
  setSummaryErrors,
  orderDetails,
  orderSummary,
  setSending,
  campaign,
  openEditorModal,
  setOpenEditorModal,
}: Props) => {
  const orderFormState = useOrderFormState();

  return (
    <>
      <SummaryContainer
        submitting={submitting}
        handleSubmit={onSubmitSummary}
        summaryTypeFormat={summaryTypeFormat}
        campaignData={campaignData}
        setErrors={setSummaryErrors}
      ></SummaryContainer>
      {openEditorModal && (
        <EditorModal
          modalOpen={openEditorModal}
          onClickModal={(): void => setOpenEditorModal(false)}
          orderDetails={orderDetails}
          campaign={campaign}
          orderSummary={orderSummary}
          price={orderFormState.price}
          setSending={setSending}
          campaignData={campaignData}
        ></EditorModal>
      )}
    </>
  );
};

export default OrderSummaryStep;
