import { Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import {
  CampaignDetailInterface,
  ProductSettingInterface,
} from '../../interfaces';
import OrderSummaryFormDetailsSettings from './summary-form-order-details-settings';

const useStyles = makeStyles(() => ({
  inputContainer: {
    justifyContent: 'space-between',
  },
  rightItem: {
    width: '400px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
}));

interface SummaryProps {
  summaryTypeFormat?: {
    settings: ProductSettingInterface[];
    settingValues: CampaignDetailInterface[];
    type: string;
  };
}

const OrderSummaryFormDetails: React.FC<SummaryProps> = ({
  summaryTypeFormat,
}: SummaryProps) => {
  const classes = useStyles();

  return (
    <>
      <Grid
        container
        className={classes.inputContainer}
        alignItems="flex-end"
        style={{ marginBottom: '20px' }}
        item
      >
        <Grid item>
          <Typography className={classes.title}>Mainostyyppi</Typography>
        </Grid>
        <Grid item className={classes.rightItem}>
          <Typography style={{ textTransform: 'capitalize', fontSize: '16px' }}>
            {summaryTypeFormat?.type ? summaryTypeFormat.type : '-'}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        className={classes.inputContainer}
        alignItems="flex-end"
        style={{ marginBottom: '100px' }}
        item
      >
        <OrderSummaryFormDetailsSettings
          summaryTypeFormat={summaryTypeFormat}
        ></OrderSummaryFormDetailsSettings>
      </Grid>
    </>
  );
};

export default OrderSummaryFormDetails;
