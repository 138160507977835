import {
  AppBar,
  Button,
  CardMedia,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Link,
  makeStyles,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ClearIcon from '@material-ui/icons/Clear';
import MenuIcon from '@material-ui/icons/Menu';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import { navigate } from '@reach/router';
import React, { useEffect, useState } from 'react';
import PMLogo from '../assets/punamusta-logo.png';
import { getToken, getUserId } from '../lib/storage-util';
import userClient from '../lib/user-client';
const useStyles = makeStyles(() => ({
  button: {
    color: '#000000',
    margin: '7px',
    marginTop: '5px',
    textTransform: 'initial',
    borderRadius: ' 4px',
  },
  wrapper: {
    alignItems: 'start',
    width: '100%',
  },
  logOutButton: {
    height: '100%',
    color: 'white',
  },
  header: {
    backgroundColor: '#DE0A39',
    justifyContent: 'space-between',
  },
  drawerPaper: {
    backgroundColor: 'white',

    width: '304px',
    boxShadow: '0px 16px 24px #00000033',
  },
  drawerRoot: {
    backgroundColor: '#B1B8C00D',
    paddingBottom: '30px',
    height: '100%',
  },
  title: {
    marginLeft: '16px',
    fontWeight: 900,
    fontSize: '20px',
    textTransform: 'uppercase',
    letterSpacing: '0.26px',
  },
  drawerTitle: {
    fontWeight: 900,
    fontSize: '20px',
    letterSpacing: '0.26px',
    textTransform: 'capitalize',
    margin: '23px',
  },
  logo: { width: '130px', height: '24px', marginTop: '62px' },
  link: {
    margin: '15px',
    marginLeft: '27px',
    color: '#000000',
    cursor: 'pointer',
  },
  selectedTab: {
    backgroundColor: 'rgba(227, 3, 18,0.1)',
  },
  indicator: { display: 'none' },
  tabRoot: { minHeight: '40px' },
  divider: {
    marginBottom: '20px',
  },
}));
interface HeaderProps {
  tab?: string;
}
const Header: React.FC<HeaderProps> = ({ tab }: HeaderProps) => {
  const classes = useStyles();
  const [token] = useState(getToken());
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('/order');
  const [restrictedPaths, setRestrictedPaths] = useState<string[]>();

  const handleChange = (event: any, newValue: any): void => {
    setValue(newValue);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const onClick = (path: string): void => {
    navigate(`/${path}`);
  };

  const TabLabel = (text: string) => {
    return (
      <Grid container direction="row">
        <ChevronRightIcon></ChevronRightIcon>
        <Typography style={{ marginLeft: '40px' }}>{text}</Typography>
      </Grid>
    );
  };

  useEffect(() => {
    const getUser = async (): Promise<void> => {
      try {
        const userId = getUserId();
        if (userId) {
          const user = await userClient.getUser(userId);
          if (user.organization.restrictedPaths && user.organization.restrictedPaths.length > 0)
            setRestrictedPaths(user.organization.restrictedPaths);
        }
      } catch {
        //pass
      }
    };
    getUser();
  }, []);

  return (
    <Grid>
      <AppBar position="static">
        <Toolbar className={classes.header}>
          <Grid item container direction="row">
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>

            <Grid item>
              <Grid container alignContent="center" style={{ height: '100%' }}>
                <Typography className={classes.title}>Mediamyynti</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container justifyContent="flex-end">
            {token && (
              <Button
                className={classes.logOutButton}
                id="logout"
                onClick={(): void => onClick('logout')}
              >
                <Typography style={{ fontWeight: 'bold', marginRight: '10px' }}>
                  kirjaudu ulos
                </Typography>
                <PowerSettingsNewIcon></PowerSettingsNewIcon>
              </Button>
            )}
          </Grid>
        </Toolbar>
      </AppBar>
      <Drawer
        classes={{ paper: classes.drawerPaper }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <Grid className={classes.drawerRoot}>
          <Grid
            container
            direction="column"
            style={{ height: '100%' }}
            wrap="nowrap"
            justifyContent="space-between"
          >
            <Grid item style={{ width: '100%' }}>
              <Grid container justifyContent="flex-end">
                <IconButton onClick={handleDrawerClose}>
                  <ClearIcon />
                </IconButton>
              </Grid>
              <Grid container>
                <Typography className={classes.drawerTitle}>Mediamyynti</Typography>
              </Grid>
              <Divider className={classes.divider} />
              {token && (
                <Tabs
                  value={tab}
                  onChange={handleChange}
                  orientation="vertical"
                  variant="fullWidth"
                  classes={{ indicator: classes.indicator }}
                >
                  <Tab
                    label={TabLabel('Uusi tilaus')}
                    value="/order"
                    className={classes.button}
                    classes={{
                      root: classes.tabRoot,
                      wrapper: classes.wrapper,
                      selected: classes.selectedTab,
                    }}
                    id="order"
                    onClick={(): void => onClick('order')}
                  ></Tab>
                  <Tab
                    label={TabLabel('Tilaukset')}
                    value="/orders"
                    className={classes.button}
                    classes={{
                      root: classes.tabRoot,
                      wrapper: classes.wrapper,
                      selected: classes.selectedTab,
                    }}
                    id="orders"
                    onClick={(): void => onClick('orders')}
                  ></Tab>
                  <Tab
                    label={TabLabel('Kelluvat tilaukset')}
                    value="/floatingOrders"
                    className={classes.button}
                    classes={{
                      root: classes.tabRoot,
                      wrapper: classes.wrapper,
                      selected: classes.selectedTab,
                    }}
                    id="floatingOrders"
                    onClick={(): void => onClick('floatingOrders')}
                  ></Tab>
                  <Tab
                    value="/offers"
                    className={classes.button}
                    classes={{
                      root: classes.tabRoot,
                      wrapper: classes.wrapper,
                      selected: classes.selectedTab,
                    }}
                    id="offers"
                    onClick={(): void => onClick('offers')}
                    label={TabLabel('Tarjoukset')}
                  ></Tab>
                  {!restrictedPaths?.includes('/reservations') && (
                    <Tab
                      value="/reservations"
                      className={classes.button}
                      classes={{
                        root: classes.tabRoot,
                        wrapper: classes.wrapper,
                        selected: classes.selectedTab,
                      }}
                      id="reservations"
                      onClick={(): void => onClick('reservations')}
                      label={TabLabel('Päiväkohtaiset tilaukset')}
                    ></Tab>
                  )}
                  {!restrictedPaths?.includes('/report') && (
                    <Tab
                      value="/report"
                      className={classes.button}
                      classes={{
                        root: classes.tabRoot,
                        wrapper: classes.wrapper,
                        selected: classes.selectedTab,
                      }}
                      id="report"
                      onClick={(): void => onClick('report')}
                      label={TabLabel('Raportti')}
                    ></Tab>
                  )}
                  {!restrictedPaths?.includes('/newuser') && (
                    <Tab
                      value="/newuser"
                      label={TabLabel('Uusi käyttäjä')}
                      className={classes.button}
                      classes={{
                        root: classes.tabRoot,
                        wrapper: classes.wrapper,
                        selected: classes.selectedTab,
                      }}
                      id="newuser"
                      onClick={(): void => onClick('newuser')}
                    ></Tab>
                  )}

                  {/* <Tab
                value="/playlist"
                label="Soittolistat"
                className={classes.button}
                classes={{ wrapper: classes.wrapper }}
                id="playlist"
                onClick={(): void => onClick('playlist')}
              ></Tab> */}
                </Tabs>
              )}
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <Divider className={classes.divider}></Divider>

              <Grid container direction="column">
                <Grid item container direction="column">
                  <Link target="blank" href="https://mediamyynti.com/" className={classes.link}>
                    Mediamyynti.com
                  </Link>
                  <Link target="blank" href="https://www.punamusta.com/" className={classes.link}>
                    Punamusta.com
                  </Link>
                  <Link target="blank" href="" className={classes.link}>
                    Tuki
                  </Link>
                </Grid>
                <Grid container justifyContent="center">
                  <CardMedia component="img" image={PMLogo} className={classes.logo}></CardMedia>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Drawer>
    </Grid>
  );
};

export default Header;
