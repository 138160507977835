import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';
import { OrganizationInterface } from '../../interfaces';

const useStyles = makeStyles(() => ({
  grid: {
    width: '100%',
    margin: '25px 25px 25px 25px',
  },
  gridContainer: {
    'grid-auto-flow': 'row',
    alignItems: 'flex-start',
    margin: '0',
    width: '100%',
  },
  title: {
    fontSize: '30px',
    letterSpacing: '0.24px',
    lineHeight: '40px',
    fontWeight: 400,
    color: '#000000DE',
    'margin-bottom': '25px',
  },
  label: {
    margin: '5px 5px 5px 5px',
    fontWeight: 'bold',
    float: 'left',
    width: '150px',
  },
  inputField: { margin: '0px 0px 0px 5px', float: 'left', width: '400px' },
  buttonBase: {
    backgroundColor: '#5E8EFC',

    borderRadius: '4px',
    'margin-bottom': '25px',
  },
  submitText: {
    color: 'white',
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: '1.25px',
  },
  formGrids: {
    margin: '25px 0px 0px 0px',
  },
  error: {
    color: 'red',
    marginLeft: '15px',
    marginBottom: '5px',
  },
}));

interface Props {
  addOrganization: (organization: OrganizationInterface) => Promise<void>;
}
const OrganizationForm: React.FC<Props> = ({ addOrganization }: Props) => {
  const [noErrors, setNoErrors] = useState(true);
  const classes = useStyles();

  const organizationSchema = Yup.object({
    name: Yup.string().required('Pakollinen kenttä'),
  });

  const onSubmitOrganization = async (
    {
      name,
    }: {
      name: string;
    },
    formikBag: { resetForm: (arg0: {}) => void }
  ): Promise<void> => {
    try {
      const organization: OrganizationInterface = { name: name };
      await addOrganization(organization);
      formikBag.resetForm({});
      if (!noErrors) setNoErrors(true);
    } catch (error) {
      setNoErrors(false);
      console.log(error);
    }
  };

  const organizationFormik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: organizationSchema,
    onSubmit: onSubmitOrganization,
    validateOnChange: false,
    validateOnBlur: false,
  });

  return (
    <>
      <Typography className={classes.title}>
        Lisää uusi organisaatio{' '}
      </Typography>
      <form onSubmit={organizationFormik.handleSubmit} className={classes.grid}>
        <Grid item container spacing={2} className={classes.gridContainer}>
          <Grid item className={classes.formGrids}>
            <Typography className={classes.label}>
              Organisaation nimi
            </Typography>
          </Grid>
          <Grid item className={classes.formGrids}>
            <Grid className={classes.error}>
              {organizationFormik.errors.name
                ? organizationFormik.errors.name
                : null}
            </Grid>
            <TextField
              id="organizationName"
              name="name"
              label="Organisaation nimi"
              placeholder="Syötä organisaation nimi"
              onChange={organizationFormik.handleChange}
              value={organizationFormik.values.name}
              className={classes.inputField}
            />
          </Grid>
        </Grid>
        <Grid className={classes.error}>
          {noErrors ? noErrors : 'Lisäyksessä tapahtui virhe'}
        </Grid>
        <Grid item className={classes.formGrids}>
          <Button
            variant="contained"
            color="primary"
            className={classes.buttonBase}
            type="submit"
          >
            <Typography className={classes.submitText}>
              Lisää organisaatio
            </Typography>
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default OrganizationForm;
