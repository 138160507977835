import { FormControl, FormControlLabel, Grid, makeStyles, Switch } from '@material-ui/core';
import fi from 'date-fns/locale/fi';
import React, { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CustomDate from './custom-date';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '800px',
    marginTop: '0px',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  radio: {
    '&$checked': {
      color: '#5E8EFC',
    },
  },
  checked: {},
  formGroup: {
    width: '400px',
    padding: 0,
  },
  title: {
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '19px',
    fontWeight: 'bold',
    color: '#000000',
  },
  text: {
    fontSize: '14px',
    marginTop: '-10px',
    padding: 0,
    letterSpacing: '0.25px',
    lineHeight: '16px',
    fontWeight: 400,
    color: '#000000DE',
  },
}));

type Props = {
  handleDateRange: (x: any) => Promise<void>;
  campaignDates?: Date[];
  minDate: Date;
};

registerLocale('fi', fi);

const OrderDateRangeInput: React.FC<Props> = ({ handleDateRange, campaignDates, minDate }) => {
  const [show, setShow] = useState(false);

  const [range, setRange] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  }>({
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    if (range.startDate && range.endDate) {
      handleDateRange({ ...range });
      setRange({ startDate: null, endDate: null });
    }
  }, [range]);

  useEffect(() => {
    //ehka joskus jotain
  }, [campaignDates]);

  const changeRange = (value: [Date | null, Date | null]): void => {
    setRange({
      startDate: value[0],
      endDate: value[1],
    });
  };

  return (
    <Grid container style={{ marginTop: '20px', marginLeft: '15px', width: '80%' }} direction="row">
      <FormControl>
        <FormControlLabel
          labelPlacement="end"
          label="Käytä päivämäärävalitsinta"
          control={
            <Switch
              checked={show}
              onChange={(): void => {
                setShow(!show);
              }}
              color="primary"
            ></Switch>
          }
        ></FormControlLabel>
      </FormControl>
      {show && (
        <div
          style={{
            marginLeft: '15px',
          }}
        >
          <DatePicker
            selectsRange
            startDate={range.startDate}
            endDate={range.endDate}
            withPortal
            locale="fi"
            dateFormat="dd.MM.yyyy"
            onChange={changeRange}
            minDate={minDate}
            calendarStartDay={1}
            customInput={<CustomDate />}
          />
        </div>
      )}
    </Grid>
  );
};

export default OrderDateRangeInput;
