import gql from 'graphql-tag';
import { CustomerInterface, CustomerPRH, NewCustomerInterface, OVTOperator } from '../interfaces';
import Client from './apollo-client';

class CustomerClient {
  private client: Client;
  constructor() {
    // eslint-disable-next-line no-undef
    const url = process.env.REACT_APP_CAMPAIGN_SERVICE_URL || '';
    this.client = new Client(url);
  }
  listCustomers = async (): Promise<Partial<CustomerInterface[]>> => {
    try {
      const LIST_CUSTOMERS = gql`
        query listCustomers {
          listCustomers {
            id
            fax
            customerName
            businessid
            address
            salesman
            contactEmail
            contactPhone
            invoiceDiscount
            companyId
            visit_address
            visit_zipcode
            visit_city
            visit_country
            free_choice4
            free_choice6
            free_choice5
            contacts {
              id
              firstname
              lastname
              phone
              email
              department
            }
            invoiceAddresses {
              id
              invoice_address
              invoice_zipcode
              invoice_city
              invoice_country
            }
          }
        }
      `;
      const result = await this.client.query(LIST_CUSTOMERS);
      return result.data.listCustomers as Partial<CustomerInterface[]>;
    } catch (error) {
      throw new Error('Cannot get customers');
    }
  };

  findCustomersByName = async (customerName: string): Promise<Partial<CustomerInterface[]>> => {
    try {
      const FIND_CUSTOMERS = gql`
        query findCustomersByName($customerName: String!) {
          findCustomersByName(customerName: $customerName) {
            id
            fax
            customerName
            businessid
            address
            salesman
            contactEmail
            contactPhone
            invoiceDiscount
            companyId
            visit_address
            visit_zipcode
            visit_city
            visit_country
            isdn_1
            free_choice4
            free_choice5
            free_choice6
            contacts {
              id
              firstname
              lastname
              phone
              email
              department
            }
            invoiceAddresses {
              id
              invoice_address
              invoice_zipcode
              invoice_city
              invoice_country
            }
          }
        }
      `;
      const result = await this.client.query(FIND_CUSTOMERS, { customerName });
      return result.data.findCustomersByName as Partial<CustomerInterface[]>;
    } catch (error) {
      throw new Error('Cannot get customers');
    }
  };

  findCustomersByBusinessid = async (businessId: string): Promise<Partial<CustomerInterface[]>> => {
    try {
      const FIND_CUSTOMERS = gql`
        query findCustomersByBusinessid($businessId: String!) {
          findCustomersByBusinessid(businessid: $businessId) {
            id
            fax
            customerName
            businessid
            address
            salesman
            contactEmail
            contactPhone
            invoiceDiscount
            companyId
            isdn_1
            visit_address
            visit_zipcode
            visit_city
            visit_country
            free_choice4
            free_choice6
            contacts {
              id
              firstname
              lastname
              phone
              email
              department
            }
            invoiceAddresses {
              id
              invoice_address
              invoice_zipcode
              invoice_city
              invoice_country
            }
          }
        }
      `;
      const result = await this.client.query(FIND_CUSTOMERS, { businessId });
      console.log(result);

      return result.data.findCustomersByBusinessid as Partial<CustomerInterface[]>;
    } catch (error) {
      throw new Error('Cannot get customers');
    }
  };

  findCustomersById = async (companyId: string, campaignId: string): Promise<CustomerInterface> => {
    try {
      const FIND_CUSTOMERS = gql`
        query findCustomersById($companyId: String!, $campaignId: String!) {
          findCustomersById(companyId: $companyId, campaignId: $campaignId) {
            id
            fax
            customerName
            businessid
            address
            salesman
            contactEmail
            contactPhone
            invoiceDiscount
            companyId
            isdn_1
            visit_address
            visit_zipcode
            visit_city
            visit_country
            free_choice4
            free_choice6
            contacts {
              id
              firstname
              lastname
              phone
              email
              department
            }
            invoiceAddresses {
              id
              invoice_address
              invoice_zipcode
              invoice_city
              invoice_country
            }
          }
        }
      `;
      const result = await this.client.query(FIND_CUSTOMERS, { companyId, campaignId });

      return result.data.findCustomersById as CustomerInterface;
    } catch (error) {
      throw new Error('Cannot get customers');
    }
  };

  findCustomerFromPRH = async (businessId: string): Promise<Partial<CustomerPRH | null>> => {
    try {
      const FIND_CUSTOMER_FROM_PRH = gql`
        query findFromPRH($businessId: String!) {
          findFromPRH(businessid: $businessId) {
            businessRegistration
            company
            vatNumber
            visitAddress
            visitZipcode
            visitCity
            visitCountry
            language
            printAddress
            printZipcode
            printCity
            printCountry
          }
        }
      `;
      const result = await this.client.query(FIND_CUSTOMER_FROM_PRH, {
        businessId,
      });
      return result.data.findFromPRH as Partial<CustomerPRH>;
    } catch (error) {
      throw new Error('Cannot get customer');
    }
  };

  getOVTOperators = async (): Promise<[OVTOperator]> => {
    try {
      const GET_OVT_OPERATORS = gql`
        query getOVT {
          ovtOperators {
            key
            value
          }
        }
      `;
      const result = await this.client.query(GET_OVT_OPERATORS);
      return result.data.ovtOperators as [OVTOperator];
    } catch (error) {
      throw new Error('Cannot get OVT operators');
    }
  };

  getCountries = async (): Promise<[OVTOperator]> => {
    try {
      const GET_COUUNTRIES = gql`
        query countries {
          countries {
            key
            value
          }
        }
      `;
      const result = await this.client.query(GET_COUUNTRIES);
      return result.data.countries as [OVTOperator];
    } catch (error) {
      throw new Error('Cannot get countries');
    }
  };

  getFreechoice4 = async (): Promise<[OVTOperator]> => {
    try {
      const GET_OVT_OPERATORS = gql`
        query freechoice4 {
          freechoice4 {
            key
            value
          }
        }
      `;
      const result = await this.client.query(GET_OVT_OPERATORS);
      return result.data.freechoice4 as [OVTOperator];
    } catch (error) {
      throw new Error('Cannot get freechoice4');
    }
  };

  getSalesmen = async (): Promise<[OVTOperator]> => {
    try {
      const GET_OVT_OPERATORS = gql`
        query salesmen {
          salesmen {
            key
            value
          }
        }
      `;
      const result = await this.client.query(GET_OVT_OPERATORS);
      return result.data.salesmen as [OVTOperator];
    } catch (error) {
      throw new Error('Cannot get salesmen');
    }
  };

  updateCustomer = async (customer: NewCustomerInterface): Promise<string> => {
    try {
      const UPDATE_CUSTOMER = gql`
        mutation updateCustomer(
          $fax: String!
          $companyId: String!
          $visitAddress: String!
          $visitCity: String!
          $visitCountry: String!
          $visitZipcode: String!
          $salesman: String!
          $freeChoice4: String!
          $freeChoice6: String!
          $isdn1: String!
          $vatNumber: String!
          $invoiceAddress: InvoiceAddressInput!
          $contactPerson: [ContactPersonUpdateInput!]!
          $id: String!
        ) {
          updateCustomer(
            id: $id
            customer: {
              fax: $fax
              companyId: $companyId
              vatNumber: $vatNumber
              visitAddress: $visitAddress
              visitZipcode: $visitZipcode
              visitCity: $visitCity
              visitCountry: $visitCountry
              salesman: $salesman
              freeChoice4: $freeChoice4
              freeChoice6: $freeChoice6
              isdn1: $isdn1
              contactPersons: $contactPerson
              invoiceAddress: $invoiceAddress
            }
          ) {
            message
          }
        }
      `;
      const result = await this.client.mutate(UPDATE_CUSTOMER, {
        ...customer,
      });
      if (result.errors) throw new Error('Failure');

      return result.data.updateCustomer.message;
    } catch (error) {
      console.log(error);
      throw new Error('Cannot update user on mpux');
    }
  };

  newCustomer = async (customer: NewCustomerInterface): Promise<string> => {
    try {
      const NEW_CUSTOMER = gql`
        mutation newCustomer(
          $businessRegistration: String!
          $company: String!
          $fax: String!
          $visitAddress: String!
          $visitCity: String!
          $visitCountry: String!
          $visitZipcode: String!
          $salesman: String!
          $freeChoice4: String!
          $freeChoice6: String!
          $isdn1: String!
          $vatNumber: String!
          $invoiceAddress: InvoiceAddressInput!
          $contactPerson: [ContactPersonInput!]!
        ) {
          newCustomer(
            newCustomer: {
              businessRegistration: $businessRegistration
              company: $company
              fax: $fax
              visitAddress: $visitAddress
              visitCity: $visitCity
              visitCountry: $visitCountry
              visitZipcode: $visitZipcode
              salesman: $salesman
              freeChoice4: $freeChoice4
              freeChoice6: $freeChoice6
              isdn1: $isdn1
              vatNumber: $vatNumber
              invoiceAddress: $invoiceAddress
              contactPersons: $contactPerson
            }
          ) {
            message
          }
        }
      `;
      const result = await this.client.mutate(NEW_CUSTOMER, { ...customer });
      if (result.errors) throw new Error('Failure');
      return result.data.newCustomer.message;
    } catch (error) {
      console.log(error);
      throw new Error('Cannot add new user to mpux');
    }
  };
}
export default new CustomerClient();
