import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { useOrderFormDispatch, useOrderFormState } from '../../context/order-form-context';
import { parseCampaignPriceInput } from '../../types/campaign-price-input';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '800px',
    marginTop: '0px',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  radio: {
    '&$checked': {
      color: '#5E8EFC',
    },
  },
  checked: {},
  formGroup: {
    width: '400px',
    padding: 0,
  },
  title: {
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '19px',
    fontWeight: 'bold',
    color: '#000000',
  },
  text: {
    fontSize: '14px',
    marginTop: '-10px',
    padding: 0,
    letterSpacing: '0.25px',
    lineHeight: '16px',
    fontWeight: 400,
    color: '#000000DE',
  },
}));

const OrderMediaDiscount: React.FC = () => {
  const classes = useStyles();
  const orderFormDispatch = useOrderFormDispatch();
  const orderFormState = useOrderFormState();
  const updateValue = (newValue: boolean): void => {
    orderFormDispatch({
      type: 'updateCampaignPrice',
      payload: parseCampaignPriceInput({
        ...orderFormState.price,
        mediaDiscount: newValue ? 1 : 0,
      }),
    });
  };

  return (
    <>
      <FormControl className={classes.formControl}>
        <div>
          <Typography className={classes.title}>Mediatoimistoalennus (15%)</Typography>
        </div>
        <FormGroup className={classes.formGroup}>
          <FormControlLabel
            className={classes.text}
            name="all"
            control={
              <Checkbox
                classes={{
                  root: classes.radio,
                  checked: classes.checked,
                }}
                onChange={(_, checked: boolean): void => updateValue(checked)}
              />
            }
            checked={!!orderFormState.price?.mediaDiscount}
            value={!!orderFormState.price?.mediaDiscount}
            label=""
          ></FormControlLabel>
        </FormGroup>
      </FormControl>
    </>
  );
};

export default OrderMediaDiscount;
