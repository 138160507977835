import { Button, Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import TextEditor from '../components/text-editor-modal/text-editor';
import { CampaignInterface, OrderDetails, UserInterface } from '../interfaces';
import MaterialClient from '../lib/material-client';
import { getUserId } from '../lib/storage-util';
import userClient from '../lib/user-client';

interface EditorModalProps {
  setSpinner: (x: boolean) => void;
  orderDetails?: OrderDetails;
  campaignData?: CampaignInterface;
  setText: (x: string) => void;
}

type Attachments = { filename: string; path: string }[];

const OfferEditorContainer: React.FC<EditorModalProps> = ({
  orderDetails,
  setSpinner,
  campaignData,
  setText,
}: EditorModalProps) => {
  const [currentUser, setUser] = useState<UserInterface>();
  const [materials, setMaterials] = useState<Attachments>([]);

  useEffect(() => {
    if (campaignData && campaignData.attachments) setMaterials(campaignData.attachments);
  }, [campaignData]);

  useEffect(() => {
    const userId = getUserId();

    if (userId) {
      const getUserInfo = async (): Promise<void> => {
        const user = await userClient.getUser(userId);
        if (user) {
          setUser(user);
        }
      };
      getUserInfo();
    }
  }, []);

  const addMaterial = async ({ target }: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    try {
      setSpinner(true);
      if (target && target.files?.length) {
        const materialResult = await MaterialClient.addFile(target.files[0]);
        // eslint-disable-next-line no-undef
        const materialPath = process.env.REACT_APP_MATERIAL_PATH || '';
        setMaterials([
          ...materials,
          {
            filename: materialResult.originalName,
            path: materialPath + materialResult.uniqueName,
          },
        ]);
        setSpinner(false);
      }
    } catch (e) {
      const error = e as Error;
      setSpinner(false);
      throw new Error('Adding material failed:' + error.message);
    }
  };

  return (
    <>
      <Grid style={{ borderBottom: '1px solid black', marginBottom: '20px' }}>
        {currentUser && (
          <TextEditor
            setText={setText}
            orderDetails={orderDetails}
            currentUser={currentUser}
            campaignData={campaignData}
          ></TextEditor>
        )}
      </Grid>
      <Grid
        container
        direction="row"
        style={{
          borderBottom: '1px solid black',
          paddingBottom: '10px',
          marginBottom: '20px',
        }}
      >
        <Button variant="contained" component="label">
          Lisää liite
          <input onChange={addMaterial} type="file" style={{ display: 'none' }} />
        </Button>
        {materials.length > 0 &&
          materials.map((material) => (
            <Button
              color="secondary"
              onClick={(): void => {
                setMaterials([...materials.filter((m) => m.path != material.path)]);
              }}
              key={material.filename}
            >
              {material.filename}
            </Button>
          ))}
      </Grid>
    </>
  );
};

export default OfferEditorContainer;
