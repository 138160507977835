import {
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import { CustomerInterface } from '../../interfaces';
import AddCustomerStepper from './add-customer-stepper';

const useStyles = makeStyles(() => ({
  paper: {
    minWidth: '855px',
    minHeight: '70vh',
    maxHeight: '90vh',
  },
  content: { margin: '10px' },
  title: { textAlign: 'center' },
  sendButton: {
    backgroundColor: '#5E8EFC',
  },
  cancelButton: {
    backgroundColor: '#FF6F75',
  },
}));

interface AddCustomerModalProps {
  modalOpen: boolean;
  onClickModal: () => void;
  addCustomer: (data: any) => Promise<void>;
  customer?: CustomerInterface;
}

const AddCustomerModal: React.FC<AddCustomerModalProps> = ({
  modalOpen,
  onClickModal,
  addCustomer,
  customer,
}: AddCustomerModalProps) => {
  const classes = useStyles();
  return (
    <Dialog
      classes={{ paper: classes.paper }}
      onClose={onClickModal}
      open={modalOpen}
    >
      <DialogTitle className={classes.title}>Lisää asiakas</DialogTitle>
      <DialogContent className={classes.content}>
        <AddCustomerStepper
          onclickModal={onClickModal}
          submit={addCustomer}
          customer={customer}
        ></AddCustomerStepper>
      </DialogContent>
    </Dialog>
  );
};

export default AddCustomerModal;
