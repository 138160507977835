import { CardMedia, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import PMLogo from '../assets/punamusta-logo.png';
import ResetPasswordForm from './reset-password-form';

const useStyles = makeStyles(() => ({
  loginComponent: {
    marginTop: '110px',
    marginLeft: '118px',
    width: '581px',
    height: '739px',
    borderRadius: '10px',
    marginBottom: '20px',
    background: '#FFFFFF 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 20px #00000029',
    border: '1px solid #70707033',
  },
  title: {
    letterSpacing: ' 0.52px',
    color: '#E30312',
    fontSize: '40px',
    fontWeight: 500,
    lineHeight: '47px',
    marginBottom: '62px',
  },
  loginText: {
    letterSpacing: ' 0.9px',
    color: '#000000',
    fontSize: '18px',
    fontWeight: 900,
    lineHeight: '21px',
    marginBottom: '50px',
    textTransform: 'uppercase',
  },
  logo: { width: '130px', height: '24px', marginTop: '62px' },
  container: {
    height: '100%',
    'align-items': 'center',
    'justify-content': 'center',
    'flex-direction': 'column',
  },
}));

const ResetPasswordComponent: React.FC = () => {
  const classes = useStyles();
  return (
    <Grid item className={classes.loginComponent}>
      <Grid className={classes.container} container>
        <Grid item>
          <Typography className={classes.title} variant="h5">
            MediaMyynti
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.loginText} variant="h5">
            Palauta salasana
          </Typography>
        </Grid>
        <Grid item>
          <ResetPasswordForm />
        </Grid>
        <CardMedia
          component="img"
          image={PMLogo}
          className={classes.logo}
        ></CardMedia>
      </Grid>
    </Grid>
  );
};
export default ResetPasswordComponent;
