export const capitalizeString = (
  value: number | string | null | undefined
): string => {
  if (value === null || value === undefined || value === '') return '';
  return `${value}`[0].toUpperCase() + `${value}`.slice(1);
};

export const doubleDecimalString = (value: number): string => {
  const rounded = Math.round((value + Number.EPSILON) * 100) / 100;
  return rounded.toFixed(2);
};
