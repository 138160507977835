import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment, { Moment } from 'moment';
import {
  AvailabilityInterface,
  Month,
  ReservationDaysMonths,
  ReservationInterface,
  ReservationsProps,
  ReservationSummary,
} from '../interfaces';

const mapAvailability = (
  array: ReservationSummary[],
  length: number,
  limitMultiplier: number
): AvailabilityInterface => {
  const availability: AvailabilityInterface = {
    freeSpace: [],
    mediumSpace: [],
    full: [],
  };

  array.map((item: ReservationSummary) => {
    if (item) {
      const date = new Date(item.date);
      if (item.available - length < 0 || length > item.limit) {
        availability.full.push(date);
      }
      if (item.available <= item.limit * limitMultiplier) availability.mediumSpace.push(date);
      if (item.available > item.limit * limitMultiplier) availability.freeSpace.push(date);
    }
    return item;
  });

  return availability;
};

export const mapReservations = (
  reservations: ReservationsProps,
  length: number,
  limitMultiplier: number
): AvailabilityInterface => {
  const array: ReservationSummary[] = [];

  Object.keys(reservations).map(async (key) => {
    if (reservations[key]) {
      reservations[key].map((reservation) => {
        const sameDay = array.find((day) => moment(day?.date).isSame(reservation?.date), 'day');

        if (sameDay) {
          if (reservation) {
            if (reservation.available < sameDay.available) {
              array[array.findIndex((day) => day === sameDay)] = reservation;
            }
          }
        } else {
          array.push(reservation);
        }
      });
    }
  });

  const reservedDays = mapAvailability(array, length, limitMultiplier);
  return reservedDays;
};

export const sortWeekReservations = (
  reservations: ReservationInterface[]
): ReservationInterface[] => {
  const reservationsCopy = [...reservations];
  const sorted = reservationsCopy.sort((a, b) => {
    if (!a.year || !b.year) return 0;
    if (!a.week || !b.week) return 0;

    if (a.year < b.year) {
      return -1;
    }
    if (a.year > b.year) {
      return 1;
    }
    if (a.week < b.week) {
      return -1;
    }
    if (a.week > b.week) {
      return 1;
    }
    return 0;
  });
  return sorted;
};

export const sortMonthReservations = (
  reservations: ReservationInterface[]
): ReservationInterface[] => {
  const reservationsCopy = [...reservations];
  const sorted = reservationsCopy.sort((a, b) => {
    if (!a.year || !b.year) return 0;
    if (!a.month || !b.month) return 0;

    if (a.year < b.year) {
      return -1;
    }
    if (a.year > b.year) {
      return 1;
    }
    if (a.month < b.month) {
      return -1;
    }
    if (a.month > b.month) {
      return 1;
    }
    return 0;
  });
  return sorted;
};

export const filterReservationsByDates = (
  startDate: MaterialUiPickersDate,
  endDate: MaterialUiPickersDate,
  reservations: ReservationDaysMonths
): ReservationDaysMonths => {
  let filteredCampaigns: string[] = [];
  if (startDate || endDate) {
    const { days, months } = reservations;
    const dayIds: string[] = [];

    days.forEach((day) => {
      const campaignId = dayIds.find((campaign) => campaign === day.campaignId);

      if (campaignId) return false;

      if (day.date) {
        const dateObj = new Date(day.date);

        if (startDate && endDate) {
          if (dateObj.getTime() >= startDate.valueOf() && dateObj.getTime() <= endDate.valueOf()) {
            dayIds.push(day.campaignId);
            filteredCampaigns = filteredCampaigns.filter((campaign) => {
              return campaign !== day.campaignId;
            });
            return true;
          }
          filteredCampaigns.push(day.campaignId);
          return false;
        }

        if (startDate) {
          if (dateObj.getTime() >= startDate.valueOf()) {
            return true;
          }
          filteredCampaigns.push(day.campaignId);
          return false;
        }

        if (endDate) {
          if (dateObj.getTime() <= endDate.valueOf()) {
            return true;
          }
          filteredCampaigns.push(day.campaignId);
          return false;
        }
      }
    });

    const monthIds: string[] = [];

    months.forEach((item) => {
      const campaignId = monthIds.find((campaign) => campaign === item.campaignId);
      if (campaignId) return false;

      if (!item.month || !item.year) return false;
      if (startDate && endDate) {
        if (item.year > startDate.year() && item.year < endDate.year()) {
          monthIds.push(item.campaignId);

          return true;
        }
        if (
          item.month >= startDate.month() + 1 &&
          item.year === startDate.year() &&
          item.year < endDate.year()
        ) {
          monthIds.push(item.campaignId);

          return true;
        }
        if (
          item.month <= endDate.month() + 1 &&
          item.year === endDate.year() &&
          item.year > startDate.year()
        ) {
          monthIds.push(item.campaignId);

          return true;
        }
        if (
          item.month >= startDate.month() + 1 &&
          item.year === startDate.year() &&
          item.month <= endDate.month() + 1 &&
          item.year === endDate.year()
        ) {
          monthIds.push(item.campaignId);

          return true;
        }
        filteredCampaigns.push(item.campaignId);
        return false;
      }
      if (startDate) {
        if (item.month >= startDate.month() + 1 && item.year === startDate.year()) {
          monthIds.push(item.campaignId);

          return true;
        }
        if (item.year > startDate.year()) {
          monthIds.push(item.campaignId);

          return true;
        }
        filteredCampaigns.push(item.campaignId);
        return false;
      }
      if (endDate) {
        if (item.month <= endDate.month() + 1 && item.year === endDate.year()) {
          monthIds.push(item.campaignId);

          return true;
        }
        if (item.year < endDate.year()) {
          monthIds.push(item.campaignId);

          return true;
        }
        filteredCampaigns.push(item.campaignId);
        return false;
      }
    });
  }
  const items = {
    months: reservations.months.filter((month) => !filteredCampaigns.includes(month.campaignId)),
    days: reservations.days.filter((day) => !filteredCampaigns.includes(day.campaignId)),
    weeks: [],
  };

  return items;
};

export const getReservationDates = (
  reservations: ReservationInterface[],
  startDate: Moment
): Date[] => {
  const dates: Date[] = [];
  const start = moment(startDate).add(3, 'day').toDate().getTime();
  if (start) {
    if (reservations) {
      const uniques = [...new Set(reservations.map((item) => item.date))];
      uniques.forEach((reservation) => {
        if (reservation) {
          if (new Date(reservation).getTime() > start) {
            dates.push(new Date(reservation));
          }
        }
      });
    }
  }
  return dates;
};

export const getReservationMonths = (reservations: ReservationInterface[]): Month[] => {
  const months: Month[] = [];

  if (reservations) {
    reservations.forEach((reservation) => {
      const { month, year } = reservation;
      if (month && year) {
        months.push({ month, year });
      }
    });
  }
  return months;
};
