import { navigate } from '@reach/router';
import { toast } from 'react-toastify';
import {
  AddOrderState,
  CustomerCampaign,
  MultipressCalculatedPrice,
  OrderDetails,
  OrderSummary,
} from '../../interfaces';
import { CampaignInput } from '../../types/campaign-input';
import { OrderBatch } from '../../types/order-batch';
import { checkSession, setSession } from '../../utils/session-utils';
import { toastOptions } from '../../utils/toast-options';
import CampaignClient from '../campaign-client';
import reservationClient from '../reservation-client';
import addCampaignPrice from './add-campaign-price';
import addMonthReservations from './add-month-reservations';
import addReservations from './add-reservations';
import addWeekReservations from './add-week-reservations';
import downloadOrderPdf from './download-order-pdf';

const updateCampaign = async (
  orderDetails: OrderDetails,
  campaign: CustomerCampaign,
  orderSummary: OrderSummary,
  price: MultipressCalculatedPrice,
  setSending: Function,
  batches: OrderBatch[],
  addOrderState: AddOrderState,
  campaignId: string,
  offerPdf = false
): Promise<void> => {
  const { floatingOrder } = addOrderState;
  const campaignData: CampaignInput = {
    ...campaign,
    campaignName: campaign.name,
    customer: campaign.customerId,
    description: '',
    offer: false,
    campaignDetails: orderDetails.campaignDetails,
    ...orderSummary,
    floating: floatingOrder ? floatingOrder.floating : false,
    floatingAmount: floatingOrder ? floatingOrder.floatingAmount : 1,
    floatingQuantity: floatingOrder ? floatingOrder.quantity : 5,
    floatingVariations: floatingOrder ? floatingOrder.variations.join(',') : '',
    floatingBatchName: floatingOrder ? floatingOrder.batchName : '',
    offerPdf,
  };

  setSending(true);

  try {
    campaignId = await CampaignClient.updateCampaign(
      campaignData as CampaignInput,
      campaignId
    );
    toast.success('Kampanja päivitetty', toastOptions);
  } catch (error) {
    console.log(error);
    toast.error('Kampanjan päivitys epäonnistui', toastOptions);
    setSending(false);
    return;
  }
  try {
    // varmaanni poistetaan kaikki vanhat ja uudet tilalle
    await reservationClient.removeReservationsByCampaingId(campaignId);
    if (addOrderState.limitType === 'month') {
      await addMonthReservations({
        campaign: campaignId,
        rough: false,
        product: addOrderState.productId,
        batches,
      });
    }
    if (addOrderState.limitType === 'week') {
      await addWeekReservations({
        campaign: campaignId,
        rough: false,
        product: addOrderState.productId,
        batches,
      });
    } else {
      await addReservations({
        product: addOrderState.productId,
        batches,
        campaign: campaignId,
      });
    }
    toast.success('Tilaukset päivitetty', toastOptions);
  } catch (error) {
    console.log(error);
    toast.error(
      'Tilauksien päivitys epäonnistui. Tarkista ajanjaksot.',
      toastOptions
    );
    setSending(false);
    return;
  }

  try {
    await addCampaignPrice({
      campaign: campaignId,
      ...price,
      discountAmount: orderSummary.discountAmount,
      discountPercent: orderSummary.discountPercent,
    });
    toast.success('Hinnat päivitetty', toastOptions);
  } catch (error) {
    toast.error('Hintojen päivitys epäonnistui', toastOptions);
    setSending(false);
    return;
  }

  try {
    //pass
  } catch (error) {
    toast.error('Jotain meni vikaan tarjousta pävittäessä', toastOptions);
    // ei tata
    setSending(false);
    return;
  }
  try {
    if (floatingOrder?.floating)
      await CampaignClient.checkFloatingCampaignStatus(campaignId);
  } catch {
    //pass
  }
  if (offerPdf) {
    if (checkSession()) setSession();
    else navigate('/');
    await downloadOrderPdf(campaignId);
  }
  setSending(false);
  if (floatingOrder && floatingOrder.floating) {
    navigate('/floatingOrders');
    return;
  }
  navigate('/orders');
};

export default updateCampaign;
