import { Button } from '@material-ui/core';
import React, { forwardRef } from 'react';

const CustomDate = forwardRef<HTMLButtonElement, any>(({ value, onClick }, ref) => (
  <Button
    style={{
      backgroundColor: '#5E8EFC',
      marginLeft: '50px',
      color: 'white',
    }}
    className="custom-date"
    onClick={onClick}
    ref={ref}
  >
    Valitse aikaväli
  </Button>
));

export default CustomDate;
