import ReservationClient from '../reservation-client';
import { navigate } from '@reach/router';
import { toast } from 'react-toastify';
import { toastOptions } from '../../utils/toast-options';

const acceptCampaignOffer = async (campaignId: string, setAccepting: Function): Promise<void> => {
  try {
    await ReservationClient.confirmRoughReservations(campaignId);
    toast.success('Varaukset allokoitu', toastOptions);
  } catch (error) {
    toast.error('Varauksien allokointi epäonnistui', toastOptions);

    setAccepting(false);
  }

  toast.info('Varauksien vahvistuksessa saattaa kestää 1-2min, odotathan.', toastOptions);

  try {
    toast.success('Tarjouksen vahvistus onnistui', toastOptions);

    navigate('/orders');
  } catch (error) {
    toast.error('Tarjouksen vahvistus epäonnistui', toastOptions);

    await ReservationClient.revertConfirmRoughReservations(campaignId);
  } finally {
    setAccepting(false);
  }
  setAccepting(false);
};

export default acceptCampaignOffer;
