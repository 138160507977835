import { Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
  inputContainer: {
    justifyContent: 'space-between',
  },
  rightItem: {
    width: '400px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  error: {
    color: 'red',
    fontSize: '12px',
  },
  text: {
    minWidth: '300px',
  },
}));

type StringInputProps = {
  value: string;
  label: string;
  setValue: (x: string) => void;
  error?: string;
};

const StringInput: React.FC<StringInputProps> = ({
  value,
  setValue,
  error,
  label,
}: StringInputProps) => {
  const classes = useStyles();

  return (
    <Grid className={classes.inputContainer} container>
      <Grid item>
        <Typography className={classes.title}>{label}</Typography>
      </Grid>
      <Grid className={classes.rightItem} item>
        <Grid container>
          <TextField
            className={classes.text}
            name={label}
            onChange={(e): void => setValue(e.target.value)}
            placeholder=""
            value={value}
          ></TextField>
          {error && <Typography className={classes.error}>{error}</Typography>}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default StringInput;
