import {
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useOrderFormDispatch, useOrderFormState } from '../../context/order-form-context';
import useDebounce from '../../hooks/use-debounce';
import { CampaignInterface } from '../../interfaces';
import { parseCampaignPriceInput } from '../../types/campaign-price-input';

const useStyles = makeStyles(() => ({
  inputContainer: {
    justifyContent: 'space-between',
  },
  rightItem: {
    width: '400px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  blueText: { fontSize: '16px', color: '#5E8EFC' },
  error: {
    color: 'red',
    fontSize: '12px',
  },
}));

interface SummaryProps {
  campaignData?: CampaignInterface;
  formik: any;
  discountSwitch: boolean;
  setDiscountSwitch: (x: boolean) => void;
}

const OrderSummaryFormCustomerDiscount: React.FC<SummaryProps> = ({
  formik,
  discountSwitch,
  setDiscountSwitch,
}: SummaryProps) => {
  const classes = useStyles();

  const [discounts, setDiscounts] = useState<{ amount: number; percent: number }>();
  const debouncedDiscounts = useDebounce(discounts, 500);
  const orderFormState = useOrderFormState();
  const orderFormDispatch = useOrderFormDispatch();

  const onChangeDiscount = (): void => {
    setDiscountSwitch(!discountSwitch);
  };

  const onChangeDiscountAmount = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = +e.currentTarget.value;
    if (value === undefined) return;

    let amount = value;
    let percent = 0;

    if (formik.values.discountType === '%') {
      amount = 0;
      percent = value;
    }

    formik.setFieldValue('discountAmount', amount);
    formik.setFieldValue('discountPercent', percent);
    formik.setFieldValue('invoiceDiscount', value);
    setDiscounts({ amount, percent });
  };

  useEffect(() => {
    let initial = orderFormState.price;

    if (!initial) {
      initial = {
        campaign: '',
        mediaDiscount: 0,
        vat: 0,
        discount: 0,
        listPrice: 0,
        priceRows: [],
        totalPrice: 0,
      };
    }
    if (!debouncedDiscounts) return;
    orderFormDispatch({
      type: 'updateCampaignPrice',
      payload: parseCampaignPriceInput({
        ...initial,
        discountPercent: debouncedDiscounts.percent,
        discountAmount: debouncedDiscounts.amount,
      }),
    });
  }, [debouncedDiscounts]);

  const onChangeDiscountType = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;

    let amount = 0;
    let percent = formik.values.invoiceDiscount;

    if (value === '€') {
      amount = formik.values.invoiceDiscount;
      percent = 0;
    }
    formik.setFieldValue('discountAmount', amount);
    formik.setFieldValue('discountPercent', percent);
    formik.setFieldValue('invoiceDiscount', formik.values.invoiceDiscount);
    formik.setFieldValue('discountType', value);
  };

  return (
    <>
      <Grid
        container
        className={classes.inputContainer}
        direction="row"
        wrap="nowrap"
        style={{ marginBottom: '45px', alignItems: 'center' }}
        item
      >
        <Grid item>
          <Typography className={classes.blueText}>Asiakaskohtainen alennus</Typography>
        </Grid>
        <Grid container direction="row" wrap="nowrap" item className={classes.rightItem}>
          <TextField
            name="invoiceDiscount"
            onChange={onChangeDiscountAmount}
            value={`${formik.values.invoiceDiscount}`}
            onFocus={(event): void => event.target.select()}
            disabled={discountSwitch}
            type="number"
            inputProps={{
              min: 0.0,
              max: formik.values.discountType === '%' ? 100 : null,
            }}
            placeholder="0"
            InputProps={{ className: classes.blueText }}
          ></TextField>
          <TextField
            onChange={onChangeDiscountType}
            name="discountType"
            select
            disabled={discountSwitch}
            value={formik.values.discountType}
          >
            <MenuItem value="€">€</MenuItem>
            <MenuItem value="%">%</MenuItem>
          </TextField>
          <FormControl>
            <FormControlLabel
              labelPlacement="end"
              style={{ marginLeft: '10px' }}
              classes={{ label: classes.blueText }}
              label="Lisää alennus käsin"
              control={
                <Switch
                  checked={!discountSwitch}
                  onChange={onChangeDiscount}
                  color="primary"
                ></Switch>
              }
            ></FormControlLabel>
          </FormControl>
          <Typography className={classes.error}>
            {formik.errors.invoiceDiscount ? formik.errors.invoiceDiscount : null}
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default OrderSummaryFormCustomerDiscount;
