import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { CustomerInterface } from '../../interfaces';

const useStyles = makeStyles({
  data: {
    margin: '10px',
  },
  gridContainer: {
    width: '700px',
    justifyItems: 'flex-start',
    backgroundColor: 'rgba(177, 184, 192, 0.05)',
    border: ' 1px solid #b1b8c026',
    padding: '10px',
  },
  boldText: {
    fontWeight: 'bold',
    marginRight: '5px',
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '19px',
    color: '#707070',
  },
  text: {
    marginRight: '4px',
    fontSize: '16px',
    letterSpacing: '0.15px',
    lineHeight: '19px',
    color: '#707070',
  },
  columnTitle: {
    margin: '10px',
    fontSize: '20px',
    color: '#707070',
    letterSpacing: '0.26px',
  },
  columnContainer: { width: '100%', height: 'fit-content' },
});

const CustomerData: React.FC<any | null> = (customer: any | null) => {
  const singleCustomer: CustomerInterface = customer?.customer;
  const classes = useStyles();
  return (
    <Grid
      container
      wrap="nowrap"
      direction="row"
      className={classes.gridContainer}
    >
      <Grid item container className={classes.columnContainer}>
        <Grid item>
          <Typography className={classes.columnTitle}>Yritys</Typography>
        </Grid>
        <Grid container direction="column" className={classes.data}>
          <Typography className={classes.boldText}>Asiakkaan nimi </Typography>
          {singleCustomer?.customerName && (
            <Typography className={classes.text}>
              {singleCustomer?.customerName}
            </Typography>
          )}
        </Grid>
        <Grid container direction="column" item className={classes.data}>
          <Typography className={classes.boldText}>Asiakastunnus</Typography>
          {singleCustomer?.companyId && (
            <Typography className={classes.text}>
              {singleCustomer?.companyId}
            </Typography>
          )}
        </Grid>
        <Grid container direction="column" item className={classes.data}>
          <Typography className={classes.boldText}>Alennusprosentti</Typography>
          {singleCustomer?.invoiceDiscount && (
            <Typography className={classes.text}>
              {singleCustomer?.invoiceDiscount}
            </Typography>
          )}
        </Grid>

        <Grid container direction="column" item className={classes.data}>
          <Typography className={classes.boldText}>Asiakkaan osoite</Typography>
          {singleCustomer?.address && (
            <Typography className={classes.text}>
              {singleCustomer?.address}
            </Typography>
          )}
        </Grid>
        <Grid container direction="column" item className={classes.data}>
          <Typography className={classes.boldText}>
            Asiakkaan laskutusosoite
          </Typography>
          {singleCustomer && (
            <Typography className={classes.text}>
              {singleCustomer.invoiceAddresses[0]
                ? singleCustomer.invoiceAddresses[0].invoice_address
                : ''}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item>
        <Divider orientation="vertical" variant="middle"></Divider>
      </Grid>

      <Grid item container className={classes.columnContainer}>
        <Grid item>
          <Typography className={classes.columnTitle}>Yhteyshenkilö</Typography>
        </Grid>

        <Grid container direction="column" className={classes.data}>
          <Typography className={classes.boldText}>
            Asiakkaan yhteyshenkilö
          </Typography>
          {singleCustomer?.salesman && (
            <Typography className={classes.text}>
              {singleCustomer?.salesman}
            </Typography>
          )}
        </Grid>
        <Grid container direction="column" item className={classes.data}>
          <Typography className={classes.boldText}>
            Yhteyshenkilön sähköpostiosoite
          </Typography>
          {singleCustomer?.contactEmail && (
            <Typography className={classes.text}>
              {singleCustomer?.contactEmail}
            </Typography>
          )}
        </Grid>
        <Grid container direction="column" item className={classes.data}>
          <Typography className={classes.boldText}>
            Yhteyshenkilön puhelinnumero
          </Typography>
          {singleCustomer?.contactPhone && (
            <Typography className={classes.text}>
              {singleCustomer?.contactPhone}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CustomerData;
