import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';
import {
  CampaignInterface,
  ProviderInterface,
  ReservationInterface,
} from '../../../interfaces';
import CTableCell from '../../table/table-cell';

interface ReservationGroup {
  date?: Date;
  month?: number;
  year?: number;
  week?: number;
  variations: string[];
}

interface Props {
  singleCampaign: CampaignInterface;
  provider?: ProviderInterface;
}

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  boldCell: {
    fontWeight: 'bold',
    fontSize: '16px',
    letterSpacing: '0.15px',
  },
  headerRow: {
    backgroundColor: '#FAFAFB',
    color: '#000000',
  },
  logTable: {
    marginTop: '20px',
  },
});

const ReservationTable: React.FC<Props> = ({
  singleCampaign,
  provider,
}: Props) => {
  const classes = useRowStyles();

  const getFilteredAndSortedReservations = (
    reservations: ReservationInterface[] = []
  ): ReservationGroup[] => {
    const groupedReservations: ReservationGroup[] = [];
    reservations.forEach((current: ReservationInterface) => {
      const found = groupedReservations.find((item: ReservationGroup) => {
        if (item.date && current.date)
          return (
            new Date(item.date).getTime() === new Date(current.date).getTime()
          );
        else {
          return false;
        }
      });
      if (found) {
        found.variations.push(current.productVariationId);
      } else {
        groupedReservations.push({
          date: current.date,
          month: current.month,
          week: current.week,
          year: current.year,
          variations: [current.productVariationId],
        });
      }
    });

    const orderedReservations = groupedReservations.sort((a, b) => {
      if (a.date && b.date)
        return new Date(a.date).getTime() < new Date(b.date).getTime()
          ? -1
          : new Date(a.date).getTime() === new Date(b.date).getTime()
          ? 0
          : 1;
      if (a.week && a.year && b.week && b.year) {
        const result =
          a.year < b.year ? -1 : a.year > b.year ? 1 : a.week < b.week ? -1 : 1;
        return result;
      }
      if (a.month && a.year && b.month && b.year) {
        const result =
          a.year < b.year
            ? -1
            : a.year > b.year
            ? 1
            : a.month < b.month
            ? -1
            : 1;
        return result;
        // const yearA = `${a.month}-${a.year}`;
        // const yearB = `${b.month}-${b.year}`;
        // return yearA < yearB ? -1 : yearA === yearB ? 0 : 1;
      }
      return 0;
    });

    return orderedReservations;
  };

  const reservationGroups = getFilteredAndSortedReservations(
    singleCampaign.reservations
  );

  return (
    <Table className={classes.logTable}>
      <TableHead>
        <TableRow className={classes.headerRow}>
          <TableCell className={classes.boldCell}>Kampanja-ajat</TableCell>
          <TableCell className={classes.boldCell}>Tuotteet</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {reservationGroups.map((res) => (
          <TableRow key={res.date + res.variations[0]}>
            <CTableCell
              resolver={async (): Promise<string> => {
                return res.date
                  ? new Date(res.date).toLocaleDateString('de-De')
                  : res.week
                  ? `${res.week}/${res.year}`
                  : res.month && res.year
                  ? `${res.month}/${res.year}`
                  : '';
              }}
            />
            <CTableCell
              resolver={async (): Promise<string> => {
                if (provider) {
                  const variations = provider.products
                    .map((prod) => prod.variations)
                    .flat();
                  if (variations) {
                    const variationNames = res.variations.map((group) =>
                      variations.find((v) => v?.id === group)
                    );
                    if (variationNames)
                      return variationNames
                        .map((v) => (v ? v.name : ''))
                        .sort()
                        .join(', ');
                  }
                  return '';
                }
                return '';
              }}
            />
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ReservationTable;
