import { makeStyles, MenuItem, TableCell, TextField, Typography } from '@material-ui/core';
import React, { ChangeEvent, ReactNode } from 'react';
import campaignClient from '../../lib/campaign-client';

interface Props {
  status: number;
  campaignId: string;
  refresh: () => Promise<void>;
  admin?: boolean;
}

interface Option {
  label: string;
  value: number;
  disabled?: boolean;
}

const useStyles = makeStyles({
  menuItem: {
    textTransform: 'capitalize',
  },
});

const CampaignStatusSelect: React.FC<Props> = ({
  status,
  campaignId,
  refresh,
  admin = false,
}: Props) => {
  const classes = useStyles();
  const updateStatus = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const value = event.target.value;
    if (value !== undefined) {
      try {
        await campaignClient.updateCampaignStatus(+value, campaignId);
        refresh();
      } catch {
        alert('Unable to update state');
      }
    }
  };
  const options = [
    { label: 'Uusi', value: 0 },
    { label: 'Työn alla', value: 1 },
    { label: 'Valmis', value: 2 },
    { label: 'Peruttu', value: 3, disabled: !admin },
    { label: 'Odottaa Paytrail-maksua', value: 4 },
    { label: 'Maksettu paytraililla', value: 5 },
  ];
  return (
    <TableCell>
      <TextField
        select
        name={'campaign-status'}
        onChange={updateStatus}
        value={status}
        fullWidth={true}
        placeholder={`status`}
      >
        {options.map((option: Option, index: number): ReactNode => {
          return (
            <MenuItem
              disabled={option.disabled}
              key={`${option.value}-${index}`}
              value={option.value}
            >
              <Typography className={classes.menuItem}>{option.label}</Typography>
            </MenuItem>
          );
        })}
      </TextField>
    </TableCell>
  );
};

export default CampaignStatusSelect;
