import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import React, { useEffect, useState } from 'react';
import { PlaylistItemTemplate } from '../interfaces';
import MaterialClient from '../lib/material-client';
import PlaylistClient from '../lib/playlist-client';

interface Upload {
  quantity: string;
  material: File | null;
  name: string;
}

const Order: React.FC<RouteComponentProps> = () => {
  const [values, setValues] = useState<Upload>({
    quantity: '',
    material: null,
    name: '',
  });
  const [templates, setTemplates] = useState<PlaylistItemTemplate[]>([]);
  const [buttonDisabled, setbuttonDisabled] = useState(true);

  const addMaterial = async ({
    target,
  }: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    try {
      if (target && target.files?.length) {
        setValues({ ...values, material: target.files[0] });
      }
    } catch (error) {
      throw new Error('Adding material failed:' + error.message);
    }
  };

  const getTemplates = async () => {
    const result = await PlaylistClient.getPlaylistItemTemplates();

    setTemplates(result);
  };
  useEffect(() => {
    getTemplates();
  }, []);

  useEffect(() => {
    if (values.quantity && values.material && values.name) {
      setbuttonDisabled(false);
    } else {
      setbuttonDisabled(true);
    }
  }, [values]);

  const changeQuantity = (e: any) => {
    setValues({ ...values, [e.target.id]: e.target.value });
  };

  const buttonCLick = async () => {
    if (values.material && values.quantity) {
      const materialResult = await MaterialClient.addFile(values.material);

      if (!materialResult) throw new Error('Adding Tampere material failed');
      const number = parseInt(values.quantity);

      const playlistRestult = await PlaylistClient.addPlaylistItemTemplate(
        number,
        materialResult.id,
        values.name
      );
      if (!playlistRestult) throw new Error('Item template add failed');
      setbuttonDisabled(true);
      setValues({ quantity: '', material: null, name: '' });
      getTemplates();
    }
  };

  return (
    <Grid container direction="column">
      <div>Tampereen mainos upload</div>

      <Grid item>
        <TextField
          label="pituus (sekuntia)"
          id="quantity"
          inputProps={{ min: '1' }}
          type="number"
          onChange={changeQuantity}
        ></TextField>
      </Grid>
      <Grid item>
        <TextField label="nimi" id="name" onChange={changeQuantity}></TextField>
      </Grid>
      <Grid container direction="row" item>
        <Button variant="contained" component="label">
          Lisää materiaali
          <input
            onChange={addMaterial}
            type="file"
            style={{ display: 'none' }}
          />
        </Button>
        {values.material && <Typography>{values.material.name}</Typography>}
      </Grid>
      <Grid item>
        <Button
          onClick={buttonCLick}
          disabled={buttonDisabled}
          variant="contained"
        >
          Lähetä mainos
        </Button>
      </Grid>
      <Grid container direction="column">
        {templates &&
          templates.map((template) => {
            return (
              <Grid key={`template-${template.id}`} item>
                {template.name}
              </Grid>
            );
          })}
      </Grid>
    </Grid>
  );
};
export default Order;
